import React, { VideoHTMLAttributes } from "react";
import { useInView } from "react-intersection-observer";

interface Props extends Omit<VideoHTMLAttributes<HTMLVideoElement>, "placeholder"> {
    placeholder?: [number, number];
}

const placeholderSrc = ([width, height]: [number, number]) => `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;

const lazyOptions = {
    triggerOnce: true,
    rootMargin: "300px 0px",
};

export default ({ children, placeholder, style, ...rest }: Props) => {
    const [ref, inView] = useInView(lazyOptions);
    return (
        <div ref={ref} style={style}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {inView ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video {...rest} style={{ width: "100%", ...style }}>
                    {children}
                </video>
            ) : placeholder ? (
                <img
                    alt="Loading"
                    src={placeholderSrc(placeholder)}
                    width={placeholder[0]}
                    height={placeholder[1]}
                    style={{ width: "100%", height: "auto", display: "block" }}
                />
            ) : null}
        </div>
    );
};
