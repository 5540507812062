if (!window.onnit_context) {
    throw new Error("`window.onnit_context` is undefined.");
}

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// This is NOT the place for secrets.
// Only PUBLIC values should be present here, since this is readable by the public.
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

const FREE_SHIPPING_THRESHOLD_DEFAULT = window.onnit_context?.free_shipping_threshold ?? 150;

export default Object.freeze({
    IMG_PARAMS: "?q=40&fm=pjpg&auto=compress,format",
    SVG_PARAMS: "?q=50&auto=compress,format",
    FREE_SHIPPING_THRESHOLD_DEFAULT,
    FACEBOOK_APP_ID: "421787945392935",
    ALLIT_URL: window.onnit_context.url.allit,
    API_URL: window.onnit_context.url.api,
    NODE_ENV: process.env.NODE_ENV,
    IMG_URL: window.onnit_context.url.cdn.static_images
});
