import React from "react";
import HorizontalScrollGrid from "@onnit-js/ui/components/slider/HorizontalScrollGrid";
import { HorizontalScrollGrid as HorizontalScrollGridInterface } from "../../interfaces/SiteContent";
import ContentfulComponentMapper from "./ContentfulComponentMapper";

interface Props {
    data: HorizontalScrollGridInterface
}

const ContentfulHorizontalScrollGrid:React.FC<Props> = ({ data: { items, ...rest } }) => {
    if (!items) {
      return null;
    }

    return (
        <HorizontalScrollGrid {...rest}>
            {items.map((node, index) =>
                // eslint-disable-next-line react/no-array-index-key,implicit-arrow-linebreak
                <ContentfulComponentMapper key={index} content={[node]} />
            )}
        </HorizontalScrollGrid>
    );
};

export default ContentfulHorizontalScrollGrid;
