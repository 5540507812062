import React from "react";
import { Text, TextProps } from "../text/Text";
import Product from "../../@types/interfaces/product/Product";
import UrlUtil from "../../utils/UrlUtil";

interface Props extends TextProps {
    item: Product;
    onClick?: (event: any) => void;
}

const RecommendationItemProductName: React.FC<React.PropsWithChildren<Props>> = ({ item, onClick, ...rest }) => {
    const { product_name } = item;
    const url = UrlUtil.getProductUrl(item);

    return (
        <Text
            as="a"
            href={url}
            fontWeight="bold"
            fontSize={2}
            letterSpacing="-0.03em"
            mb={1}
            onClick={onClick}
            {...rest}
        >
            {product_name}
        </Text>
    );
};

export default RecommendationItemProductName;
