import React, { MouseEvent, ReactElement } from "react";
import styled, { css } from "styled-components";
import { switchProp, theme } from "styled-tools";
import { Box, BoxProps } from "../box/Box";

export type Variants = "default" | "reverse" | "wideOffset";

interface GridProps extends BoxProps {
    variant?: Variants;
}

const defaultCSS = css`
    grid-template-columns: 1fr minmax(384px, 3fr) repeat(8, 1fr);
    .hero__img {
        grid-column: 1 / -1;
    }
    .hero__content {
        grid-column: 2 / span 1;
    }
`;

const reverse = css`
    grid-template-columns: repeat(8, 1fr) minmax(384px, 3fr) 1fr;
    .hero__img {
        grid-column: 1 / -1;
    }
    .hero__content {
        grid-column: 9 / span 1;
    }
`;

const wideOffset = css`
    .hero__img {
        grid-column: 3 / -1;
        height: 100%;
        > img {
            object-fit: cover;
            height: 100% !important;
        }
    }
    .hero__content {
        grid-column: 1 / span 5;
        background-color: white;
        @media (min-width: ${(prp) => prp.theme.breakpoints[2]}) {
            margin-top: 48px;
            margin-bottom: 48px;
        }
        > * {
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

export const Grid = styled(Box)<GridProps>`
    grid-template-columns: repeat(12, 1fr);
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    @media (min-width: ${(prp) => prp.theme.breakpoints[2]}) {
        display: grid;
        align-items: center;
    }
    .hero__img {
        display: block;
        grid-row: 2;
        z-index: 1;
    }
    .hero__content {
        grid-row: 2;
        z-index: 2;
    }
    ${switchProp("variant", {
    default: defaultCSS,
    reverse,
    wideOffset,
})}
`;

interface Props extends BoxProps {
    before?: ReactElement;
    image: ReactElement;
    contentBg?: string;
    variant?: Variants;
    href?: string;
    target?: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
}

const Hero: React.FC<React.PropsWithChildren<Props>> = (
    {
        before,
        image,
        href,
        target,
        contentBg = "white",
        variant = "default",
        children,
        onClick,
        ...rest
    }) => {
    const linkProps = href ? { href, target, onClick } : {};

    return (
        <Grid variant={variant} {...rest}>
            {before && <Box gridColumn="1 / -1">{before}</Box>}
            <Box className="hero__img" as={href ? "a" : "div"} {...linkProps}>
                {image}
            </Box>
            {children && (
                <Box className="hero__content">
                    <Box bg={contentBg} px={[0, 0, 4]} py={5}>
                        {children}
                    </Box>
                </Box>
            )}
        </Grid>
    );
};

export default Hero;
