import React from "react";
import ProductGrid from "@onnit-js/ui/components/module/grid/product/ProductGrid";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { Img } from "@onnit-js/ui/components/image";
import { Text } from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { FaChevronRight } from "react-icons/fa";
import { IconType } from "react-icons/lib";
import SuperScriptSpecialChars from "../common/SuperScriptSpecialChars";
import { Grid, GridItemNode } from "../../interfaces/SiteContent";

const getIcon = (name?: string | null): IconType => {
    switch (name) {
        case "Chevron Right":
        default:
            return FaChevronRight;
    }
};

interface Props extends BoxProps {
    data: Grid;
}

const ContentfulGrid = ({ data: { items, ...rest } }: Props) => (
    <ProductGrid {...rest}>
        {items.map((item: GridItemNode, index) => {
            const { url, image, title, titleColor, body, bodyColor, backgroundColor, button, variant } = item.data;
            const linkProps = url ? { href: url } : {};

            return (
                <Box
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    display="block"
                    backgroundColor={backgroundColor}
                    as={url ? "a" : "div"}
                    {...linkProps}
                    style={{ textDecoration: "none" }}
                >
                    <Box display="grid">
                        <Box gridColumn="1" gridRow="1" zIndex="1">
                            <Img {...image} />
                        </Box>
                        {button && variant === "buttonImageRight" && (
                            <Box
                                gridColumn="1"
                                gridRow="1"
                                zIndex="2"
                                display="flex"
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                p={2}
                            >
                                {button.icon ? (
                                    <Button
                                        el="span"
                                        icon={<Icon icon={getIcon(button.icon)} color={button.textColor} />}
                                        size="icon"
                                        color={button.color}
                                        textColor={button.textColor}
                                    />
                                ) : (
                                    <Button
                                        el="span"
                                        size={["small", "small", "small", "medium"]}
                                        color={button.color}
                                        textColor={button.textColor}
                                    >
                                        {button.text}
                                    </Button>
                                )}
                            </Box>
                        )}
                    </Box>
                    <Box py={4}>
                        <Text as="p" typeStyle="title04" mb={2} color={titleColor}>
                            <SuperScriptSpecialChars text={title} />
                        </Text>
                        {body && (
                            <Text as="p" typeStyle="text04" color={bodyColor}>
                                <SuperScriptSpecialChars text={body} />
                            </Text>
                        )}
                    </Box>
                </Box>
            );
        })}
    </ProductGrid>
);

export default ContentfulGrid;
