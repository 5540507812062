import AbstractClient from "./AbstractClient";
import config from "../config";
import RecommendationListResponse from "../@types/interfaces/amazonPersonalize/RecommendationListResponse";
import RecommendationListCommand from "../@types/interfaces/amazonPersonalize/RecommendationListCommand";

export default class PersonalizeClient extends AbstractClient {
    protected getBaseUrl(): string {
        return config.PERSONALIZE_API_URL;
    }

    protected withCredentials(): boolean {
        return false;
    }

    async listRecommendations(command: RecommendationListCommand): Promise<RecommendationListResponse> {
        const response = await this.axios.post("/recommendations", command, {
            headers: {
                "X-API-Key": config.PERSONALIZE_API_KEY,
            },
        });
        return response.data;
    }
}
