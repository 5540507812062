import React, { MouseEvent, ReactNode } from "react";
import Box, { BoxProps } from "../box/Box";
import styled, { css } from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ifProp } from "styled-tools";

interface ContainerProps extends BoxProps {
    defaultOnMobile?: boolean;
}

const Container = styled(Box)<ContainerProps>`
    display: grid;
    gap: ${themeGet("space.5")}px;
    
    &:hover {
        text-decoration: none !important;
    }
    
    > * {
        grid-column: 1;
    }

    .oui-block__content {
        display: flex;
        flex-direction: column;
    }

    .oui-block__content__title,
    .oui-block__content__subtitle,
    .oui-block__content__body {
        container-type: inline-size;
    }
    
    .oui-block__content__title > * {
        font-size: clamp(1.5rem, 4cqi, 3.5rem);
        line-height: 1.1em;
    }

    .oui-block__content__subtitle > * {
        font-size: clamp(1rem, 3cqi, 1.5rem);
    }

    .oui-block__content__body > * {
        font-size: clamp(1rem, 1.25cqi, 1.5rem);
    }

    .oui-block__content__body{
        margin-top: ${themeGet("space.3")}px;
    }

    .oui-block__image {
        grid-row: 1 / span 2;
    }

    .oui-block__content,
    .oui-block__button {
        //padding: 0 ${themeGet("space.5")}px ${themeGet("space.5")}px ${themeGet("space.5")}px;
    }

    /**
        Before image
     */
    &.oui-block__content-above-image {
        .oui-block__content {
            grid-row-start: 1;
            //padding: ${themeGet("space.5")}px ${themeGet("space.5")}px 0 ${themeGet("space.5")}px;
        }

        .oui-block__image {
            grid-row: 2 / span 2;
        }
    }

    /**
        Over image top
     */
    &.oui-block__content-over-image-top {
        .oui-block__content {
            grid-row-start: 1;
            padding: ${themeGet("space.5")}px;
        }
    }

    &.oui-block__button-over-image-top {
        .oui-block__image {
            grid-row: 1 / span 2;
        }

        .oui-block__button {
            grid-row-start: 1;
            padding: ${themeGet("space.5")}px;
        }
    }
    
    /**
        Over image bottom
     */
    &.oui-block__content-over-image-bottom {
        .oui-block__content {
            grid-row-start: 2;
            padding: 0 ${themeGet("space.5")}px ${themeGet("space.4")}px ${themeGet("space.5")}px;
        }
    }
    &.oui-block__button-over-image-bottom {
        .oui-block__image {
            grid-row: 1 / span 2;
        }

        .oui-block__button {
            grid-row-start: 2;
            padding: 0 ${themeGet("space.5")}px ${themeGet("space.4")}px ${themeGet("space.5")}px;
        }
    }

    &.oui-block__content-over-image-bottom.oui-block__button-over-image-bottom {
        gap: 0;

        .oui-block__image {
            grid-row: 1 / span 4;
        }

        .oui-block__content {
            grid-row-start: 3;
            justify-content: flex-end;
        }

        .oui-block__button {
            grid-row-start: 4;
        }
    }

    // vertical stack elements on mobile
    ${ifProp(
        "defaultOnMobile",
        css`
            @media (max-width: ${themeGet("breakpoints.0")}) {
                display: block;
                .oui-block__button {
                    display: block;
                }
            }
        `
    )}
`;

export type ContentLocation = "above-image" | "below-image" | "over-image-top" | "over-image-bottom";
export type ButtonLocation = "below-image" | "over-image-top" | "over-image-bottom";

interface Props extends Omit<ContainerProps, "title"> {
    image: ReactNode;
    title?: ReactNode;
    subTitle?: ReactNode;
    body?: ReactNode;
    button?: ReactNode;
    contentLocation?: ContentLocation;
    buttonLocation?: ButtonLocation;
    href?: string;
    target?: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
}

const Block: React.FC<Props> = ({
    image,
    button,
    title,
    subTitle,
    body,
    href,
    target,
    onClick,
    defaultOnMobile = false,
    contentLocation = "below-image",
    buttonLocation = "below-image",
    ...rest
}) => {
    const hasContent = title || subTitle || body;
    const linkProps = href ? { href, target, onClick } : {};

    return (
        <Container
            defaultOnMobile={defaultOnMobile}
            className={`oui-block oui-block__content-${contentLocation} oui-block__button-${buttonLocation}`}
            as={href ? "a" : "div"}
            {...linkProps}
            {...rest}
            draggable={false}
        >
            <Box className="oui-block__image">{image}</Box>

            {hasContent && (
                <Box className="oui-block__content">
                    {title && <div className="oui-block__content__title">{title}</div>}
                    {subTitle && <div className="oui-block__content__subtitle">{subTitle}</div>}
                    {body && <div className="oui-block__content__body">{body}</div>}
                </Box>
            )}

            {button && (
                <Box className={`oui-block__button`} display="flex" alignItems="flex-end">
                    {button}
                </Box>
            )}
        </Container>
    );
};

export default Block;
