import React from "react";
import { Box, BoxProps } from "../box/Box";
import Product from "../../@types/interfaces/product/BuyBoxProduct";
import OOSView from "./shared/OOSView";
import DiscontinuedView from "./shared/DiscontinuedView";
import getProductStatus from "./utils";
import ProductStatus from "../../@types/enums/ProductStatus";

interface Props extends BoxProps {
    renderInStockView: (product: Product) => React.JSX.Element;
    product: Product;
}

const SingleProductBuyBox = ({ renderInStockView, product, ...rest }: Props) => {
    if (!product) {
        return null;
    }

    const productStatus = getProductStatus(product);

    return (
        <Box {...rest}>
            {
                {
                    [ProductStatus.InStock]: renderInStockView(product),
                    [ProductStatus.OutOfStock]: <OOSView product={product} />,
                    [ProductStatus.Discontinued]: <DiscontinuedView />,
                }[productStatus]
            }
        </Box>
    );
};

export default SingleProductBuyBox;
