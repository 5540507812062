import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import styled from "styled-components";
import Button from "@onnit-js/ui/components/button/Button";
import React, { useEffect, useState } from "react";
import { Text } from "@onnit-js/ui/components/text";

const PageButton = styled(Button)`
    border-radius: 50%;
    padding: 4px 6px 4px 6px;
    display: inline-block;
    
    :disabled {
        background-color: rgb(40, 40, 40);
        border-color: rgb(40, 40, 40);
    }
`;

interface PageButtonProps extends BoxProps {
    arrowColor?: string;
    btnColor?: string;
    isFirstPage?: boolean;
    isLastPage?: boolean;
    currentPage: number;
    onClick: (event: any) => void;
    ref: any;
}

const PageButtonPrevious = React.forwardRef((
    {
        arrowColor = "#FEFEFE",
        btnColor = "grays.6",
        isFirstPage,
        currentPage,
        onClick
    }: PageButtonProps, ref: any) => {
    const handleOnClick = () => {
        if (!isFirstPage) {
            onClick(currentPage - 1);
        }
    };

    return (
        <PageButton
            ref={ref}
            size="medium"
            color={isFirstPage ? "grays.7" : btnColor}
            onClick={handleOnClick}
            disabled={isFirstPage}
            aria-label={isFirstPage ? "You are on the first page." : `Back to page ${currentPage - 1}."`}
        >
            <Box mt="4px" mr="6px" aria-hidden="true">
                <svg width="23px" height="25px" viewBox="0 0 23 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <title>Previous Page</title>
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Mobile" transform="translate(-101.000000, -2692.000000)" fill={arrowColor}>
                            <g id="Group-5" transform="translate(80.828488, 2673.000000)">
                                <polygon
                                    id="Fill-3"
                                    points="20.5102041 31.4485024 31.4489796 25.2952025 42.3877551 19.1428571 42.3877551 31.4485024 42.3877551 43.755102 31.4489796 37.6018022"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </Box>
        </PageButton>
    );
});

const PageButtonNext = React.forwardRef((
    {
        arrowColor = "#FEFEFE",
        btnColor = "grays.6",
        isLastPage,
        currentPage,
        onClick
    }: PageButtonProps, ref: any) => {
    const handleOnClick = () => {
        if (!isLastPage) {
            onClick(currentPage + 1);
        }
    };

    return (
        <PageButton
            size="medium"
            color={isLastPage ? "grays.7" : btnColor}
            onClick={handleOnClick}
            disabled={isLastPage}
            aria-label={`Go to page ${currentPage + 1}`}
            ref={ref}
        >
            <Box mt="4px" ml="6px" aria-hidden="true">
                <svg width="23px" height="25px" viewBox="0 0 23 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <title>Next Page</title>
                    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="Mobile" transform="translate(-254.000000, -2692.000000)" fill={arrowColor}>
                            <g id="Button" transform="translate(80.828488, 2673.000000)">
                                <g id="Group-5" transform="translate(149.000000, 0.000000)">
                                    <polygon
                                        id="Fill-3"
                                        points="46.4897959 31.4485024 35.5510204 37.6018022 24.6122449 43.755102 24.6122449 31.4485024 24.6122449 19.1428571 35.5510204 25.2952025"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            </Box>
        </PageButton>
    );
});

interface PagingSimpleProps extends BoxProps {
    highlightColor: string;
    items: any[];
    currentPage: number;
    maxPerPage: number;
    onClick: (event: any) => void;
}

const PagingSimple = ({ highlightColor, items, currentPage, maxPerPage, onClick, ...rest }: PagingSimpleProps) => {
    if (!items.length) {
        return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [pages, setPages] = useState<number>(Math.ceil(items.length ?? 0));

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (items.length) {
            setPages(Math.ceil(items.length / maxPerPage));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, currentPage]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const prevPageBtn = React.useRef<HTMLButtonElement>(null);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const nextPageBtn = React.useRef<HTMLButtonElement>(null);

    const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        switch (event.key) {
            case "ArrowLeft":
                prevPageBtn?.current?.click();
                break;
            case "ArrowRight":
                nextPageBtn?.current?.click();
                break;
            case "Home":
                event.preventDefault();
                onClick(1);
                break;
            case "End":
                event.preventDefault();
                onClick(pages);
                break;
            default:
                break;
        }
    };

    return (
        <Box {...rest} tabIndex={0} onKeyDown={handleOnKeyDown} as="nav" aria-describedby="paging-nav-instructions">
            <Box className="sr-only" id="paging-nav-instructions" color="white">
                Use <em>left</em> and <em>right</em> arrows to change pages.
                Use <em>home</em> key to go to first page. Use <em>end</em> key to go to last page.
            </Box>

            <PageButtonPrevious
                ref={prevPageBtn}
                isFirstPage={(currentPage === 1)}
                currentPage={currentPage}
                onClick={onClick}
                mx={1}
            />

            <Box display="inline" mx={2}>
                <Text
                    aria-live="polite"
                    color={highlightColor}
                    fontSize={1}
                    typeStyle="copy05"
                    display="inline"
                    fontWeight="bold"
                >
                    <span className="sr-only">Page{" "}</span>{currentPage}
                </Text>
                <Text color="white" fontSize={1} typeStyle="copy05" display="inline">
                    <span className="sr-only">of{" "}</span><span aria-hidden="true">{" "}/{" "}</span>{pages}
                    <span className="sr-only">Pages.</span>
                </Text>
            </Box>

            <PageButtonNext
                ref={nextPageBtn}
                isLastPage={(currentPage === pages)}
                currentPage={currentPage}
                onClick={onClick}
                mx={1}
            />
        </Box>
    );
};

export default PagingSimple;
