import isPropValid from "@emotion/is-prop-valid";
import { StyleSheetManager, ThemeProvider as StyledThemeProvider } from "styled-components";
import React, { PropsWithChildren } from "react";
import { ThemeInterface } from "./interfaces";

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName: string, target: any) => {
    if (typeof target === "string") {
        // For HTML elements, forward the prop if it is a valid HTML attribute
        return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
};

const ThemeProvider: React.FC<PropsWithChildren<{ theme: ThemeInterface }>> = ({ theme, children }) => (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <StyledThemeProvider theme={theme}>
            {children}
        </StyledThemeProvider>
    </StyleSheetManager>
);

export default ThemeProvider;
