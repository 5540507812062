import React, { useEffect, useState } from "react";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import AddToBagSimpleSticky from "./AddToBagSimpleSticky";
import AddToBagSticky from "./AddToBagSticky";

export interface DefaultProductStickysProps {
    products: Product[];
    productName: string;
    rating: number;
}

const StickyButtonContainer = styled.div`
    position: fixed;
    bottom: 10px;
    z-index: 9000 !important;
    right: 20px;
    margin: 10px 3px;
`;

export const useShowProductStickys = () => {
    const { ref: buyBoxRef, entry } = useInView({ initialInView: true });
    const [showSticky, setShowSticky] = useState(false);

    useEffect(() => {
        if (entry && entry?.boundingClientRect.bottom < 0) {
            setShowSticky(true);
        } else {
            setShowSticky(false);
        }
    }, [entry]);

    return {
        showSticky,
        buyBoxRef,
    };
};

export default ({ products, productName, rating }: DefaultProductStickysProps) => {
    if (!products) {
        return null;
    }

    return (
        <StickyButtonContainer>
            <AddToBagSimpleSticky products={products} />
            <AddToBagSticky products={products} productName={productName} rating={rating} />
        </StickyButtonContainer>
    );
};
