import React from "react";
import FavoriteContainer from "./FavoriteContainer";
import FavoriteButton from "./FavoriteButton";
import { ButtonProps } from "../../../button/Button";

interface Props extends ButtonProps {
    productId: number;
    iconOnly?: boolean;
}

export default function FavoriteContainerButton({ productId, ...rest }: Props) {
    return (
        <FavoriteContainer>
            {({ addProduct, removeProduct, isProductOnFavorites }) => (
                <FavoriteButton
                    {...rest}
                    productId={productId}
                    addProduct={addProduct}
                    removeProduct={removeProduct}
                    defaultSelected={isProductOnFavorites(productId)}
                />
            )}
        </FavoriteContainer>
    );
}
