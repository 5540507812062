import React from "react";
import { Text } from "@onnit-js/ui/components/text";
import { Card, Cards } from "@onnit-js/ui/components/module/Cards";
import { BoxProps } from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import { ContentCardCollection, ContentCardNode } from "../../interfaces/SiteContent";

interface ContentfulCardProps extends BoxProps {
    data: ContentCardCollection;
}

const ContentfulCards: React.FC<React.PropsWithChildren<React.PropsWithChildren<ContentfulCardProps>>> = (
    {
        data,
        ...rest
    }) => (
        <Cards
            before={
                data.title ? (
                    <Text as="h2" fontWeight="heavy" fontSize={5} mb={4}>
                        {data.title}
                    </Text>
                ) : undefined
            }
            {...rest}
        >
            {data.cards?.map(({ data: card }: ContentCardNode, index: number) => (
                <Card
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={`cards-${data.title?.toLowerCase().replace(" ", "")}-${index}`}
                    span={card.columnSpan ?? "auto"}
                    justifyContent={card.justifyContent}
                    href={card.url}
                    bgSrc={card.image?.src ?? ""}
                    lazy={card.lazyLoad}
                    overlayAlpha={card.overlayAlpha}
                >
                    {card.text
                        && (
                            <Text as="h3" typeStyle="text01" color="white">
                                {card.text}
                            </Text>
                        )}
                    {card.urlDescription
                        && (
                            <Text as="span" className="sr-only">{card.urlDescription}</Text>
                        )}
                    {card.cta && (
                        <Button el="span" size="small" raised={false} color="white" textColor="black">
                            {card.cta}
                        </Button>
                    )}
                </Card>
            ))}
        </Cards>
);

export default ContentfulCards;
