import { useCallback, useEffect, useState } from "react";
import { Favorite } from "../../../../@types/interfaces/favorites/Favorite";
import FavoritesBrowserStorageService from "./FavoritesBrowserStorageService";
import { create, destroy, get } from "./FavoritesClient";

export default function useFavorites() {
    const [favorites, setFavorites] = useState<Favorite[] | null>(null);

    const getCustomerIdFromCookie = (): number | undefined => {
        const cid = FavoritesBrowserStorageService.getCustomerId();
        return cid ? Number(cid) : undefined;
    };

    useEffect(() => {
        const getCustomerFavorites = async (): Promise<Favorite[]> => {
            const customerId = getCustomerIdFromCookie();
            if (customerId) {
                const existingFavorites = FavoritesBrowserStorageService.getFavorites();
                if (existingFavorites) {
                    return existingFavorites;
                }

                const paginator = await get(customerId);
                if (paginator.data) {
                    FavoritesBrowserStorageService.setFavorites(paginator.data);
                }
                return paginator.data;
            }
            return [];
        };

        getCustomerFavorites().then(setFavorites).catch(console.log);
    }, []);

    const addFavorite = useCallback(
        async (customerId: number, productId: number): Promise<void> => {
            // eslint-disable-next-line no-useless-catch
            try {
                const newItem = await create(customerId, productId);
                if (newItem) {
                    setFavorites((prevFavorites) => {
                        const newFavoritesList = prevFavorites ? [...prevFavorites, newItem] : [newItem];
                        FavoritesBrowserStorageService.setFavorites(newFavoritesList);
                        return newFavoritesList;
                    });

                    window.ONNIT?.googleTagManager?.productFavoriteAdded(newItem.product.product_name);
                }
            } catch (err) {
                throw err;
            }
        },
        []
    );

    const removeFavorite = useCallback(
        async (customerId: number, productId: number): Promise<void> => {
            const match = favorites?.find((item: Favorite) => item.product_id === productId);
            if (match) {
                try {
                    await destroy(customerId, match.customer_favorite_id);

                    window.ONNIT?.googleTagManager?.productFavoriteRemoved(match.product.product_name);
                } catch (err) {
                    console.log(err);
                }
                setFavorites((prevFavorites) => {
                    // We want to remove from localStorage even if it wasn't found in our DB.
                    const newFavoritesList = prevFavorites?.filter(
                        (item: Favorite) => item.customer_favorite_id !== match.customer_favorite_id
                    ) ?? [];
                    FavoritesBrowserStorageService.setFavorites(newFavoritesList);
                    return newFavoritesList;
                });
            }
        },
        [favorites]
    );

    const isProductOnFavorites = useCallback(
        (productId: number): boolean => {
            const match = favorites?.find((item: Favorite) => item.product_id === productId);
            return !!match;
        },
        [favorites]
    );

    return {
        favorites,
        addFavorite,
        removeFavorite,
        isProductOnFavorites,
    };
}
