export const getUrlParameter = (name: string) => {
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[]/u, "\\[").replace(/[\]]/u, "\\]");
    const regex = new RegExp(`[\\?&]${name}=([^&#]*)`, "u");
    // eslint-disable-next-line no-restricted-globals
    const results = regex.exec(location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/gu, " "));
};

export const isValidEmail = (email: string) => {
    if (email.trim() === "") {
        return false;
    }

    // eslint-disable-next-line require-unicode-regexp,no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.trim());
};

export const isValidPhoneNumber = (phone: string) => {
    const normalizedPhone = phone.trim();
    if (normalizedPhone === "") {
        return false;
    }
    return (/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/gu).test(normalizedPhone);
};

/**
 * Const REG = /^([mpfw][trblxy]?|outline|small|fullwidth|width|color|bg)$/u;
 removeProps(REG, props)
 * @param {RegExp} REG
 * @param {object} props
 */
export const removeProps = (REG: RegExp, props: any) => {
    const next: any = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const key in props) {
        if (REG.test(key)) {
            continue;
        }
        next[key] = props[key];
    }

    return next;
};

/**
 * Split Box Props
 */
const REG = /^([mpfw][trblxy]?|width|height|display|flex|alignItems|justifyContent|bg)$/u;

interface INameToValueMap {
    [key: string]: any;
}

export const splitBoxProps = (props: INameToValueMap, regEx = REG) => {
    const otherProps: INameToValueMap = {};
    const boxProps: INameToValueMap = Object.keys(props).reduce((acc: INameToValueMap, key: string) => {
        if (regEx.test(key)) {
            acc[key] = props[key];
        } else {
            otherProps[key] = props[key];
        }
        return acc;
    }, {});

    return [boxProps, otherProps];
};

/**
 * Generate a random string of characters. Useful for random class names
 */
export const generateString = (length: number) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

    let result = "";
    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus,id-length
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};
