import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { Box, BoxProps } from "../../box/Box";
import { FieldState } from "./FieldContext";
import { ThemeInterface } from "../../themes/interfaces";

interface Props {
    fieldState: FieldState;
    children?: ReactNode;
    bg?: string;
}

interface BorderProps extends BoxProps, FieldState {
    theme?: ThemeInterface;
}

const themeFieldColor = (prp: BorderProps) => (prp.currentState && prp.theme
    ? prp.theme.component.formfield[prp.currentState].borderColor
    : "gray");

const StyledBorder: React.FunctionComponent<React.PropsWithChildren<BorderProps>> = styled(Box)<BorderProps>`
    box-sizing: border-box;
    position: relative;
    /* overflow: hidden; */
    border-width: 2px;
    border-radius: 4px;
    border-style: solid;
    border-color: ${themeFieldColor};

    ${(prp) => {
    if (prp.disabled || prp.readOnly) {
        return css`
                background-color: ${themeFieldColor};
                pointer-events: none;
            `;
    }
    return "";
}};
`;

export default function FieldBorder({ fieldState, children, ...rest }: Props) {
    return (
        <StyledBorder {...fieldState} {...rest}>
            {children}
        </StyledBorder>
    );
}
