import React from "react";
import PageSection from "@onnit-js/ui/components/module/PageSection";
import { BoxProps } from "@onnit-js/ui/components/box/Box";
import { PageSection as PageSectionInterface } from "../../interfaces/SiteContent";
import ContentfulComponentMapper from "./ContentfulComponentMapper";

interface Props extends BoxProps {
    data: PageSectionInterface;
}

const ContentfulPageSection = ({ data: { content, boxProps, ...rest } }: Props) => (
    <PageSection {...rest} {...boxProps}>
        <ContentfulComponentMapper content={content} />
    </PageSection>
);

export default ContentfulPageSection;
