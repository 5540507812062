import React from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import MediaListItem, { MediaListGrid } from "@onnit-js/ui/components/module/MediaListItem";
import { Img } from "@onnit-js/ui/components/image";
import { Text } from "@onnit-js/ui/components/text";
import { MediaList, MediaListItemNode } from "../../interfaces/SiteContent";
import SuperScriptSpecialChars from "../common/SuperScriptSpecialChars";

interface ContenfulMediaListProps extends BoxProps {
    data: MediaList;
}

const ContentfulMediaList: React.FC<React.PropsWithChildren<React.PropsWithChildren<ContenfulMediaListProps>>> = ({ data }) => {
    if (!data.items || data.items.length === 0) {
        return null;
    }

    return (
        <MediaListGrid gridTemplateColumns={Array.from({ length: Number(data.columnCount) }, () => "1fr").join(" ")}>
            {data.items?.map(({ data: item }: MediaListItemNode, index: number) => {
                let ImageComponent = <></>;

                if (item.image) {
                    const { src, ...imgProps } = item.image;
                    ImageComponent = <Img lazyLoad={false} src={`${src}?q=40&fl=progressive`} {...imgProps} />;
                }

                return (
                    <MediaListItem
                        border={item.borderColor ? "3px solid" : undefined}
                        borderColor={item.borderColor}
                        borderRadius="8px"
                        backgroundColor={item.backgroundColor}
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        url={item.url}
                        my={4}
                        mx={[0, 0, 4, 5]}
                        image={ImageComponent}
                        headline={(
                            <Text
                                as="p"
                                display="flex"
                                alignItems="center"
                                typeStyle="text01"
                                textTransform="uppercase"
                                fontSize={[2, 2, 4, 4, 5]}
                                color={item.titleColor}
                            >
                                <span
                                    style={{
                                        marginRight: 16,
                                        // TextDecoration: item.url ? "underline" : undefined
                                    }}
                                >
                                    <SuperScriptSpecialChars text={item.title} />
                                </span>
                                {item.showButton && (
                                    <Box as="span" flex="0 0 15%" maxWidth="64px" ml="auto">
                                        <svg width="100%" viewBox="0 0 64 64" style={{ transform: "translateY(5px)" }}>
                                            <circle
                                                fill={item.buttonBackgroundColor ?? "black"}
                                                cx="28.5"
                                                cy="28.5"
                                                r="28.5"
                                                style={{
                                                    filter: "drop-shadow(rgba(0, 0, 0, 0.4) 3px 3px 2px)",
                                                }}
                                            />
                                            <polygon
                                                transform="translate(25.040000, 21.548181)"
                                                fill={item.buttonIconColor ?? "white"}
                                                points="0 2.184 2.212 0 8.652 6.384 8.652 6.804 2.212 13.188 0 11.004 4.424 6.608"
                                            />
                                        </svg>
                                    </Box>
                                )}
                            </Text>
                        )}
                    />
                );
            })}
        </MediaListGrid>
    );
};

export default ContentfulMediaList;
