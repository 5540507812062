import AppEnvironmentEnum from "../@types/enums/AppEnvironmentEnum";

if (!window.onnit_context) {
    throw new Error("`window.onnit_context` is undefined.");
}
const { environment } = window.onnit_context;
const APP_ENV_IS_PROD = environment === AppEnvironmentEnum.PRODUCTION;
const FREE_SHIPPING_THRESHOLD_DEFAULT = window.onnit_context?.free_shipping_threshold ?? 150;

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// This is NOT the place for secrets.
// Only PUBLIC values should be present here, since this is readable by the public.
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export default Object.freeze({
    // Alphabetical list.
    ALLIT_URL: window.onnit_context.url.allit,
    API_URL: window.onnit_context.url.api,
    APP_ENV: window.onnit_context.environment,
    APP_ENV_IS_PROD,
    CUSTOMER_ID_COOKIE: "ONNIT_CUSTOMER_ID",
    GOOGLE_MAPS_API_KEY: window.onnit_context?.api_key?.google_maps,
    GOOGLE_MAPS_SDK_URL: "https://maps.googleapis.com/maps/api/js",
    // The Webpack mode in which the UI bundle was built. Can be "development" or "production".
    NODE_ENV: process.env.NODE_ENV,
    ONNIT_FAVORITES: "ONNIT_FAVORITES", // Favorites localStorage key name
    RECAPTCHA_PUBLIC_KEY: window.onnit_context?.api_key?.recaptcha_public_key,
    PERSONALIZE_API_URL: window.onnit_context?.personalize?.api_url,
    PERSONALIZE_API_KEY: window.onnit_context?.personalize?.api_key,
    FREE_SHIPPING_THRESHOLD_DEFAULT,
    PAYPAL_CLIENT_ID: APP_ENV_IS_PROD
        ? "AQtuFVUYYqU6JK53wbCHr4Ae7nQ30czGSa3ok8yPoR76CEikBWcubMHOTNzyUdGGKoRBqBJD0IYrkxbl"
        : "AabpF245ilNDNXNvurIzj1_tzFqFx_TXti3VXjzcOS79m32tWmNNo6UbM8fYo-oAyVPa1q2ub35c4efK",
});
