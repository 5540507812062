import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from "styled-components";
import { TextProps } from "../text/Text";
import { Box, BoxProps } from "../box/Box";
import { Text } from "../text";

export interface TabExternalProps extends Omit<BoxProps, "title"> {
    title: (isActive: boolean) => ReactNode;
    content: ReactNode;
}

const Container = styled(Box)`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content max-content;
`;

const TabNav = styled.div`
    position: relative;
    grid-row: 1;
    width: 100%;
    overflow: hidden;
    margin-bottom: 8px;
    > div {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        scroll-behavior: smooth;
        scrollbar-width: none;
    }
`;

interface TabButtonProps extends TextProps {
    onTabClick: () => void;
}

const TabButton: React.FC<React.PropsWithChildren<TabButtonProps>> = ({
   onTabClick,
   children,
}) => (
    <Text
        as="button"
        role="tab"
        onClick={onTabClick}
        onKeyUp={(evt) => {
            if (evt.key === " " || evt.key === "Enter") {
                onTabClick?.();
            }
        }}
        style={{
            display: "inline-block",
            background: "none",
            border: "none",
            cursor: "pointer",
            margin: "12px 16px 12px 0",
            padding: 0,
            color: "unset",
            textDecoration: "none"
        }}
    >
        {children}
    </Text>
);

interface TabContentProps extends BoxProps {
    isActive: boolean;
}

const TabContent: React.FC<React.PropsWithChildren<TabContentProps>> = ({isActive, children}) => {
    return <Box display={isActive ? "block" : "none"}>{children}</Box>
};

interface TabsProps extends BoxProps {
    activeTabIndex?: number;
    onTabChange?: (activeIndex: number | undefined) => void;
}

interface TabsComposition {
    Tab: React.FC<React.PropsWithChildren<TabExternalProps>>;
}

const Tabs:React.FC<React.PropsWithChildren<TabsProps>> & TabsComposition = ({
    activeTabIndex = 0,
    onTabChange,
    children,
    ...rest
}) => {
    const [activeIndex, setActiveIndex] = useState(activeTabIndex);
    const numChildren = React.Children.count(children);
    const tabNavComponents: ReactNode[] = [];
    const tabContentComponents:ReactNode[] = [];

    React.Children.forEach(children, (child, index) => {
        if (!React.isValidElement(child)) {
            return;
        }
        const JSXProps: TabExternalProps & ReactElement = child.props;
        const isActive = index === activeIndex;

        tabNavComponents.push(
            React.createElement(TabButton, {
                onTabClick: () => {
                    setActiveIndex(index);
                },
                // eslint-disable-next-line react/no-array-index-key
                key: `oui-tab-title-${index}`
            }, JSXProps.title(isActive))
        );

        tabContentComponents.push(
            React.createElement(TabContent, {
                // eslint-disable-next-line react/no-array-index-key
                key: `oui-tab-content-${index}`,
                isActive
            }, JSXProps.content)
        );
    });

    useEffect(() => {
        if (onTabChange && activeIndex >= 0 && activeIndex <= numChildren){
            onTabChange(activeIndex);
        }
    }, [activeIndex, numChildren, onTabChange]);

    return (
        <Container {...rest}>
            <TabNav>
                <div>
                    {tabNavComponents}
                </div>
            </TabNav>
            <Box gridRow="2" width="100%" overflow="hidden">
                {tabContentComponents}
            </Box>
        </Container>
    );
};

export default Tabs;

// We need this dummy component as a way to get the "external props" to pass when we React.createElement because of TS :(
Tabs.Tab = () => null;
