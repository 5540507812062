import React, { CSSProperties } from "react";
import useOnnitContext from "../../../hooks/useOnnitContext";
import { Box, BoxProps } from "../../box/Box";
import { Text } from "../../text/Text";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";
import { getSizeText } from "./sizes";

interface Props extends Omit<BoxProps, "onChange"> {
    containerProduct: Product;
    selectedColorVariant: Product | null;
    selectedSizeVariant: Product | null;
    setColorVariant: (product: Product) => void;
    setSizeVariant: (product: Product) => void;
    onChange: (product: Product) => void;
}

const getColorLabel = (product: Product): string => {
    if (product.meta["a-color1"] === product.meta["a-color2"]) {
        return product.meta["a-color1"] ?? "";
    }
    return `${product.meta["a-color1"]} / ${product.meta["a-color2"]}`;
};

const OOSSvg: React.FC<React.PropsWithChildren<{ style: CSSProperties }>> = (props) => (
    <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none" {...props}>
        <line x1="0" y1="0" x2="100%" y2="100%" stroke="#666" strokeWidth="2px" />
        <line x1="0" y1="100%" x2="100%" y2="0" stroke="#666" strokeWidth="2px" />
    </svg>
);

export default function VariantSelector(
    {
        containerProduct,
        selectedColorVariant,
        selectedSizeVariant,
        setColorVariant,
        setSizeVariant,
        onChange,
        ...rest
    }: Props) {
    const onnitContext = useOnnitContext();

    if (!onnitContext || !selectedColorVariant || !selectedSizeVariant) {
        return null;
    }

    const isOnSale = selectedSizeVariant.sale_discount_ratio > 0;
    const saleText = isOnSale ? `${(selectedSizeVariant.sale_discount_ratio * 100).toFixed()}% off` : "";

    return (
        <Box {...rest}>
            {selectedSizeVariant.sale_discount_ratio === 1 ? (
                <Box mb={4}>
                    <Text display="inline-block" fontWeight="bold" fontSize={4}>
                        FREE
                    </Text>
                </Box>
            ) : (
                <Box mb={4}>
                    {isOnSale && (
                        <>
                            <span className="sr-only">Normally,</span>
                            <Text
                                fontSize={4}
                                display="inline-block"
                                textDecoration="line-through"
                                color="grays.4"
                                mr={2}
                            >
                                {selectedSizeVariant.price_msrp}
                            </Text>
                            <span className="sr-only">Your price today,</span>
                        </>
                    )}
                    <Text display="inline-block" fontWeight="heavy" fontSize={4}>
                        {selectedSizeVariant.price}
                    </Text>
                    {isOnSale && (
                        <Text display="inline-block" fontWeight="bold" color="grays.4" fontSize={4} ml={2}>
                            {saleText}
                        </Text>
                    )}
                </Box>
            )}
            {Number(containerProduct?.children?.length) > 0 && (
                <Box mb={3}>
                    <Box display="flex" mb={2} tabIndex={-1}>
                        <Text fontWeight="bold" mr={2}>
                            Color:
                        </Text>
                        {selectedColorVariant && <Text>{getColorLabel(selectedColorVariant)}</Text>}
                    </Box>
                    <Box display="flex" flexWrap="wrap">
                        {containerProduct.children?.map((variant) => (
                            <Box
                                as="button"
                                aria-label={`Color ${variant.meta["a-color1"]}, ${variant.meta["a-color2"]}`}
                                aria-pressed={variant === selectedColorVariant}
                                key={variant.id}
                                bg="grays.1"
                                overflow="hidden"
                                mr={1}
                                tabIndex={0}
                                onClick={() => setColorVariant(variant)}
                                width="58px"
                                height="80px"
                                style={{
                                    backgroundImage: `url(${onnitContext?.url.cdn.static_images}/authority/${variant.meta["authority-id"]}.png?w=100)`,
                                    backgroundPosition: "center top",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    cursor: "pointer",
                                    border: "none",
                                    boxShadow:
                                        variant === selectedColorVariant
                                            ? "inset 0 0 0 2px black"
                                            : "none",
                                }}
                            >
                                <span className="sr-only">Select color {getColorLabel(variant)}</span>
                            </Box>
                        ))}
                    </Box>
                </Box>
            )}

            {selectedColorVariant && Number(selectedColorVariant?.children?.length) > 0 && (
                <Box>
                    <Box display="flex" mb={2} tabIndex={-1}>
                        <Text fontWeight="bold" mr={2}>
                            Size:
                        </Text>
                        {selectedSizeVariant && <Text>{selectedSizeVariant.meta["a-size"]}</Text>}
                    </Box>
                    <Box display="flex" flexWrap="wrap">
                        {selectedColorVariant.children?.map((sizeVariant) => (
                            // eslint-disable-next-line react/button-has-type
                            <button
                                key={sizeVariant.id}
                                tabIndex={0}
                                onClick={() => onChange(sizeVariant)}
                                aria-pressed={sizeVariant === selectedSizeVariant}
                                style={{
                                    textAlign: "center",
                                    paddingLeft: 12,
                                    paddingRight: 12,
                                    paddingTop: 14,
                                    paddingBottom: 14,
                                    border: "none",
                                    position: "relative",
                                    marginRight: 8,
                                    marginBottom: 8,
                                    minWidth: 46,
                                    cursor: "pointer",
                                    backgroundColor:
                                    // eslint-disable-next-line no-nested-ternary
                                        sizeVariant === selectedSizeVariant
                                            ? sizeVariant.in_stock
                                                ? "black"
                                                : "#e4e4e4"
                                            : "transparent",
                                    boxShadow:
                                        sizeVariant === selectedSizeVariant
                                            ? "none"
                                            : "inset 0 0 0 1px #666",
                                }}
                            >
                                <Text
                                    fontSize={1}
                                    color={
                                        // eslint-disable-next-line no-nested-ternary
                                        sizeVariant.in_stock
                                            ? sizeVariant === selectedSizeVariant
                                                ? "white"
                                                : "black"
                                            : "#666"
                                    }
                                >
                                    <span className="sr-only">
                                        {`Size ${sizeVariant.meta["a-size"]} ${
                                            (sizeVariant.in_stock ? "" : ", out of stock")
                                        }`}
                                    </span>
                                    <span aria-hidden>
                                        {getSizeText(sizeVariant.meta["a-size"])}
                                    </span>
                                </Text>

                                {!sizeVariant.in_stock && (
                                    <OOSSvg style={{ position: "absolute", top: 0, left: 0 }} />
                                )}
                            </button>
                        ))}
                    </Box>
                </Box>
            )}
        </Box>
    );
}
