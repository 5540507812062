import React from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const getColor = (prp: any): string => {
    if (prp.color) {
        return prp.color.indexOf("#") === -1 ? themeGet(`colors.${prp.color}`)(prp) : prp.color;
    }
    return "black";
};

const Svg = styled.svg<{ active: boolean; color: string }>`
    path {
        stroke: ${getColor};
        fill: ${(prp) => (prp.active ? getColor(prp) : "transparent")};
    }
`;

const IndexIcon = ({ width = 32, active = false, color = "#585858", ...rest }) => (
    <Svg width={width} viewBox="0 0 53 31" xmlSpace="preserve" active={active} color={color} {...rest}>
        <path
            strokeWidth="1"
            d="M46.7,1L17.5,1c-1.9,0-3.1,1.3-3.4,2L1.8,23c-1.2,2-1.9,7,4.1,7l29.2,0c0.8,0,2.4-0.3,3.5-2 L51.3,7.2C52.5,5.2,52.8,1,46.7,1z"
        />
    </Svg>
);

export default IndexIcon;
