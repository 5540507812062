import React, { useState } from "react";
import { MdHelp } from "react-icons/md";
import { Text } from "../../text/Text";
import Modal from "../../modal/Modal";
import Icon from "../../icon/Icon";

interface Props {
    size?: number;
}

export default function SubscriptionInfoModal({ size }: Props) {
    const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
    return (
        <>
            <Icon
                size={size}
                color="#949494"
                icon={MdHelp}
                ariaLabel="Subscription Info"
                ml={1}
                onClick={() => {
                    setIsSubscriptionModalOpen(true);
                }}
            />
            <Modal
                title="Autoship &amp; Save"
                isOpen={isSubscriptionModalOpen}
                onRequestClose={() => setIsSubscriptionModalOpen(false)}
            >
                <Text mb={5} display="block">
                    With a subscription, you'll get your favorite Onnit products whenever you want at 15% off
                    MSRP. All automatic, worry free, and with our same great money back guarantee.
                </Text>
                <Text mb={5} display="block" fontSize={1}>
                    Cancel or adjust your order at any time, hassle free. Your payment method will only be
                    charged right before your order ships. The discount applied every time is 15% off MSRP or
                    the current sale discount, whichever is greater. For more information, see the complete
                    Onnit{" "}
                    <Text
                        as="a"
                        fontSize={1}
                        href="/terms-and-conditions/subscription/#terms"
                        display="inline"
                    >
                        Subscription Terms &amp; Conditions
                    </Text>{" "}
                    and{" "}
                    <Text as="a" fontSize={1} href="/terms-and-conditions/subscription/#faq" display="inline">
                        Frequently Asked Questions
                    </Text>
                    .
                </Text>
            </Modal>
        </>
    );
}
