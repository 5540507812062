import React, { ReactNode } from "react";
import { Box, BoxProps } from "../box/Box";
import Grid from "./grid/12/Grid";

interface Props extends BoxProps {
    item1: ReactNode;
    item2: ReactNode;
    item3: ReactNode;
}

export default ({ item1, item2, item3, ...rest }: Props) => (
    <Grid
        gridTemplateColumns="repeat(12, 1fr)"
        p={2}
        {...rest}
    >
        <Box
            p={2}
            gridRow={["2 / span 1", "2 / span 1", "1 / span 2"]}
            gridColumn={["1 / span 6", "1 / span 6", "2 / span 2"]}
        >
            {item1}
        </Box>
        <Box
            p={2}
            gridRow={["2 / span 1", "2 / span 1", "2 / span 2"]}
            gridColumn={["7 / span 6", "7 / span 6", "4 / span 2"]}
            display={["block", "block", "flex"]}
            alignItems="flex-end"
        >
            {item2}
        </Box>
        <Box
            p={2}
            gridRow={["1 / span 1", "1 / span 1", "1 / span 3"]}
            gridColumn={["1 / -1", "1 / -1", "7 / span 4"]}
        >
            {item3}
        </Box>
    </Grid>
);
