import React from "react";
import styled from "styled-components";
import { MdOpenInNew } from "react-icons/md";
import { TextProps } from "./Text";
import Icon from "../icon/Icon";

interface Props extends TextProps {
    noExternalLinkIcon?: boolean
    width?: number | undefined;
    height?: number | undefined;
    title?: string | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const NewExternalLinkIconSvg = styled.span.attrs((prp: Props) => ({}))<Props>`
    svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: 3px;
        fill: currentColor;
    }
`;

const NewWindowIndicator: React.FC<React.PropsWithChildren<Props>> = (
    {
        noExternalLinkIcon = false,
        width = 14,
        height = 14,
        title = "Opens in new window.",
        ...rest
    }) => (
    noExternalLinkIcon
        ? (
            <span className="sr-only">{title}</span>
        )
        : (

            <NewExternalLinkIconSvg {...rest}>
                <Icon width={width} height={height} title={title} icon={MdOpenInNew} />
            </NewExternalLinkIconSvg>
        )
);

export default NewWindowIndicator;
