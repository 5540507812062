import React, { ReactNode } from "react";
import styled from "styled-components";
import { prop, theme } from "styled-tools";
import { Box, BoxProps } from "../box/Box";

interface Props extends BoxProps {
    media?: ReactNode;
    headline: ReactNode;
}

const Container = styled(Box)`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .mediaHero02__content {
        grid-row: 4;
        grid-column: 2 / span 10;
    }

    @media ${prop("theme.mediaQueries.md")} {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        .mediaHero02__headline {
            grid-column: 2 / -2;
            grid-row: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 3;
        }
        .mediaHero02__media {
            grid-column: 5 / -2;
            grid-row: 2;
            transform: translateY(-45%);
            z-index: 0;
            div > * {
                width: 100%;
                display: block;
            }
        }

        .mediaHero02__content {
            grid-row: 4;
            grid-column: 3 / span 8;
        }
    }
`;

const MediaHero02: React.FC<React.PropsWithChildren<Props>> = ({ headline, media, content, children, ...rest }) => (
    <Container {...rest}>
        {media && (
            <Box p={[4, 0]} className="mediaHero02__media">
                <Box>{media}</Box>
            </Box>
        )}
        <Box className="mediaHero02__headline">
            <Box p={[4]}>{headline}</Box>
        </Box>
        {content && (
            <Box p={[4]} className="mediaHero02__content">
                {content}
            </Box>
        )}
        {children}
    </Container>
);

export default MediaHero02;
