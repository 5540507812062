import React, { ReactNode } from "react";
import { Box, BoxProps } from "../box/Box";
import makePseudoButtonProps from "../../utils/PropUtils";

interface MediaListItemProps extends BoxProps {
    image: ReactNode;
    headline: ReactNode;
    description?: ReactNode;
    url?: string;
}

export const MediaListGrid = ({ gridTemplateColumns = "1fr 1fr", ...rest }: BoxProps) => (
    <Box
        display={["block", "block", "grid"]}
        gridTemplateColumns={["1fr", "1fr", gridTemplateColumns as string]}
        maxWidth={1260}
        mx="auto"
        {...rest}
    />
);

export default function MediaListItem({ image, headline, description, url, ...rest }: MediaListItemProps) {
    const buttonProps = url
        ? makePseudoButtonProps(() => {
            if (url) {
                window.location.href = url;
            }
        })
        : {};
    return (
        <Box {...rest} {...buttonProps} display="flex" alignItems="center">
            <Box flex="0 0 25%" ml={[5, 5, 5, 7]}>
                {image}
            </Box>
            <Box
                flex="1 1 auto"
                display={["block", "block", "flex"]}
                alignItems="center"
                flexDirection={description ? ["column", "column", "row"] : "row"}
            >
                <Box flex={description ? "0 0 40%" : "1 1 auto"} px={4} py={2}>
                    {headline}
                </Box>
                {description && (
                    <Box flex="1 1 60%" p={[1, 2]}>
                        {description}
                    </Box>
                )}
            </Box>
        </Box>
    );
}
