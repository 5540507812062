import React, { ReactNode } from "react";
import { Box, BoxProps } from "../box/Box";
import Grid from "./grid/12/Grid";

interface Props extends BoxProps {
    children: [ReactNode, ReactNode];
}

export default ({ children, ...rest }: Props) => (
    <Grid p={2} gridTemplateColumns="repeat(12, 1fr)" {...rest}>
        <Box p={2} gridColumn={["5 / -1", "3 / 7"]} gridRow={["3 / span 2", "1"]} zIndex={2}>
            {children[0]}
        </Box>
        <Box p={2} gridColumn={["1 / 10", "8 / 12"]} gridRow={["1 / 4", "1"]} zIndex={1}>
            {children[1]}
        </Box>
    </Grid>
);
