import React, { useState } from "react";
import Button from "@onnit-js/ui/components/button/Button";
import Box from "@onnit-js/ui/components/box/Box";
import { Img } from "@onnit-js/ui/components/image";
import { Text } from "@onnit-js/ui/components/text";
import styled from "styled-components";
import Embed from "@onnit-js/ui/components/video/Embed";
import Modal from "@onnit-js/ui/components/modal/Modal";
import { Story } from "../../interfaces/Story";

const FadeBox = styled(Box)`
    position: absolute;
    width: 100%;
    height: 35%;
    content: "";
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 30px;
    background: linear-gradient(to top, black, transparent);
`;

interface StoryProps extends Story {
    baseUrl: string;
    imgParams: string;
    target?: string;
    onClick?: (event: React.MouseEvent) => void;
    isModal?: boolean;
}

export default ({
                    baseUrl,
                    headline,
                    title,
                    description,
                    img,
                    href,
                    imgAltText,
                    imgParams,
                    target,
                    onClick,
                    isModal = false,
                }: StoryProps) => {
    const [isOpen, setOpen] = useState<boolean>(false);
    const linkProps = isModal ? {
        onClick(event: any) {
            event.preventDefault();
            setOpen(true);
        },
    } : {
        href,
        target,
        onClick,
    };

    return (
        <Box
            display={["block", "block", "grid"]}
            gridTemplateColumns={[
                "1fr minmax(200px, 3fr) 7fr  1fr",
                "1fr minmax(150px, 3fr) 7fr  1fr",
                "1fr minmax(150px, 3fr) 7fr  1fr",
                "1fr minmax(360px, 3fr) 7fr  1fr",
            ]}
            py={[6, 6, 6, 8]}
            mx={2}
        >
            <Box
                px={4}
                pb={5}
                as="a"
                gridColumn={["1 / span 2", "1 / span 2", "1 / span 2", "1 / span 3"]}
                gridRow={1}
                {...linkProps}
                display="block"
                style={{ cursor: "pointer" }}
            >
                <Img
                    alt={imgAltText}
                    src={`${baseUrl}/${img}${imgParams}`}
                    placeholder={[345, 145]}
                    width={345}
                    height={145}
                />
            </Box>
            <Box
                gridColumn={["3 / span 1", "3 / span 1", "3 / span 1", "1 / span 3"]}
                gridRow={[1, 1, 1, 2]}
                px={[0, 0, 0, 4]}
            >
                <Box pb={4}>
                    <Text color="white" fontWeight="bold" as="h3">
                        <Text as="a" href={href} textDecoration="none">
                            {`"${headline}"`}
                        </Text>
                    </Text>
                </Box>
                <Box pt={[1, 1, 2]} pb={5}>
                    {title ? (
                        <Text color="white" as="h4">
                            {title}
                        </Text>
                    ) : (
                        <br />
                    )}
                </Box>
                <Box
                    pb={5}
                    display={["none", "block"]}
                    position="relative"
                    overflow="hidden"
                    style={{ height: "200px" }}
                >
                    <Text color="white" lineHeight={1.5} dangerouslySetInnerHTML={{ __html: description }} />
                    <FadeBox />
                </Box>
            </Box>
            <Box gridColumn={["1 / -1", "3 / span 1", "3 / span 1", "1 / -1"]} gridRow={[2, 3, 3, 3]} pt={[0, 0, 3, 4]}>
                <Button
                    el={isModal ? "button" : "a"}
                    p={1}
                    size="medium"
                    textColor="white"
                    color="#323030"
                    width={1}
                    aria-label={`Learn more about ${headline}.`}
                    {...linkProps}
                >
                    Full Story
                </Button>
            </Box>
            <Modal
                maxWidth="900px"
                isOpen={isOpen}
                title={(
                    <Text
                        color="black"
                        fontSize={[2, 3, 4]}
                        pb={4}
                        fontWeight="bold"
                        textTransform="uppercase"
                        dangerouslySetInnerHTML={{ __html: headline }}
                    />
                )}
                onRequestClose={() => setOpen(false)}
            >
                <Embed title={headline} src={href} />
            </Modal>
        </Box>
    );
};
