import Cookies from "js-cookie";
import config from "../../../../config";
import { Favorite } from "../../../../@types/interfaces/favorites/Favorite";

export default class FavoritesBrowserStorageService {
    static getCustomerId(): number | undefined {
        const cid = Cookies.get(config.CUSTOMER_ID_COOKIE);
        return cid ? Number(cid) : undefined;
    }

    static getFavorites(): Favorite[] | null {
        let favorites = null;

        try {
            const json = window.localStorage.getItem(config.ONNIT_FAVORITES);
            favorites = json ? JSON.parse(json) : null;
        } catch (error: any) {
            console.debug("Storage: Failed to get customer favorites in localStorage.", error);
        }

        return favorites;
    }

    static setFavorites(favorites: Favorite[]): void {
        try {
            window.localStorage.setItem(config.ONNIT_FAVORITES, JSON.stringify(favorites));
        } catch (error: any) {
            console.error("Storage: Failed to set favorites in localStorage.", error);
        }
    }

    static removeFavorites(): void {
        try {
            window.localStorage.removeItem(config.ONNIT_FAVORITES);
            console.debug("Storage: Removed favorites from localStorage.");
        } catch (error: any) {
            console.debug("Storage: Failed to remove favorites from localStorage.", error);
        }
    }
}
