import React, { MouseEvent, ReactElement } from "react";
import { Box, BoxProps } from "../box/Box";
import Grid from "./grid/12/Grid";

export type Variants = "primary" | "secondary";

interface Props extends BoxProps {
    variant?: Variants;
    heading?: ReactElement;
    image: ReactElement;
    content?: any;
    cta?: ReactElement;
    href?: string;
    target?: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
}

const Primary: React.FC<React.PropsWithChildren<Props>> = (
    {
        heading,
        image,
        content,
        cta,
        href,
        target,
        px = 4,
        onClick,
        ...rest
    }) => {
    const linkProps = href ? { href, target, onClick } : {};

    return (
        <Grid display={["block", "block", "block", "grid"]} px={px} {...rest}>
            <Box gridColumn="2 / span 10" pb={5} as={href ? "a" : "div"} {...linkProps} display="block">
                {image}
            </Box>
            {heading && (
                <Box gridColumn="2 / span 10" gridRow={1} pb={3}>
                    {heading}
                </Box>
            )}
            {content && (
                <Box gridColumn="2 / span 6" pb={[5, 5, 4]}>
                    {content}
                </Box>
            )}
            {cta && (
                <Box gridColumn="9 / span 3" pb={4}>
                    {cta}
                </Box>
            )}
        </Grid>
    );
};

const Secondary: React.FC<React.PropsWithChildren<Props>> = (
    {
        heading,
        image,
        content,
        cta,
        href,
        target,
        onClick,
        ...rest
    }) => {
    const linkProps = href ? { href, target, onClick } : {};

    return (
        <Box
            display={["block", "block", "block", "grid"]}
            gridTemplateColumns="1fr minmax(360px, 3fr) 7fr  1fr"
            {...rest}
        >
            <Box
                gridColumn="3 / span 1"
                gridRow={1}
                px={4}
                pb={5}
                as={href ? "a" : "div"}
                {...linkProps}
                display="block"
            >
                {image}
            </Box>
            <Box gridColumn="2 / span 1" gridRow={1} px={4}>
                {heading && <Box pb={4}>{heading}</Box>}
                {content && <Box pb={5}>{content}</Box>}
                {cta && <Box>{cta}</Box>}
            </Box>
        </Box>
    );
};

const Feature: React.FC<React.PropsWithChildren<Props>> = ({ variant = "primary", ...rest }) => ({
    primary: <Primary {...rest} />,
    secondary: <Secondary {...rest} />,
}[variant]);

export default Feature;
