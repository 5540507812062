import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import { Text } from "@onnit-js/ui/components/text";

export interface Filter {
    name: any;
    isActive: boolean;
}

type SetFilter = (filter: Filter, isActive: boolean) => void;

export const useFilters = (initialFilters: Filter[], onFiltersUpdated?: Function): [Filter[], SetFilter] => {
    const [filters, setCategoryFilters] = useState<Filter[]>(initialFilters);

    useEffect(() => {
        onFiltersUpdated?.();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const updateFilter = (filter: Filter, isActive: boolean) => {
        let newFilters: any[];
        if (filter.name === "All") {
            newFilters = filters.map((filter) => {
                filter.isActive = true;
                return filter;
            });
        } else {
            // Update selected filter.
            const filterIndex = filters.findIndex((fil) => fil.name === filter.name);
            newFilters = [...filters];
            newFilters[filterIndex].isActive = isActive;

            // Update the "All" filter if it exists relative to other filters.
            const allIndex = filters.findIndex((fil) => fil.name === "All");
            if (allIndex !== -1) {
                newFilters[allIndex].isActive = newFilters
                    .filter((fil) => fil.name !== "All")
                    .every((filter) => filter.isActive);
            }
        }

        setCategoryFilters(newFilters);
    };

    return [filters, updateFilter];
};

const FilterContainer = styled(Box)`
    white-space: nowrap;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }

    ::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
`;

interface FilterButtonProps extends BoxProps {
    activeColor?: string;
    inactiveColor?: string;
    filter: Filter;
    onFilterUpdate: SetFilter;
}

const FilterButton = ({ activeColor, inactiveColor = "#323030", filter, onFilterUpdate }: FilterButtonProps) => {
    const { isActive } = filter;
    const btnText = filter.name.replace(/([A-Z])/gu, " $1");

    return (
        <Button
            style={{ borderRadius: "50px", padding: "1px 8px", minWidth: "60px" }}
            mx={1}
            display="inline-block"
            size="small"
            textColor={isActive ? "black" : "grays.1"}
            color={isActive ? activeColor : inactiveColor}
            onClick={() => onFilterUpdate(filter, !isActive)}
            aria-label={`Filter by${btnText}.`}
        >
            {btnText}
            <Text
                aria-hidden="true"
                display="inline-block"
                textAlign="right"
                ml={2}
                color={isActive ? "black" : "grays.4"}
                dangerouslySetInnerHTML={{ __html: ` &check;` }}
            />
        </Button>
    );
};

interface PagingFiltersProps extends BoxProps {
    activeColor?: string;
    inactiveColor?: string;
    filters: Filter[];
    onFilterUpdate: SetFilter;
    ariaDescription: string;
}

const PagingFilters = (
    {
        activeColor,
        inactiveColor,
        filters,
        onFilterUpdate,
        ariaDescription,
        ...rest
    }: PagingFiltersProps) => (
        <FilterContainer {...rest}>
            <Box aria-describedby="filter-buttons" className="sr-only" backgroundColor="white">
                {ariaDescription}
            </Box>
            <Box id="filter-buttons">
                {filters && filters.map((filter, index) => (
                    <FilterButton
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        filter={filter}
                        onFilterUpdate={onFilterUpdate}
                        activeColor={activeColor}
                        inactiveColor={inactiveColor}
                    />
                ))}
            </Box>
        </FilterContainer>
);

export default PagingFilters;
