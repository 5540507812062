import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import React from "react";
// eslint-disable-next-line import/no-unresolved
import { Property } from "csstype";

export interface StarTestimonial {
    quote: string;
    author: string;
    starsColor?: string;
    color?: string;
}

interface FiveStarTestimonialProps extends BoxProps {
    title: any;
    testimonials: StarTestimonial[];
    titleColor?: string;
    textColor?: string;
    mb?: number;
    my?: number;
    maxWidth?: string;
    flexDirection?: Property.FlexDirection;
}

interface TestimonialProps extends BoxProps {
    quote: string;
    author: string;
    starsColor?: string;
    starsBgColor?: string;
    color?: string;
    mb?: number;
    my?: number;
}

const xmlNamespace = () => `
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    `;

const StarsSvg = ({ starsColor = "sunwashGold" }) => (
    <svg
        {...xmlNamespace}
        id="stars"
        style={{ display: "none" }}
        version="1.0"
    >

        <symbol id="stars-full-star" viewBox="0 0 102 18">
            <path
                d="M9.5 14.25l-5.584 2.936 1.066-6.218L.465 6.564l6.243-.907L9.5 0l2.792 5.657 6.243.907-4.517 4.404 1.066 6.218 z "
            />
        </symbol>

        <symbol id="stars-all-star" viewBox="0 0 102 18">
            <use xlinkHref="#stars-full-star" />
            <use xlinkHref="#stars-full-star" transform="translate(21)" />
            <use xlinkHref="#stars-full-star" transform="translate(42)" />
            <use xlinkHref="#stars-full-star" transform="translate(63)" />
            <use xlinkHref="#stars-full-star" transform="translate(84)" />
        </symbol>

        <symbol id="stars-5-0-star" viewBox="0 0 102 18">
            <use xlinkHref="#stars-all-star" fill={starsColor} />
        </symbol>
    </svg>
);
const Testimonial = ({
                         quote,
                         author,
                         starsColor = "sunwashGold",
                         color = "white",
                         mb = 2,
                         my = 6,
                         ...rest
                     }: TestimonialProps) => (
                         <Box my={my} {...rest}>
                             <Box width="140px" mb={2}>
                                 <StarsSvg starsColor={starsColor} />
                                 <svg
                                     role="img"
                                     viewBox="0 0 140 24"
                                     aria-label="Five star rating"
                                 >
                                     <title>Five star rating</title>
                                     <use xlinkHref="#stars-5-0-star" />
                                 </svg>
                             </Box>
                             <Text as="p" color={color} mb={2} pl={6} dangerouslySetInnerHTML={{ __html: quote }} />
                             <Text fontWeight="bold" as="p" color={color} mb={mb} pl={6}>
                                 - {author}
                             </Text>
                         </Box>
);

// eslint-disable-next-line func-style
function FiveStarTestimonials({ title, testimonials, titleColor = "white", textColor = "white", mb = 2, my = 6, maxWidth = "960px", flexDirection = "column", ...rest }: FiveStarTestimonialProps) {
    const isRow = flexDirection === "row";

    return (
        <Box maxWidth={maxWidth} mx="auto">
            <Text
                fontWeight="black"
                lineHeight={0}
                textTransform="uppercase"
                color={titleColor}
                fontSize={[9, 11, 12, 13, 14]}
                mb={[6, 6, 6]}
                mt={3}
            >
                {title}
            </Text>

            <Box display={["block", "block", isRow ? "flex" : "block"]} flexDirection={flexDirection} flexWrap="wrap">
                {testimonials.map((testimonial, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Box flexWrap="wrap" width={["100%", "100%", isRow ? "50%" : "100%"]} key={`testimonial-${index}`}>
                        <Testimonial
                            color={textColor}
                            starsColor={testimonial.starsColor}
                            quote={testimonial.quote}
                            author={testimonial.author}
                            mb={mb}
                            my={my}
                            {...rest}
                        />
                    </Box>
                    )
                )}
            </Box>
        </Box>
    );
}

export default FiveStarTestimonials;
