import { ProductBox } from "../../interfaces/ProductBox";

const dayPackIngredients: ProductBox[] = [
    {
        title: 'Alpha BRAIN<span class="oui-sup">&reg;</span>',
        content: 'Our flagship nootropic formula for mental speed, memory, and focus. It helps support alpha brain waves, and aids the production and maintenance of neurotransmitters to promote cognitive function.<span class="oui-sup">&dagger;</span>',
        img: "alpha-brain--ingredient.png",
        altText: "Bottle of Alpha BRAIN® on cutting board",
    },
    {
        title: 'Shroom Tech<span class="oui-sup">&reg;</span> SPORT',
        content: 'Clinically-studied ingredients combined for cellular energy and cardiovascular endurance, with adaptogens, and Vitamin B12 (methylcobalamin).<span class="oui-sup">&dagger;</span>',
        img: "shroom-tech-sport--ingredient.png",
        altText: "Hand holding bottle of Shroom Tech® SPORT",
    },
    {
        title: 'Stron BONE<span class="oui-sup">&reg;</span>',
        content: 'Designed to help the body build bone strength and structural integrity with strontium, boron, and other vitamins.<span class="oui-sup">&dagger;</span>',
        img: "stronbone--ingredient.png",
        altText: "Hands opening bottle of Stron BONE®",
    },
    {
        title: "Active B Complete™",
        content: 'A select range of B-vitamins for promoting energy production, cognitive performance, and neural communication, methylated for optimal absorption.<span class="oui-sup">&dagger;</span>',
        img: "active-b--ingredient.png",
        altText: "Bottle of Active B Complete™ on metal design",
    },
    {
        title: 'Shroom Tech<span class="oui-sup">&reg;</span> IMMUNE',
        content: 'A potent blend of nutritional mushrooms, herbs, and turmeric extract to support the innate immune system.<span class="oui-sup">&dagger;</span>',
        img: "shroom-tech-immune--ingredient.png",
        altText: "Bottle of Shroom Tech® IMMUNE in front of fresh fruits and vegetables",
    },
    {
        title: "Krill Oil",
        content: 'Packed with EPA &amp; DHA, Krill Oil supplies essential fatty acids from a clean, sustainable source that also contains the powerful carotenoid astaxanthin.<span class="oui-sup">&dagger;</span>',
        img: "krill--ingredient.png",
        altText: "Bottle of Krill Oil on ocean cliff",
    },
    {
        title: "Spirulina &amp; Chlorella",
        content: 'These quintessential green superfoods are blended in this supercombo, packing huge micronutrition that supports the immune system, endurance, and heart health.<span class="oui-sup">&dagger;</span>',
        img: "spirulina--ingredient.png",
        altText: "Hand holding bottle of Spirulina & Chlorella",
    },

];

export default dayPackIngredients;
