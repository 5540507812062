import { useState } from "react";
import ProductStatus from "../@types/enums/ProductStatus";
import Product from "../@types/interfaces/product/BuyBoxProduct";
import getProductStatus from "../components/buybox/utils";
import { getUrlParameter } from "../utils/utils";

export default function useSelectedProduct(products: Product[]) {
    const [selectedProduct, setProduct] = useState(() => {
        // Sort by stock status
        // eslint-disable-next-line no-nested-ternary
        products.sort((prodA: Product, prodB: Product) => (prodA.in_stock === prodB.in_stock ? 0 : prodA.in_stock ? -1 : 1));

        // On mount, check if the url has a specified PID or default to the first in stock product.
        const pid = getUrlParameter("pid");

        // If there is a pid in the url set it as selected no matter product status
        if (pid) {
            const match = products.find((prod) => prod && prod.id === Number(pid));
            if (match) {
                return match;
            }
        }

        // By default, set the product selected to the first product in stock.
        const inStockProducts = products.filter((product: Product) => getProductStatus(product) === ProductStatus.InStock);

        if (inStockProducts.length > 0) {
            return inStockProducts[0];
        }

        return products[0];
    });
    return {
        setProduct,
        selectedProduct
    };
}
