import React, { ReactNode, Ref } from "react";
import styled, { css } from "styled-components";
// eslint-disable-next-line import/no-unresolved
import * as CSS from "csstype";
import { ResponsiveValue } from "@techstack/styled-system";
import { FieldProps } from "../field/Field";
import { FieldConsumer, FieldProvider } from "../field/FieldContext";
import { splitBoxProps } from "../../../utils/utils";
import { Box } from "../../box/Box";
import ToggleIcon from "./ToggleIcon";
import { HelperText } from "../field";
import { Text } from "../../text";

interface Props extends Omit<FieldProps, "label">, React.InputHTMLAttributes<HTMLInputElement> {
    labelPosition?: "left" | "right";
    alignLabel?: ResponsiveValue<CSS.Property.AlignItems>;
    label?: ReactNode;
}

const FocusBorder = styled(Box)<{ isFocused?: boolean }>`
    ${(prp) => prp.isFocused
    && css`
            outline-width: 5px;
            outline-style: solid;
            outline-color: Highlight;
            @media (-webkit-min-device-pixel-ratio: 0) {
                outline-color: -webkit-focus-ring-color;
                outline-style: auto;
            }
        `}
`;
const CheckBox = (props: Props, ref: Ref<any>) => {
    const [boxProps, otherProps] = splitBoxProps(props);
    boxProps.my = boxProps.my ?? 3; // Because forwardRef can't have default props.
    const {
        children,
        helperText,
        error,
        icon,
        isValid,
        onBlur,
        onFocus,
        labelPosition = "right",
        type = "checkbox",
        alignLabel = "flex-start",
        ...inputProps
    } = otherProps;
    const label = otherProps.label ? (
        <Text mr={labelPosition === "left" ? 2 : 0} ml={labelPosition === "right" ? 2 : 0} lineHeight={6}>
            {inputProps.label}
        </Text>
    ) : (
        children
    );

    return (
        <FieldProvider {...inputProps} isValid={isValid} error={error} onBlur={onBlur} onFocus={onFocus}>
            <FieldConsumer>
                {({ fieldState, onBlur, onFocus, htmlId }) => (
                    <Box {...boxProps}>
                        <FocusBorder
                            isFocused={fieldState.focused}
                            as="label"
                            htmlFor={htmlId}
                            display="flex"
                            alignItems={alignLabel}
                            style={{ position: "relative", cursor: "pointer" }}
                        >
                            {labelPosition === "left" && label}
                            <input
                                style={{ opacity: 0, position: "absolute", zIndex: -1 }}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                {...inputProps}
                                type={type}
                                id={htmlId}
                                ref={ref}
                                aria-describedby={error || helperText ? `${htmlId}__helper-text` : undefined}
                            />
                            <ToggleIcon checked={inputProps.checked} type={type} />
                            {labelPosition === "right" && label}
                        </FocusBorder>
                        {error || helperText ? (
                            <HelperText
                                id={`${htmlId}__helper-text`}
                                fieldState={fieldState}
                                pl={labelPosition === "right" ? "30px" : 0}
                            >
                                {error || helperText}
                            </HelperText>
                        ) : null}
                    </Box>
                )}
            </FieldConsumer>
        </FieldProvider>
    );
};

export default React.forwardRef(CheckBox);
