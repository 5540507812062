import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import useRecommendationsList from "@onnit-js/ui/hooks/amazonPersonalize/useRecommendationsList";
import RecommendationsList from "./amazonPersonalize/RecommendationsList";

const RecommendationsPage: React.FC<React.PropsWithChildren<React.PropsWithChildren>> = () => {
    const {
        paginatedData,
    } = useRecommendationsList({ pageType: "HOME_PAGE", perPage: 3 });

    if (!paginatedData || !paginatedData.data) {
        return null;
    }

    return (
        <Box mb={4} px={4} maxWidth="1920px" margin="auto">
            <Text fontWeight="heavy" fontSize={5}>
                Your Recommendations
            </Text>
            <RecommendationsList recommendations={paginatedData?.data} />
        </Box>
    );
};

export default RecommendationsPage;
