import React from "react";
import { Img, Picture } from "@onnit-js/ui/components/image";
import Hero from "@onnit-js/ui/components/module/Hero";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import Button from "@onnit-js/ui/components/button/Button";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useMedia } from "@onnit-js/ui/components/media";
import { BANNER_RICH_TEXT_OPTIONS } from "../RichTextMapping";
import SuperScriptSpecialChars from "../../common/SuperScriptSpecialChars";
import usePromotionEvent from "../../../hooks/usePromotionEvent";

interface HeroProps {
    data: any;
    position: number;
    imgParams: string;
}

const ContentfulHero: React.FC<React.PropsWithChildren<React.PropsWithChildren<HeroProps>>> = (
    {
        data,
        imgParams,
        position
    }) => {
    const onClick = usePromotionEvent(`Hero - ${data.url.replace("/", "")}`, position);
    const isMobile = useMedia({ query: "(max-width: 1039px)" });
    const isWideOffset = data.variant === "wideOffset";
    const showContent = data.variant !== "imageOnly" || (data.variant === "imageOnly" && isMobile);

    return (
        <Hero
            variant={data.variant === "imageOnly" ? "reverse" : data.variant}
            mt={position === 1 ? 0 : [6, 6, 6, 8]}
            mb={[6, 6, 6, 8]}
            href={data.url}
            image={
                data.mobileImage ? (
                    <Picture
                        lazyLoad={position > 2}
                        width={data.image.width}
                        height={data.image.height}
                        /* eslint-disable @typescript-eslint/restrict-plus-operands */
                        src={[
                            data.mobileImage.src + (data.mobileImage.src.includes(".gif") ? "" : imgParams),
                            data.image.src + (data.image.src.includes(".gif") ? "" : imgParams),
                        ]}
                        alt={data.image.alt}
                    />
                ) : (
                    <Img
                        src={data.image.src + (data.image.src.includes(".gif") ? "" : imgParams)}
                        /* eslint-enable @typescript-eslint/restrict-plus-operands */
                        alt={data.image.alt}
                        width={data.image.width}
                        height={data.image.height}
                        lazyLoad={position > 2}
                    />
                )
            }
            onClick={onClick}
            before={
                isWideOffset && data.title ? (
                    <Text as="h2" fontWeight="heavy" fontSize={5} mb={3} px={4}>
                        <SuperScriptSpecialChars text={data.title} />
                    </Text>
                ) : undefined
            }
        >
            {showContent && (
                <Box px={4} textAlign={isWideOffset ? "center" : "left"}>
                    {!isWideOffset && data.title && (
                        <Text as="h2" typeStyle="title02" fontSize={[4, 4, 5, 5, 6, 6, 6]} mb={3}>
                            <SuperScriptSpecialChars text={data.title} />
                        </Text>
                    )}

                    {data.body && <Box mb={5}>{documentToReactComponents(data.body, BANNER_RICH_TEXT_OPTIONS)}</Box>}

                    {data.url && data.cta && (
                        <Button el="a" href={data.url} width={1} aria-label="Shop" onClick={onClick}>
                            {data.cta}
                        </Button>
                    )}
                </Box>
            )}
        </Hero>
    );
};

export default ContentfulHero;
