import Product from "../../../@types/interfaces/product/BuyBoxProduct";

export const SIZES = ["XXSMALL", "XSMALL", "SMALL", "MEDIUM", "LARGE", "XLARGE", "2XLARGE", "3XLARGE"];

export const sizeLabelMap = new Map([
    [SIZES[0], "XXS"],
    [SIZES[1], "XS"],
    [SIZES[2], "S"],
    [SIZES[3], "M"],
    [SIZES[4], "L"],
    [SIZES[5], "XL"],
    [SIZES[6], "2XL"],
    [SIZES[7], "3XL"],
]);

export const getSizeText = (size: string | undefined | null): string => {
    if (!size) {
        return "";
    }
    return sizeLabelMap.get(size) ?? size;
};

export const sortBySize = (productA: Product, productB: Product): number => {
    const aSize = productA.meta["a-size"] ?? "";
    const bSize = productB.meta["a-size"] ?? "";
    return SIZES.indexOf(aSize) - SIZES.indexOf(bSize);
};
