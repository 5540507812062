import React from "react";

export default function useRovingIndex(
    maxIndex: number,
    initialIndex = 0,
    doWrap = true
): [number, (nextIndex: number) => void, Function, Function] {
    const [currentIndex, setIndex] = React.useState(initialIndex);
    const decrementIndex = () => {
        if (currentIndex === 0 && doWrap) {
            setIndex(maxIndex);
            return;
        }
        if (currentIndex > 0) {
            setIndex((index) => index - 1);
        }
    };
    const incrementIndex = () => {
        if (currentIndex === maxIndex && doWrap) {
            setIndex(0);
            return;
        }
        if (currentIndex < maxIndex) {
            setIndex((index) => index + 1);
        }
    };
    const updateIndex = (nextIndex: number) => {
        // Check if next index is in bounds.
        if (nextIndex >= 0 && nextIndex <= maxIndex) {
            setIndex(nextIndex);
        }
    };

    return [currentIndex, updateIndex, decrementIndex, incrementIndex];
}
