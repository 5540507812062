import React from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import AddToCartButton from "../common/AddToCartButton";

export interface StickyButtonAddToBagSimpleProps extends BoxProps {
    children?: any;
    buttonText?: string;
    buttonTextColor?: string;
    buttonColor?: string;
    pid: number;
    buttonProps?: any
}

export default function StickyButtonAddToBagSimple(
    {
        children,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        buttonText = "Add to Bag",
        buttonTextColor,
        buttonColor,
        pid,
        buttonProps,
        ...rest
    }: StickyButtonAddToBagSimpleProps) {
    if (!pid) {
        return null;
    }

    return (
        <>
            <Box ml="auto" {...rest}>
                <AddToCartButton
                    style={buttonProps}
                    size="small"
                    color={buttonColor}
                    textColor={buttonTextColor}
                    configs={[
                        {
                            product_id: pid,
                            quantity: 1,
                            subscription_interval: null,
                        },
                    ]}
                />
            </Box>
        </>
    );
}
