import React, { ReactNode, useState } from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import { FiChevronRight } from "react-icons/fi";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { FontWeightNames } from "@onnit-js/ui/components/themes/interfaces";
// eslint-disable-next-line import/no-unresolved
import { Property } from "csstype";

interface AccordionItemProps extends BoxProps {
    title: string;
    children: ReactNode;
    headingLevel?: number;
    textTransform?: Property.TextTransform;
    chevronColor?: string;
    fontWeight?: FontWeightNames | undefined;
    underline?: boolean;
    defaultOpen?: boolean;
}

export const AccordionItem = (
    {
        title,
        children,
        fontSize,
        fontWeight = "bold",
        color = "black",
        headingLevel,
        chevronColor = "black",
        textTransform,
        underline,
        defaultOpen = false,
        ...rest
    }: AccordionItemProps) => {
    const [isOpen, setOpen] = useState(defaultOpen);

    return (
        <Box {...rest} fontSize={fontSize}>
            <Box display="flex" alignItems="center">
                <Text
                    as="button"
                    textAlign="left"
                    textTransform={textTransform}
                    fontSize={fontSize}
                    fontWeight={fontWeight}
                    aria-expanded={isOpen}
                    onClick={() => {
                        setOpen((openFlag) => !openFlag);
                    }}
                    position="relative"
                    pl="1.1em"
                    color={color}
                    style={{ textDecoration: underline ? "underline" : "none" }}
                >
                    <Icon
                        icon={FiChevronRight}
                        color={chevronColor}
                        iconStyle={{
                            width: "1.1em",
                            height: "auto",
                        }}
                        style={{
                            transition: "transform 300ms cubic-bezier(0.22, 0.61, 0.36, 1) 0s",
                            transform: isOpen ? `rotate(90deg)` : `rotate(0deg)`,
                        }}
                        position="absolute"
                        left="0"
                        top="1px"
                    />
                    {headingLevel
                        ? (<span role="heading" aria-level={headingLevel}>{title}</span>)
                        : (<>{title}</>)}
                </Text>
            </Box>
            {isOpen && (
                <Box py={2} pl="1em" pr={4}>
                    {children}
                </Box>
            )}
        </Box>
    );
};

export const Accordion: React.FC<React.PropsWithChildren<React.PropsWithChildren<BoxProps>>> = ({ children, ...rest }) => <Box {...rest}>{children}</Box>;
