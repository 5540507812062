import { ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

interface Props {
    children: ReactNode;
}

export default function Portal(props: Props) {
    const container = useRef<HTMLDivElement>(document.createElement("div"));

    useEffect(() => {
        document.body.appendChild(container.current);
        return () => {
            if (container.current && container.current.parentNode) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                container.current.parentNode.removeChild(container.current);
            }
        };
    }, []);

    return ReactDOM.createPortal(props.children, container.current);
}
