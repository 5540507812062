export enum StoryType {
    Hero = "Hero",
    Feature = "Feature",
}

export enum StoryCategory {
    Employee = "Employee",
    Pro = "Pro",
    Trainer = "Trainer",
    TribeMember = "TribeMember",
    Sports = "Sports",
    Lifestyle = "Lifestyle",
    Other = "Other",
}
