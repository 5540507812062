import React from "react";
import { Box } from "../../box/Box";
import { RadioButton } from "../../form/toggle/RadioGroup";
import { Text } from "../../text/Text";
import SubscriptionInfoModal from "../shared/SubscriptionInfoModal";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";
import CostPerServing from "../shared/CostPerServing";
import CostPerCount from "../shared/CostPerCount";
import ProductCategory from "../../../@types/enums/ProductCategory";
import PromoDiscountConfig from "../../../@types/interfaces/PromoDiscountConfig";
import { getActivePromoDiscountConfig } from "../shared/PromoDiscount";
import RiskFreeLinkText from "../shared/RiskFreeLinkText";
import SubscriptionDiscountCalculator from "../../../services/SubscriptionDiscountCalculator";

interface Props {
    product: Product;
    promoDiscountConfigs?: PromoDiscountConfig[] | null;
    freeShippingThreshold: number;
}

const calculator = new SubscriptionDiscountCalculator();

const SubscriptionRadioButton = (
    {
        product,
        promoDiscountConfigs,
        freeShippingThreshold
    }: Props) => {
    if (product.meta["subscription-eligible"] !== "yes") {
        return null;
    }

    const isOnSale = product.sale_discount_ratio > 0;
    const saleText = isOnSale ? `${(product.sale_discount_ratio * 100).toFixed()}% off` : "";
    const activePromoConfig = promoDiscountConfigs
        ? getActivePromoDiscountConfig(product.id, promoDiscountConfigs)
        : null;

    const intervalMonths = (parseInt(product.meta["default-subscription-frequency"]) || 30) / 30;
    const subscriptionDiscounts = calculator.getDiscounts({
        productId: product.id,
        msrp: Number(product.price_msrp_raw),
        price: Number(product.price_raw),
    }, activePromoConfig ? [activePromoConfig] : []);
    const isSupplementsOrFoods = product.meta.category === ProductCategory.FOODS
        || product.meta.category === ProductCategory.SUPPLEMENTS;

    return (
        <RadioButton value={product.id}>
            {parseInt(product.meta["default-subscription-frequency"]) >= 30 && (
                <Text color="grays.4" display="block">{`Autoship every ${intervalMonths} month${intervalMonths > 1 ? "s" : ""}`}</Text>
            )}
            <Box display="flex" alignItems="center" mb={1}>
                <Text color="grays.4">{product.name}</Text>
                <SubscriptionInfoModal />
            </Box>

            <RiskFreeLinkText />

            <p className="sr-only">Normally,</p>
            <Text display="inline-block" textDecoration="line-through" color="grays.4">
                {product.price_msrp}
            </Text>
            <p className="sr-only">Your price today,</p>
            <Text display="inline-block" fontWeight="bold" ml={1}>
                {subscriptionDiscounts.applies
                ? `$${subscriptionDiscounts.adjustedPrice.toFixed(2)}`
                : product.price}
            </Text>

            <Text display="inline-block" fontWeight="bold" color="grays.4" ml={2}>
                {subscriptionDiscounts.applies
                    ? `${subscriptionDiscounts.percent}% off`
                    : saleText}
            </Text>
            {subscriptionDiscounts.adjustedPrice >= freeShippingThreshold
                ? (
                    <Text display="block" fontSize={0} color="grays.4" mt={1}>
                        Free Shipping!
                    </Text>
                ) : null}
            {isSupplementsOrFoods && (
                <>
                    <CostPerServing
                        price={subscriptionDiscounts.adjustedPrice.toString()}
                        totalServings={product.product_servings_total}
                        display="none"
                        mt={1}
                    />
                    <CostPerCount
                        price={subscriptionDiscounts.adjustedPrice.toString()}
                        count={product.product_size}
                        unitOfMeasure={product.product_serving_unit_of_measure}
                        display="none"
                        mt={1}
                    />
                </>
            )}
        </RadioButton>
    );
};

export default SubscriptionRadioButton;
