import React, { ReactNode } from "react";
import { Box, BoxProps } from "../box/Box";

interface Props extends BoxProps {
    item1: ReactNode;
    item2: ReactNode;
    item3: ReactNode;
}

export default ({ item1, item2, item3, ...rest }: Props) => (
    <Box
        {...rest}
        maxWidth="${theme('gridMaxWidth')}px" /* eslint-disable-line no-template-curly-in-string */
        mx="auto"
        display="grid"
        gridTemplateColumns={["1fr 1fr", "repeat(12, 1fr)"]}
        gridTemplateRows={[
            "min-content min-content",
            "repeat(4, 1fr) min-content"
        ]}
        px={[1, 3]}
    >
        <Box
            p={2}
            gridColumn={["1 / -1", "2 / span 5"]}
            gridRow={["1", "2 / span 4"]}
        >
            {item1}
        </Box>
        <Box
            p={2}
            gridColumn={["1 / span 1", "8 / span 2"]}
            gridRow={["2", "1 / span 2"]}
        >
            {item2}
        </Box>
        <Box
            p={2}
            gridColumn={["2 / span 1", "9 / span 3"]}
            gridRow={["2", "5 / span 1"]}
        >
            {item3}
        </Box>
    </Box>
);
