import React, { ReactNode } from "react";
import { FaChevronDown } from "react-icons/fa";
import styled from "styled-components";
import { ifProp } from "styled-tools";
import { themeGet } from "@styled-system/theme-get";
import { Box } from "../../box/Box";
import Field, { FieldProps } from "../field/Field";
import { FieldConsumer, FieldProvider } from "../field/FieldContext";
import { splitBoxProps } from "../../../utils/utils";
import Icon from "../../icon/Icon";

export interface SelectFieldProps extends FieldProps, React.InputHTMLAttributes<HTMLSelectElement> {
    renderSelectedValue?: (value: string) => ReactNode;
    bg?: string;
}

const StyledSelect = styled.select<{ hasLabel?: boolean; hasIcon?: boolean }>`
    box-sizing: border-box;
    border: none;
    background: none;
    color: ${themeGet("colors.text")};
    font-family: ${themeGet("fonts.primary")};
    font-size: ${themeGet("fontSizes.2")}px;
    letter-spacing: normal;
    padding: ${ifProp("hasLabel", "32px 10px 10px 10px", "13px 10px 13px 10px")};
    padding-right: ${ifProp("hasIcon", "57px", 0)};
    position: relative;
    width: 100%;
    overflow: hidden;
    z-index: 10;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    &:focus {
        outline: none;
    }
`;
const SelectField = (props: SelectFieldProps, ref: any) => {
    const [boxProps, otherProps] = splitBoxProps(props);
    const {
        helperText,
        label,
        error,
        isValid,
        children,
        onBlur,
        onFocus,
        renderSelectedValue,
        ...inputProps
    } = otherProps;
    const hasLabel = Boolean(label);

    return (
        <FieldProvider {...inputProps} isValid={isValid} error={error} onBlur={onBlur} onFocus={onFocus}>
            <FieldConsumer>
                {({ onBlur, onFocus, fieldState, htmlId }) => (
                    <Field
                        htmlFor={htmlId}
                        fieldState={fieldState}
                        boxProps={boxProps}
                        helperText={helperText}
                        label={label}
                        error={error}
                        icon={<Icon icon={FaChevronDown} color="grays.4" aria-hidden="true" />}
                    >
                        {/* eslint-disable-next-line no-nested-ternary */}
                        <Box position="relative" minHeight={renderSelectedValue ? (hasLabel ? 60 : 44) : 0}>
                            <StyledSelect
                                hasLabel={!!label}
                                hasIcon
                                ref={ref}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                {...inputProps}
                                id={htmlId}
                                aria-describedby={error || helperText ? `${htmlId}__helper-text` : undefined}
                                style={
                                    renderSelectedValue
                                        ? {
                                            opacity: 0,
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                        }
                                        : {
                                            // eslint-disable-next-line no-negated-condition,no-nested-ternary
                                            opacity: !hasLabel ? 1 : fieldState.hasValue ? 1 : 0,
                                        }
                                }
                            >
                                {children}
                            </StyledSelect>
                            {renderSelectedValue && (
                                <Box pt={hasLabel ? 6 : 3} pb={3} pl={3} pr="57px">
                                    {renderSelectedValue(inputProps.value)}
                                </Box>
                            )}
                        </Box>
                    </Field>
                )}
            </FieldConsumer>
        </FieldProvider>
    );
};

export default React.forwardRef(SelectField);
