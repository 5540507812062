import React, { MouseEvent, ReactNode } from "react";
import { Box, BoxProps } from "../../../box/Box";
import { Img } from "../../../image";
import { Text, TextProps } from "../../../text/Text";
import useResponsiveProp from "../../../../hooks/useResponsiveProp";

export type ProductGridItemVariants = "default" | "buttonImageRight";

export interface DefaultProductBoxProps {
    title: string;
    titleProps?: TextProps;
    content?: string;
    contentProps?: TextProps;
    imgUrl: string;
    altText: string;
    href?: string;
    target?: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
    children?: ReactNode;
}

export const DefaultProductBox = (
    {
        title,
        titleProps,
        content,
        contentProps,
        imgUrl,
        altText,
        href,
        target,
        onClick,
        children,
    }: DefaultProductBoxProps) => {
    const linkProps = href ? { href, target, onClick } : {};

    return (
        <Box display="block" as={href ? "a" : "div"} {...linkProps} style={{ textDecoration: "none" }}>
            <Img placeholder={[460, 460]} src={`${imgUrl}`} alt={altText} />
            <Box py={4}>
                <Text
                    as="p"
                    typeStyle="title04"
                    mb={2}
                    dangerouslySetInnerHTML={{ __html: title }}
                    {...titleProps}
                />
                {content && (
                    <Text
                        as="p"
                        typeStyle="text04"
                        {...contentProps}
                        dangerouslySetInnerHTML={{ __html: content }}
                    />
                )}
                {children}
            </Box>
        </Box>
    );
};

const gridGapSizeMap = {
    none: 0,
    small: 32,
    medium: 64,
    large: 96,
    xlarge: 128,
};

export type GridGapSizes = keyof typeof gridGapSizeMap;
export type GridNumColumns = 2 | 3 | 4 | 6 | 12;

export interface ProductGridProps extends BoxProps {
    gridGapSize?: GridGapSizes;
    columnCount?: GridNumColumns;
}

const ProductGrid: React.FC<React.PropsWithChildren<ProductGridProps>> = (
    {
        columnCount = 3,
        gridGapSize = "none",
        children,
        ...rest
    }) => {
    const gap = useResponsiveProp([24, 32, 48, gridGapSizeMap[gridGapSize]]);
    // For 3 children we don't want to have one item on its own row.
    const isThree = React.Children.count(children) === 3;
    const columns = useResponsiveProp(isThree ? [1, columnCount] : [1, 2, 2, columnCount]);

    return (
        <Box
            display="grid"
            gridTemplateColumns={`repeat(${columns}, 1fr)`}
            gridGap={gap}
            px={[0, 0, 48, gridGapSizeMap[gridGapSize]]}
            {...rest}
            /* eslint-disable-next-line react/no-children-prop */
            children={children}
        />
    );
};

export default ProductGrid;
