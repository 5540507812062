export type TypeStyles = keyof typeof textVariants;

export const textVariants = {
    title01: {
        fontFamily: "primary",
        fontWeight: "black",
        fontSize: [6, 6, 9, 11],
        lineHeight: 2,
        textTransform: "uppercase",
    },
    title02: {
        fontFamily: "primary",
        fontWeight: "black",
        fontSize: [4, 4, 5, 7],
        fontStyle: "italic",
        lineHeight: 3,
        textTransform: "uppercase",
    },
    title03: {
        fontFamily: "primary",
        fontWeight: "heavy",
        fontSize: [5, 5, 6, 7],
        lineHeight: 4,
        textTransform: "uppercase",
    },
    title04: {
        fontFamily: "primary",
        fontWeight: "black",
        fontSize: [1, 1, 1, 2],
        lineHeight: 2,
        textTransform: "uppercase",
    },
    text01: {
        fontFamily: "primary",
        fontWeight: "heavy",
        fontSize: [2, 2, 4, 6],
        lineHeight: 3,
    },
    text02: {
        fontFamily: "primary",
        fontWeight: "bold",
        fontSize: [2, 2, 2, 4],
        lineHeight: 5,
    },
    text03: {
        fontFamily: "primary",
        fontWeight: "bold",
        fontSize: [1, 1, 1, 2],
        lineHeight: 6,
    },
    text04: {
        fontFamily: "primary",
        fontWeight: "bold",
        fontSize: [0, 0, 0, 1],
        lineHeight: 6,
    },
    caption01: {
        fontFamily: "primary",
        fontWeight: "heavy",
        fontSize: [1, 1, 2],
        lineHeight: 2,
    },
    caption02: {
        fontFamily: "primary",
        fontWeight: "regular",
        fontSize: [0, 0, 0, 1],
        lineHeight: 3,
    },

    // Deprecated
    headlineHero01: {
        fontFamily: "primary",
        fontWeight: "black",
        fontSize: [8, 12, 14, 16, 18],
        lineHeight: 0,
        textTransform: "uppercase",
    },
    headlineHero02: {
        fontFamily: "primary",
        fontWeight: "black",
        fontStyle: "italic",
        fontSize: [10, 12, 12, 14, 16],
        lineHeight: 0,
        textTransform: "uppercase",
    },
    headlineHero03: {
        fontFamily: "primary",
        textTransform: "uppercase",
        fontWeight: "bold",
        fontStyle: "italic",
        fontSize: [10, 14],
        lineHeight: 2,
    },
    copyDisplay01: {
        fontFamily: "primary",
        fontWeight: "bold",
        fontStyle: "italic",
        fontSize: [6, 7, 8, 9],
        lineHeight: 2,
    },
    copyDisplay02: {
        fontFamily: "primary",
        fontWeight: "black",
        fontStyle: "italic",
        fontSize: [5, 5, 6, 8],
        lineHeight: 3,
        textTransform: "uppercase",
    },
    copyHero01: {
        fontFamily: "primary",
        fontWeight: "heavy",
        fontSize: [3, 3, 4, 5, 6],
        lineHeight: 5,
    },
    copyHero02: {
        fontFamily: "primary",
        fontWeight: "bold",
        fontSize: [2, 2, 3, 4, 5],
        lineHeight: 5,
    },

    copy01: {
        fontFamily: "primary",
        fontWeight: "heavy",
        textTransform: "uppercase",
        fontSize: [2, 2, 3, 3, 4],
        lineHeight: 5,
    },
    copy02: {
        fontFamily: "primary",
        fontWeight: "light",
        fontSize: [2, 2, 2, 3, 4],
        lineHeight: 5,
    },
    copy03: {
        fontFamily: "primary",
        fontWeight: "bold",
        fontStyle: "italic",
        textTransform: "uppercase",
        fontSize: [2, 2, 3],
        lineHeight: 2.1,
    },
    copy04: {
        fontFamily: "primary",
        fontWeight: "heavy",
        fontStyle: "italic",
        textTransform: "uppercase",
        fontSize: [1, 1, 2],
        lineHeight: 5,
    },
    copy05: {
        fontFamily: "primary",
        fontWeight: "heavy",
        textTransform: "uppercase",
        fontSize: [3],
        lineHeight: 6,
    },
    body: {
        fontFamily: "primary",
        fontSize: 2,
        fontWeight: "regular",
        color: "text",
        lineHeight: 6,
    },
    default: {
        fontFamily: "primary",
        fontSize: 2,
        fontWeight: "regular",
        color: "text",
        lineHeight: 6,
    },
};
