import { useCallback, useEffect, useRef } from "react";

export default function useScrollToRef(selector?: string, buffer = 0): [React.RefObject<any>, Function] {
    const ref = useRef<any>(null);

    const scrollTo = useCallback(() => {
        if (ref.current) {
            ref.current.scrollIntoView();
        }

        if (buffer) {
            const scrolledY = window.scrollY;

            if (scrolledY) {
                window.scroll(0, scrolledY - buffer);
            }
        }
    }, [buffer]);

    useEffect(() => {
        if (selector) {
            const el = document.querySelector(selector);
            if (el) {
                ref.current = el;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [ref, scrollTo];
}
