import React, { ReactNode } from "react";

const SuperScriptSpecialChars: React.FC<React.PropsWithChildren<React.PropsWithChildren<{
    text: string
}>>> = ({ text }) => {
    const regex = /(™|®|†)/gmu;
    return (
        <>
            {text.split("").reduce<ReactNode[]>((acc, char: string, index) => {
                if (regex.test(char)) {
                    acc = [
                        ...acc,
                        // eslint-disable-next-line react/no-array-index-key
                        <sup key={`sup-${index}`} className="oui-sup">
                            {char}
                        </sup>,
                    ];
                } else if (index === 0) {
                    acc.push(char);
                } else {
                    const lastChar = acc[acc.length - 1];
                    if (lastChar && typeof lastChar === "string") {
                        acc[acc.length - 1] = lastChar.concat(char);
                    } else {
                        acc.push(char);
                    }
                }

                return acc;
            }, [])}
        </>
    );
};

export default SuperScriptSpecialChars;
