import React, { useEffect, useState } from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import Button, { ButtonProps } from "@onnit-js/ui/components/button/Button";
import BuyBoxProduct from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import Modal from "@onnit-js/ui/components/modal/Modal";
import BuyBox from "@onnit-js/ui/components/buybox/BuyBox";
import { Text } from "@onnit-js/ui/components/text";
import BazaarVoiceStarRatingNoLinks from "../common/BazaarVoiceStarRatingNoLinks";

export interface StickyButtonAddToBagProps extends BoxProps {
    buttonText?: string;
    buttonProps?: ButtonProps;
    children?: any;
    products: BuyBoxProduct[];
    freeShippingThreshold?: number;
    productName: string;
    rating?: number;
}

export default function StickyButtonAddToBagModal(
    {
        buttonText = "Add to Bag",
        products,
        freeShippingThreshold,
        productName,
        rating,
        buttonProps,
    }: StickyButtonAddToBagProps) {
    const [isModalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        document.dispatchEvent(new Event(isModalOpen ? "buyBoxModalOpen" : "buyBoxModalClosed"));
    }, [isModalOpen]);

    return (
        <>
            <Button {...buttonProps} onClick={openModal}>
                {buttonText}
            </Button>
            <Modal
                title={(
                    <Text
                        as="span"
                        fontSize={[5, 6, 7]}
                        pb={4}
                        fontWeight="bold"
                        fontStyle="italic"
                        dangerouslySetInnerHTML={{ __html: productName }}
                    />
                )}
                aria-describedby="addToBagDescription"
                isOpen={isModalOpen}
                onRequestClose={closeModal}
            >
                {rating ? <BazaarVoiceStarRatingNoLinks rating={rating} mt={-4} mb={4} /> : ""}
                <Box className="sr-only" id="addToBagDescription">
                    Select options to add item to your bag.
                </Box>
                <BuyBox
                    showFavoritesButton={false}
                    products={products}
                    freeShippingThreshold={freeShippingThreshold}
                    onSuccess={closeModal}
                    onError={closeModal}
                />
            </Modal>
        </>
    );
}
