import { Faq } from "../../components/common/Faqs";

const faqs: Faq[] = [
    {
        question:
            'What is the benefit of a product like Total GUT HEALTH<span class="oui-sup">&reg;</span>?',
        answer:
            'Practically everyone can benefit from a product like Total GUT HEALTH<span class="oui-sup">&reg;</span>! It provides digestive enzymes and Betaine HCl to help break down the foods we eat, prebiotics to feed the good bacteria, and probiotics to help balance the gut. This is a digestive game-changer! ',
    },
    {
        question: 'Why should I take Total GUT HEALTH<span class="oui-sup">&reg;</span>?',
        answer:
            'Our modern lifestyles don’t support proper digestion: eating too fast, occasional poor food choices, daily stress, etc. A product like this provides the building blocks needed for the GI tract to perform at its best, and as a result, helps keep you performing at your best!<span class="oui-sup">&dagger;</span>',
    },
    {
        question: "Is this a probiotic?",
        answer:
            'Total GUT HEALTH<span class="oui-sup">&trade;</span> is so much more than a probiotic! It contains pre and probiotics to keep the gut microbiome in tip-top shape.',
    },
    {
        question:
            'How long will it take for my body to adjust to Total GUT HEALTH<span class="oui-sup">&reg;</span>?',
        answer:
            "Some users experience rapid results. This can look like less occasional bloating after a meal, or just smoother-feeling digestion in general.†.",
    },
    {
        question: "Do I need to take it everyday? When is the best time to take it?",
        answer:
            "Take one serving (4 capsules) with your biggest meal of the day.",
    },
];

export default faqs;
