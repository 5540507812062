enum SubscriptionIntervalEnum {
    SEVEN_DAYS = 7,
    FIFTEEN_DAYS = 15,
    TWENTY_DAYS = 20,
    THIRTY_DAYS = 30,
    FORTY_FIVE_DAYS = 45,
    SIXTY_DAYS = 60,
    NINETY_DAYS = 90,
}

export default SubscriptionIntervalEnum;
