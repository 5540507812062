import React, { ReactNode } from "react";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";
import { Box, BoxProps } from "../box/Box";

interface Props extends BoxProps {
    image?: ReactNode;
    reverse?: boolean;
}

const Grid = styled(Box)<BoxProps & { reverse: boolean }>`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: column;

    .col {
        grid-column: 1 / -1;
        min-width: 0; // hack
    }

    .col__left,
    .col__right {
        grid-column: 1 / -1;
    }

    @media (min-width: ${(prp) => prp.theme.breakpoints[1]}) {
        .col__left {
            grid-column-start: ${ifProp("reverse", 4, 2)};
            grid-column-end: span 1;
        }
        .col__right {
            grid-column-start: ${ifProp("reverse", 2, 4)};
            grid-column-end: span 1;
        }
    }

    @media (min-width: ${(prp) => prp.theme.breakpoints[1]}) {
        grid-template-columns: 0 4fr 0 4fr 0;
    }
    @media (min-width: ${(prp) => prp.theme.breakpoints[2]}) {
        grid-template-columns: ${ifProp(
    "reverse",
    "1fr minmax(auto, 5fr) 1fr minmax(420px, 4fr) 1fr",
    "1fr minmax(420px, 4fr) 1fr minmax(auto, 5fr) 1fr"
)};
    }
`;

const ImageContentPortrait = ({ image, content, reverse = false, ...rest }: Props) => (
    <Grid {...rest} reverse={reverse}>
        <div className="col col__left">
            <Box p={[4, 4, 4, 5]}>{image}</Box>
        </div>
        <div className="col col__right">
            <Box p={[4, 4, 4, 5]} height="100%">
                {content}
            </Box>
        </div>
    </Grid>
);

export default ImageContentPortrait;
