import React from "react";
import { FaRegStar, FaStar, FaStarHalfAlt } from "react-icons/fa";
import Icon from "../icon/Icon";
import { Box, BoxProps } from "../box/Box";
import useResponsiveProp from "../../hooks/useResponsiveProp";

interface Props extends BoxProps {
    rating: number;
    emptyStarColor?: string;
    color?: string;
    size?: number | number[];
}

const StarRating = ({
                        rating,
                        color = "sunwashGold",
                        emptyStarColor = "black",
                        size,
                        style,
                        ...rest
                    }: Props) => {
    if (rating < 0 || rating > 5) {
        console.error("Star rating must be a number between 0 and 5");
        return null;
    }

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const sizes = useResponsiveProp(size);

    /// round to nearest .5
    const numStars = parseFloat((Math.round(rating * 2) / 2).toFixed(1));

    const hasHalfStar = numStars - Math.floor(numStars) === 0.5;
    const wholeStars = Array(Math.floor(numStars)).fill(0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const emptyStars = Array((5 - wholeStars.length) - (hasHalfStar ? 1 : 0)).fill(0);

    return (
        <Box
            display="inline-block"
            {...rest}
            style={{ cursor: rest.onClick ? "pointer" : "initial", ...style }}
        >
            <Box display="grid" gridTemplateColumns="1" gridTemplateRows="1">
                <Box gridColumn="1" gridRow="1" zIndex={1}>
                    {Array.from(Array(5)).map((val, index) => (
                        <Icon
                            /* eslint-disable-next-line react/no-array-index-key */
                            key={`reg${index}`}
                            color={emptyStarColor}
                            icon={FaRegStar}
                            size={sizes}
                            aria-hidden="true"
                        />
                    ))}
                </Box>
                <Box gridColumn="1" gridRow="1" zIndex={2}>
                    {wholeStars.map((val, index) => (
                        /* eslint-disable-next-line react/no-array-index-key */
                        <Icon key={index} color={color} icon={FaStar} size={sizes} aria-hidden="true" />
                    ))}
                    {hasHalfStar && (
                        <Icon color={color} icon={FaStarHalfAlt} size={sizes} aria-hidden="true" />
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default StarRating;
