export enum PromoDiscountTypeEnum {
    SINGLE = "SINGLE",
    BUNDLE = "BUNDLE",
    SUBSCRIPTION = "SUBSCRIPTION",
    SUBSCRIPTION_MSRP = "SUBSCRIPTION_MSRP",
}

export default interface PromoDiscountConfig {
    promo_discounts_rule_id: string;
    promo_discounts_type: PromoDiscountTypeEnum;
    title: string;
    description: string;
    unit_discount_ratio: number;
    eligible_promo_product_ids: number[];
    eligible_discount_product_ids: number[];
    start_date: string;
    end_date: string;
    subscription_rebill_discount_ratio: number | null;
}
