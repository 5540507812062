import Product from "../@types/interfaces/product/Product";
import ProductResponse from "../@types/interfaces/product/ProductResponse";

export default class ProductFactory {
    static fromApiResponse(product: ProductResponse): Product {
        return {
            product_id: product.id,
            product_name: product.name,
            in_stock: product.is_in_stock,
            in_stock_subscription: product.is_in_stock_subscription,
            ...product
        };
    }
}
