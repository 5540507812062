import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { BoxProps } from "../../box/Box";

const PriceSpiderContainer = styled.div<BoxProps>`
    height: 60px;

    div {
        float: none !important;
        width: 100%;
        border-radius: 44px !important;
        font-weight: 700 !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        line-height: 1.125 !important;
        padding: 0 !important;
        background-color: #ffffff !important;
        color: #000 !important;
        transition: box-shadow 200ms;
        text-decoration: underline;
        font-family: ${themeGet("fonts.primary")} !important;

        :hover {
            background-color: rgba(0, 0, 0, 0.2) !important;
            transition: box-shadow .2s;
        }
    }

    span {
        padding: 22px 48px 18px 48px !important;
        font-weight: 700 !important;
        font-size: 16px;
        border-radius: inherit;
        display: block;
    }
`;

export default PriceSpiderContainer;
