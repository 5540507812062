import React, { ReactNode } from "react";
import { Box, BoxProps } from "../box/Box";
import { Text } from "../text/Text";

export enum MessageLevelEnum {
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
    INFO = "INFO",
    WARNING = "WARNING",
}

export interface MessageBoxProps extends BoxProps {
    message?: string | ReactNode | null;
    level?: MessageLevelEnum | null;
}

const levelsToColors = new Map([
    [MessageLevelEnum.SUCCESS, ["earthGreens.0", "earthGreens.6"]],
    [MessageLevelEnum.INFO, ["trainingBlues.0", "trainingBlues.6"]],
    [MessageLevelEnum.WARNING, ["hsl(45, 100%, 90%)", "hsl(45, 100%, 25%)"]],
    [MessageLevelEnum.ERROR, ["gameReds.0", "gameReds.6"]],
]);

const MessageBox: React.FC<React.PropsWithChildren<MessageBoxProps>> = ({ message, level, children, ...boxProps }) => {
    if (!message) {
        return null;
    }

    const colors = levelsToColors.get(level || MessageLevelEnum.INFO);
    if (!colors) {
        throw new Error(`Colors are not defined for message level '${level}'.`);
    }
    const [backgroundColor, textColor] = colors;

    return (
        <Box
            display={["block", "flex"]}
            justifyContent="space-between"
            alignItems="center"
            backgroundColor={backgroundColor}
            py={3}
            px={5}
            my={3}
            role="alert"
            {...boxProps}
        >
            <Text as="p" color={textColor} flex="1 1 auto">
                {message}
            </Text>
            {children && (
                <Box mt={[3, 0]} ml={[0, 3]}>
                    {children}
                </Box>
            )}
        </Box>
    );
};

export default MessageBox;
