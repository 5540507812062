import React, { PropsWithChildren } from "react";
import Block from "@onnit-js/ui/components/module/Block";
import { Img, Picture } from "@onnit-js/ui/components/image";
import { Text } from "@onnit-js/ui/components/text";
import Button, { ButtonProps } from "@onnit-js/ui/components/button/Button";
import useAddToBag from "@onnit-js/ui/hooks/useAddToBag";
import SuperScriptSpecialChars from "../common/SuperScriptSpecialChars";
import { BlockModel, ContentfulButtonProps } from "../../interfaces/SiteContent";

interface BlockProps {
    data: BlockModel;
    imgParams: string;
    position: number;
}

const ContentfulButton: React.FC<PropsWithChildren<ContentfulButtonProps>> = (props) => {
    const { addToBag, isAddingToBag } = useAddToBag();
    const { align, addToBagPid, addToBagSubscriptionInterval, children, ...rest } = props;
    const newProps: ButtonProps = { ...rest };

    switch (align) {
        case "right":
            newProps.ml = "auto";
            break;
        case "center":
            newProps.mx = "auto";
            break;
        default:
            break;
    }

    if (addToBagPid) {
        return (
            <Button
                {...newProps}
                disabled={isAddingToBag}
                onClick={(evt) => {
                    evt.preventDefault();
                    evt.nativeEvent.stopImmediatePropagation();
                    addToBag({
                        product_id: Number(addToBagPid),
                        quantity: 1,
                        subscription_interval: addToBagSubscriptionInterval ? Number(addToBagSubscriptionInterval) : null,
                    });
                }}
            >
                {isAddingToBag ? "Adding..." : `Add to Bag${addToBagSubscriptionInterval ? " & Subscribe" : ""}`}
            </Button>
        );
    }
    return <Button {...newProps}>{children}</Button>;
};

const ContentfulBlock: React.FC<React.PropsWithChildren<React.PropsWithChildren<BlockProps>>> = ({
    data,
    imgParams,
    position,
}) => (
    <Block
        defaultOnMobile={data.defaultOnMobile}
        href={data.url}
        contentLocation={data.contentLocation ?? "below-image"}
        target={data.openInNewWindow ? "_blank" : undefined}
        image={
                data.mobileImage ? (
                    <Picture
                        lazyLoad={position > 2}
                        width={data.image.width}
                        height={data.image.height}
                        src={[
                            data.mobileImage.src + (data.mobileImage.src.includes(".gif") ? "" : imgParams),
                            data.image.src + (data.image.src.includes(".gif") ? "" : imgParams),
                        ]}
                        alt={data.image.alt}
                    />
                ) : (
                    <Img
                        src={data.image.src + (data.image.src.includes(".gif") ? "" : imgParams)}
                        alt={data.image.alt}
                        width={data.image.width}
                        height={data.image.height}
                        lazyLoad={position > 2}
                    />
                )
            }
        title={
                data.title && (
                    <Text as="p" {...data.titleProps}>
                        <SuperScriptSpecialChars text={data.title} />
                    </Text>
                )
            }
        subTitle={
                data.subTitle && (
                    <Text
                        as="p"
                        {...data.subTitleProps}
                        ml={data.bodyProps?.maxWidth && data.bodyProps.textAlign === "right" ? "auto" : ""}
                    >
                        <SuperScriptSpecialChars text={data.subTitle} />
                    </Text>
                )
            }
        body={
                data.body && (
                    <Text
                        as="p"
                        {...data.bodyProps}
                        ml={data.bodyProps?.maxWidth && data.bodyProps.textAlign === "right" ? "auto" : ""}
                    >
                        <SuperScriptSpecialChars text={data.body} />
                    </Text>
                )
            }
        buttonLocation={data.buttonLocation ?? "below-image"}
        button={data.buttonText && <ContentfulButton {...data.buttonProps}>{data.buttonText}</ContentfulButton>}
    />
    );

export default ContentfulBlock;
