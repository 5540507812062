import React from "react";
import styled from "styled-components";
import { ifProp, theme } from "styled-tools";
import { FieldProps } from "../field/Field";
import { FieldConsumer, FieldProvider } from "../field/FieldContext";
import { splitBoxProps } from "../../../utils/utils";
import { Box, BoxProps } from "../../box/Box";
import ToggleIcon from "./ToggleIcon";

interface StyledBorderProps extends BoxProps {
    checked: boolean;
}

const StyledBorder = styled(Box)<StyledBorderProps>`
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    border-width: 2px;
    border-radius: 4px;
    border-style: solid;
    border-color: ${ifProp(
    "checked",
    theme("component.formfield.active.borderColor"),
    theme("component.formfield.initial.borderColor")
)};
    &:focus-within {
        outline-width: 5px;
        outline-style: solid;
        outline-color: Highlight;
        @media (-webkit-min-device-pixel-ratio: 0) {
            outline-color: -webkit-focus-ring-color;
            outline-style: auto;
        }
    }
`;

export interface ToggleProps extends FieldProps, React.InputHTMLAttributes<HTMLInputElement> {
    labelPosition?: "left" | "right";
    variant?: "normal" | "compact";
}

export default function Toggle(props: ToggleProps) {
    const [boxProps, otherProps] = splitBoxProps(props);
    const {
        children,
        helperText,
        label,
        labelPosition = "right",
        error,
        icon,
        isValid,
        onBlur,
        onFocus,
        variant = "normal",
        type = "checkbox",
        ...inputProps
    } = otherProps;

    return (
        <FieldProvider {...inputProps} isValid={isValid} error={error} onBlur={onBlur} onFocus={onFocus}>
            <FieldConsumer>
                {({ onBlur, onFocus, htmlId }) => (
                    <StyledBorder checked={inputProps.checked} my={2} {...boxProps}>
                        <Box
                            as="label"
                            htmlFor={htmlId}
                            display="flex"
                            alignItems="center"
                            p={1}
                            flexDirection={labelPosition === "left" ? "row-reverse" : "row"}
                            style={{ cursor: "pointer" }}
                        >
                            <Box
                                py={variant === "compact" ? 1 : 4}
                                pl={labelPosition === "left" ? 2 : 4}
                                pr={labelPosition === "left" ? 4 : 2}
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <input
                                    style={{ opacity: 0, width: 0, height: 0 }}
                                    onBlur={onBlur}
                                    onFocus={onFocus}
                                    type={type}
                                    {...inputProps}
                                    id={htmlId}
                                />
                                <ToggleIcon checked={inputProps.checked} type={type} />
                            </Box>

                            <Box flex="1" alignItems="center" p={2}>
                                {children}
                            </Box>
                        </Box>
                    </StyledBorder>
                )}
            </FieldConsumer>
        </FieldProvider>
    );
}
