import Promotion from "@onnit-js/ui/@types/interfaces/google-tag-manager/Promotion";
import { MouseEvent, useCallback, useEffect } from "react";

type ClickHandler = (event: MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;

export default (promotionName: string, position?: number): ClickHandler => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const promotion: Promotion = {
        name: promotionName,
        position,
    };

    useEffect(() => {
        if (!window.ONNIT?.googleTagManager) {
            console.error("Google Tag Manager: Cannot send promotion view because `window.ONNIT.googleTagManager` is undefined.");
            return;
        }

        window.ONNIT.googleTagManager.promotionView([promotion]);
    }, [promotion]);

    // Click event handler
    return useCallback((event: any) => {
        if (!window.ONNIT?.googleTagManager) {
            console.error("Google Tag Manager: Cannot send promotion click because `window.ONNIT.googleTagManager` is undefined.");
            return;
        }

        // Handle the case if this is being used improperly as a click handler for `HTMLDivElement`.
        const currentTarget = event.currentTarget as HTMLAnchorElement;
        const clickUrl = currentTarget.href;
        if (!clickUrl) {
            console.error("Google Tag Manager: Cannot send promotion click because the click URL cannot be determined.");
            return;
        }

        // Prevent navigation and delegate it to GTM if it's loaded, as it could be blocked by a browser extension.
        // In either case, we still send the click payload in the edge case that the SDK is still loading.
        if (window.ONNIT.googleTagManager.isSdkLoaded()) {
            event.preventDefault();
        }

        window.ONNIT.googleTagManager.promotionClick([promotion], clickUrl);
    }, [promotion]);
};
