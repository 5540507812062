import React, { ReactNode } from "react";
import styled from "styled-components";
import { ifProp } from "styled-tools";
import { BoxProps } from "../../../box/Box";
import Grid from "./Grid";

export interface TwoEightProps extends BoxProps {
    two?: ReactNode;
    eight?: ReactNode;
    reverse?: boolean;
    children?: any;
}

const Two = styled.div<{ reverse: boolean }>`
    grid-column: 1 / -1;
    @media (min-width: ${(prp) => prp.theme.breakpoints[2]}) {
        grid-column-start: ${ifProp("reverse", 10, 2)};
        grid-column-end: span 2;
    }
`;

const Eight = styled.div<{ reverse: boolean }>`
    grid-column: 1 / -1;
    @media (min-width: ${(prp) => prp.theme.breakpoints[2]}) {
        grid-column-start: ${ifProp("reverse", 2, 4)};
        grid-column-end: span 8;
    }
`;

export default function TwoEight({ two, eight, reverse = false, children, ...rest }: TwoEightProps) {
    return (
        <Grid {...rest}>
            {two && <Two reverse={reverse}>{two}</Two>}
            {eight && <Eight reverse={reverse}>{eight}</Eight>}
            {children}
        </Grid>
    );
}
