import React from "react";
import { Box, BoxProps } from "../box/Box";
import { Text } from "../text";

interface Props extends BoxProps {
    boldYes?: boolean;
}

const EmailOptInText:React.FC<Props> = ({ boldYes = false, ...rest }) => (
    <Box {...rest}>
        <Text
            color="grays.5"
            typeStyle="caption02"
        >
            <Text color="grays.5" fontWeight={boldYes ? "bold" : "regular"} display="inline">Yes</Text>,
            I want to receive offers and updates
            from Onnit and other related brands via email and online
            marketing. I have read and acknowledge Onnit's{" "}
            <a href="/privacy-policy/" target="_blank">Privacy Policy</a> and{" "}
            <a href="/financial-incentives-notice/" target="_blank">Notice of Financial Incentive</a>.
            I understand I may unsubscribe from promotional emails at any time.*
        </Text>
        <Text
            mt={2}
            color="grays.5"
            typeStyle="caption02"
            fontStyle="italic"
            style={{
                fontSize: "12px"
            }}
        >
            *If you unsubscribe, you may still receive non-marketing or transactional messages from us.
            Related brands include Liquid I.V., Olly, Welly, Smarty Pants, and Nutrafol.
        </Text>
    </Box>
);

export default EmailOptInText;