import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import nextId from "react-id-generator";
import Icon from "../icon/Icon";
import { Box } from "../box/Box";
import { Text } from "../text/Text";
import ModalPortal, { ModalPortalProps } from "./ModalPortal";

interface Props extends ModalPortalProps {
    title?: React.ReactNode;
}

export default function Modal({ title, onRequestClose, children, ...rest }: Props) {
    const [uuid] = useState(() => nextId("oui-modal-"));

    return (
        <ModalPortal {...rest} uuid={uuid} onRequestClose={onRequestClose}>
            <Box display="flex" alignItems="center" mb={4}>
                <Box flex="1" mr={4}>
                    {title && (
                        <Text
                            as="h2"
                            fontWeight="bold"
                            fontSize={5}
                            id={rest["aria-labelledby"] ? undefined : uuid}
                        >
                            {title}
                        </Text>
                    )}
                </Box>
                {onRequestClose && (
                    <Icon
                        ariaLabel={`Close ${title ? `${title} ` : null}Dialog`} /* eslint-disable-line @typescript-eslint/no-base-to-string */
                        size={24}
                        color="black"
                        icon={MdClose}
                        tabIndex={0}
                        onClick={onRequestClose ? (onRequestClose as (event: any) => void) : undefined}
                        style={{
                            cursor: "pointer",
                        }}
                    />
                )}
            </Box>
            {children}
        </ModalPortal>
    );
}
