import React from "react";
import { Text } from "../../text/Text";
import { Box, BoxProps } from "../../box/Box";

interface Props extends BoxProps {
    price: string | undefined | null;
    totalServings: number;
}

export default function CostPerCount({ price, totalServings, ...rest }: Props) {
    if (!price) {
        return null;
    }

    return (
        <Box className="pricePerServing" {...rest}>
            <Text display="block" color="grays.4" fontSize={0}>
                ${(parseFloat(price) / totalServings).toFixed(2)}
                <span className="sr-only">Per</span>
                <span aria-hidden="true"> / </span>Serving
            </Text>
        </Box>
    );
}
