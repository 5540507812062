import React, { useEffect, useState } from "react";
import Media from "@onnit-js/ui/components/media";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import { Img } from "@onnit-js/ui/components/image";
import SelectField from "@onnit-js/ui/components/form/selectfield/SelectField";
import Modal from "@onnit-js/ui/components/modal/Modal";
import { ProductNameTransform } from "@onnit-js/ui/components/buybox/BuyBox";
import usePageData from "../../hooks/usePageData";

interface Props {
    currentPID?: number;
    onChange?: Function;
    enableZoom?: boolean;
    productNameTransform?: ProductNameTransform;
}

const SupplementFacts: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (
    {
        onChange,
        currentPID,
        enableZoom = false,
        productNameTransform
    }
) => {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    const IMGIX = onnitContext ? onnitContext.url.cdn.static_images : "";

    const [pid, setPID] = useState(currentPID);
    const [isModalOpen, setModalOpen] = useState(false);
    const handleImageClick = () => {
        if (!enableZoom) {
            return false;
        }
        setModalOpen(true);
        return false;
    };

    useEffect(() => {
        setPID(currentPID);
    }, [currentPID]);

    useEffect(() => {
        if (onChange) {
            onChange(pid);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pid]);

    return (
        <Media query="(min-width: 640px)">
            {(matches) => {
                if (!onnitContext || !pageData || !pageData.supplement_facts) {
                    return null;
                }
                const isSingle = Object.keys(pageData.supplement_facts).length <= 1;
                const zoomProps = matches && enableZoom
                    ? {
                        onClick: handleImageClick,
                        style: { cursor: "pointer" },
                    }
                    : {};

                return (
                    <>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {typeof pageData.supplement_facts === "string" ? (
                            <>
                                <Img
                                    alt="Product nutrition facts"
                                    src={`${IMGIX}${pageData.supplement_facts}`}
                                    width={350}
                                    height={430}
                                    {...zoomProps}
                                />
                                <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} maxWidth="80%">
                                    <Img
                                        alt="Product nutrition facts"
                                        width={350}
                                        height={430}
                                        src={`${IMGIX}${pageData.supplement_facts}`}
                                    />
                                </Modal>
                            </>
                        ) : currentPID && pid ? (
                            <>
                                {!isSingle && (
                                    <SelectField
                                        label="Product"
                                        value={pid}
                                        onChange={(event) => setPID(parseInt(event.target.value))}
                                    >
                                        {pageData.products.map((prp) => (
                                            <option key={prp.id} value={prp.id}>
                                                {productNameTransform ? productNameTransform(prp.name, prp.id) : prp.name}
                                            </option>
                                        ))}
                                    </SelectField>
                                )}
                                <Img
                                    alt="Product nutrition facts"
                                    src={`${IMGIX}${pageData.supplement_facts[pid]}`}
                                    width={350}
                                    height={430}
                                    {...zoomProps}
                                />
                                <Modal isOpen={isModalOpen} onRequestClose={() => setModalOpen(false)} maxWidth="80%">
                                    {pid && (
                                        <Img
                                            alt="Product nutrition facts"
                                            src={`${IMGIX}${pageData.supplement_facts[pid]}`}
                                            width={350}
                                            height={430}
                                        />
                                    )}
                                </Modal>
                            </>
                        ) : null}
                    </>
                );
            }}
        </Media>
    );
};

export default SupplementFacts;
