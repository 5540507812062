export enum ProductBuyBoxSelectVariantTypeEnum {
    CONDENSED = "CONDENSED",
    DEFAULT = "DEFAULT",
}

export default interface ProductBuyBoxSelectVariantConfig {
    variant: ProductBuyBoxSelectVariantTypeEnum;
    subscriptionProductIds: number[] | null;
    buyOnceProductIds: { [productId: number]: number } | null;
}
