import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { Img } from "@onnit-js/ui/components/image";
import { Text } from "@onnit-js/ui/components/text";

export default function ProductBoxDisplay(
    {
        title,
        content,
        imgUrl,
        altText,
    }: {
    title: any;
    content: any;
    imgUrl: string;
    altText: string;
}) {
    return (
        <Box display="block" m={[2, 4]}>
            <Img placeholder={[460, 460]} src={`${imgUrl}`} alt={altText} />
            <Box p={[6, 4, 6]}>
                <Text
                    as="p"
                    textTransform="uppercase"
                    fontWeight="bold"
                    mb={[1, 2]}
                    dangerouslySetInnerHTML={{ __html: title }}
                />
                <Text as="p" lineHeight={4} dangerouslySetInnerHTML={{ __html: content }} />
            </Box>
        </Box>
    );
}
