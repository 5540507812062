import React, { ReactElement, Ref } from "react";
import styled, { css } from "styled-components";
import * as SS from "@techstack/styled-system";
import { themeGet } from "@styled-system/theme-get";
import { switchProp, theme } from "styled-tools";
import { FaChevronRight } from "react-icons/fa";
import { removeProps } from "../../utils/utils";
import useResponsiveProp from "../../hooks/useResponsiveProp";
import Icon, { IconProps } from "../icon/Icon";

type ButtonSize = "icon" | "small" | "medium" | "large";

interface Props extends SS.SpaceProps, SS.LayoutProps, SS.FlexboxProps {
    to?: string;
    el?: any;
    className?: string;
    href?: string;
    type?: "button" | "submit";
    onClick?: (evt: any) => void;
    style?: any;
    color?: string;
    textColor?: string;
    size?: ButtonSize | ButtonSize[];
    fill?: "solid" | "outline" | "text";
    disabled?: boolean;
    raised?: boolean;
    theme?: any;
    ref?: any;
    tabIndex?: number | string;
    icon?: ReactElement<IconProps>;
}

export interface ButtonProps extends SS.SpaceProps, SS.LayoutProps, SS.FlexboxProps {
    to?: string;
    el?: any;
    children?: any;
    className?: string;
    href?: string;
    target?: string;
    type?: "button" | "submit";
    onClick?: (evt: any) => void;
    style?: any;
    color?: string;
    textColor?: string;
    size?: ButtonSize | ButtonSize[];
    fill?: "solid" | "outline" | "text";
    disabled?: boolean;
    raised?: boolean;
    ref?: any;
    tabIndex?: number | string;
    icon?: ReactElement<IconProps>;
}

/**
 * Workaround because styled-components won't remove styled-system props from DOM element.
 */
const PROPS_FILTER_REG = /^([mpfw][trblxy]?|as|bg|display|color|size|fill|raised|width|textColor)$/u;

const Element: React.FC<React.PropsWithChildren<Props>> = React.forwardRef(({ el = "button", ...rest }, ref) => {
    const filteredProps = removeProps(PROPS_FILTER_REG, rest);
    filteredProps.ref = ref;
    return React.createElement(el, filteredProps);
});
const getButtonColor = (prp: Props): string => {
    if (prp.color) {
        return prp.color.indexOf("#") === -1 ? themeGet(`colors.${prp.color}`)(prp) : prp.color;
    }
    return themeGet("component.button.bg")(prp);
};
const getTextColor = (prp: Props): string => {
    if (prp.textColor) {
        return prp.textColor.indexOf("#") === -1 ? themeGet(`colors.${prp.textColor}`)(prp) : prp.textColor;
    }
    return themeGet("component.button.color")(prp);
};

const Base: React.FunctionComponent<React.PropsWithChildren<Props>> = styled(Element)<Props>`
    ${SS.space};
    ${SS.layout};
    ${SS.flexbox};
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    line-height: 1.125;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    text-align: center;
    font-weight: ${theme("fontWeights.bold")};
    font-family: ${themeGet("fonts.primary")};
    font-size: ${themeGet("fontSizes.2")}px;
    transition: box-shadow 200ms;
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    -webkit-appearance: none !important;

    ${(prp) => prp.raised && prp.fill !== "text" && "box-shadow: 2px 2px 0 0 rgba(0,0,0,.1875)"};

    border-color: ${getButtonColor};

    ${switchProp("fill", {
    solid: css`
            background-color: ${getButtonColor};
            color: ${getTextColor};
            &:hover,
            &:active {
                color: ${getTextColor};
                text-decoration: none !important;
            }
            &:hover:not([disabled]):not(:active) {
                box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.5);
            }
        `,
    outline: css`
            background: none;
            color: ${getButtonColor};
            &:hover,
            &:active {
                color: ${getButtonColor};
                text-decoration: none !important;
            }
            &:hover:not([disabled]):not(:active) {
                box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.5);
            }
        `,
    text: css`
            border-color: transparent;
            background: none;
            color: ${getButtonColor};
            text-decoration: underline;
            &:hover {
                color: ${getButtonColor};
                background-color: rgba(0, 0, 0, 0.2);
            }
        `,
})}

    ${switchProp("size", {
    icon: css`
            display: flex;
            width: 54px;
            height: 54px;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        `,
    small: css`
            padding: 9px ${themeGet("space.4")}px 7px ${themeGet("space.4")}px;
            font-size: ${themeGet("fontSizes.1")}px;
            border-radius: 22px;
        `,
    medium: css`
            padding: 13px ${themeGet("space.6")}px 10px ${themeGet("space.6")}px;
            border-radius: 44px;
        `,
    large: css`
            padding: 22px ${themeGet("space.7")}px 18px ${themeGet("space.7")}px;
            border-radius: 64px;
        `,
})}

    &:active {
        outline: none;
        top: 2px;
        left: 2px;
        box-shadow: none;
    }

    &[disabled] {
        background-color: ${themeGet("colors.grays.3")};
        color: ${themeGet("colors.grays.2")};
        border-color: ${themeGet("colors.grays.3")};
        pointer-events: none;
    }

    svg {
        transform: translateY(-1px);
    }
`;

const Button = (
    {
        disabled = false,
        display = "inline-block",
        fill = "solid",
        raised = true,
        size = "large",
        type = "button",
        el = "button",
        icon,
        textColor,
        children,
        ...rest
    }: ButtonProps,
    ref: Ref<any>
) => {
    const sizeProp = useResponsiveProp(size);
    const iconProp = icon ?? (
        <Icon
            color={textColor ?? "white"}
            icon={FaChevronRight}
            iconStyle={{ transform: "translate3d(1px,1px,0)" }}
        />
    );

    return (
        <Base
            disabled={disabled}
            display={display}
            fill={fill}
            raised={raised}
            size={sizeProp}
            type={el === "a" ? undefined : type}
            el={el}
            textColor={textColor}
            {...rest}
            ref={ref}
        >
            {sizeProp === "icon" ? iconProp : children}
        </Base>
    );
};

export default React.forwardRef(Button);
