import React from "react";
import { Img, Picture } from "@onnit-js/ui/components/image";
import Feature from "@onnit-js/ui/components/module/Feature";
import Button from "@onnit-js/ui/components/button/Button";
import { Text } from "@onnit-js/ui/components/text";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import usePromotionEvent from "../../../hooks/usePromotionEvent";
import { BANNER_RICH_TEXT_OPTIONS } from "../RichTextMapping";
import SuperScriptSpecialChars from "../../common/SuperScriptSpecialChars";

interface HeroProps {
    data: any;
    position: number;
    imgParams: string;
}

const ContentfulFeature: React.FC<React.PropsWithChildren<React.PropsWithChildren<HeroProps>>> = (
    {
        data,
        imgParams,
        position
    }) => {
    const onClick = usePromotionEvent(`Feature - ${data.url?.replace("/", "")}`, position);

    return (
        <Feature
            onClick={onClick}
            py={[6, 6, 6, 8]}
            variant={data.variant}
            href={data.url}
            heading={(
                <Text as="h2" typeStyle="title02" fontSize={[4, 4, 5, 5, 6, 6, 6]}>
                    <SuperScriptSpecialChars text={data.title} />
                </Text>
            )}
            image={
                data.mobileImage ? (
                    <Picture
                        lazyLoad
                        width={data.image.width}
                        height={data.image.height}
                        src={[
                            /* eslint-disable @typescript-eslint/restrict-plus-operands */
                            data.mobileImage.src + (data.mobileImage.src.includes(".gif") ? "" : imgParams),
                            data.image.src + (data.image.src.includes(".gif") ? "" : imgParams),
                        ]}
                        alt={data.image.alt}
                    />
                ) : (
                    <Img
                        src={data.image.src + (data.image.src.includes(".gif") ? "" : imgParams)}
                        /* eslint-enable @typescript-eslint/restrict-plus-operands */
                        alt={data.image.alt}
                        placeholder={[data.image.width, data.image.height]}
                    />
                )
            }
            content={data.body ? <>{documentToReactComponents(data.body, BANNER_RICH_TEXT_OPTIONS)}</> : <></>}
            cta={
                data.cta && data.url ? (
                    <Button el="a" href={data.url} width={1} aria-label={data.urlDescription} onClick={onClick}>
                        {data.cta}
                    </Button>
                ) : (
                    <></>
                )
            }
        />
    );
};

export default ContentfulFeature;
