import { useTheme } from "styled-components";
import { useMedia } from "@onnit-js/ui/components/media";
import { ThemeInterface } from "../components/themes/interfaces";

export default function useResponsiveProp(prop: string | number | any[] | undefined) {
    if (!Array.isArray(prop)) {
        return prop;
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    const { breakpoints } = (theme as ThemeInterface);
    const mediaQueries = prop.reduce((acc, next, index) => {
        acc[index] = `(min-width: ${breakpoints[index]})`;
        return acc;
    }, {});
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const matches = useMedia({ queries: mediaQueries });
    let value = prop[0];
    // eslint-disable-next-line no-restricted-syntax
    for (const entry of Object.entries(matches).reverse()) {
        if (entry[1]) {
            value = prop[Number(entry[0])];
            break;
        }
    }
    return value;
}
