import { useCallback, useEffect, useState } from "react";
import PersonalizeClient from "../../clients/AmazonPersonalizeClient";
import ProductClient from "../../clients/ProductClient";
import { ProductListResponse } from "../../@types/interfaces/amazonPersonalize/ProductListResponse";
import RecommendationListCommand from "../../@types/interfaces/amazonPersonalize/RecommendationListCommand";
import UserContext from "../../@types/interfaces/amazonPersonalize/UserContext";
import GoogleTagManagerUtil from "../../utils/google-tag-manager/GoogleTagManagerUtil";
import ListNameEnum from "../../@types/enums/ListNameEnum";

interface Props {
    pageType: string,
    perPage?: number,
}

interface UseRecommendationsListHook {
    isLoading: boolean,
    paginatedData: ProductListResponse | null,
    getRecommendations: (page: number) => Promise<void>;
    message: string | null,
}

const personalizeClient = new PersonalizeClient();
const productClient = new ProductClient();

const useRecommendationsList = (props?: Props): UseRecommendationsListHook => {
    const perPage = props?.perPage ?? 10;
    const [isLoading, setLoading] = useState(false);
    const [recommendationListCommand, setRecommendationListCommand] = useState<RecommendationListCommand | null>();
    const [recommendedProductIds, setRecommendedProductIds] = useState<number[] | null>();
    const [paginatedData, setPaginatedData] = useState<ProductListResponse | null>(null);
    const [message, setMessage] = useState<string | null>(null);

    const getRecommendedProductIds = useCallback(async (): Promise<void> => {
        if (!recommendationListCommand) {
            return;
        }
        setLoading(true);
        setMessage(null);
        try {
            const { itemList } = await personalizeClient.listRecommendations(recommendationListCommand);
            setRecommendedProductIds(itemList.map((item) => item.itemId));
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [recommendationListCommand]);

    const getRecommendations = useCallback(async (page = 1): Promise<void> => {
        if (!recommendedProductIds) {
            return;
        }
        setLoading(true);
        setMessage(null);
        try {
            const response = await productClient.listProductsInStockByIds(recommendedProductIds, page, perPage);
            setPaginatedData(response);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [recommendedProductIds, perPage]);

    useEffect(() => {
        if (!window?.onnit_context?.personalize.enable) {
            console.error("Personalize: Unable to get recommendations, `window.onnit_context.personalize.enable` is not true");
            return;
        }
        const userId = window?.onnit_context?.personalize.user_id || window?.onnit_context?.personalize.session_id;
        if (!userId) {
            console.error("Personalize: Unable to get recommendations, `window.onnit_context.personalize.user_id` and `window.onnit_context.personalize.session_id` are unavailable.");
            return;
        }

        const userContext: UserContext = {
            PAGE_TYPE: props?.pageType || "ACCOUNT_PAGE",
        };

        const country = window?.onnit_context?.personalize.country;
        if (country) {
            userContext.COUNTRY = country;
        }

        const device = window?.onnit_context?.personalize.device;
        if (device) {
            userContext.DEVICE = device;
        }

        setRecommendationListCommand({ userId, userContext });
    }, [props?.pageType]);

    useEffect(() => {
        if (!recommendationListCommand) {
            return;
        }
        getRecommendedProductIds().catch(console.log);
    }, [recommendationListCommand, getRecommendedProductIds]);

    useEffect(() => {
        if (!recommendedProductIds) {
            return;
        }
        getRecommendations().catch(console.log);
    }, [recommendedProductIds, getRecommendations]);

    useEffect(() => {
        if (paginatedData?.data?.length) {
            GoogleTagManagerUtil.productImpression(paginatedData.data, ListNameEnum.RECOMMENDATIONS);
        }
    }, [paginatedData]);

    return {
        isLoading,
        paginatedData,
        getRecommendations,
        message,
    };
};

export default useRecommendationsList;
