import styled from "styled-components";
import { prop, theme } from "styled-tools";
import { Box, BoxProps } from "../../../box/Box";

export const TwelveColumn = styled(Box)<BoxProps>`
    display: ${prop("display", "grid")};
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    grid-auto-columns: 1fr;
    grid-template-rows: ${prop("gridTemplateRows", "auto")};
    @media (min-width: ${(prp) => prp.theme.breakpoints[1]}) {
        grid-template-columns: repeat(12, 1fr);
        grid-auto-flow: column;
    }
`;

export default TwelveColumn;
