import React from "react";
import { Box } from "../box/Box";
import ProductImage from "../image/ProductImage";
import UrlUtil from "../../utils/UrlUtil";
import RecommendationItemPrice from "./RecommendationItemPrice";
import RecommendationItemProductName from "./RecommendationItemProductName";
import { RecommendationItemProps } from "./RecommendationItem";
import { ProductCTA } from "./shared/ProductCta";

const ProductDetailRecommendationItem: React.FC<React.PropsWithChildren<RecommendationItemProps>> = ({
                                                                                                         item,
                                                                                                         onClick,
                                                                                                         ...rest
                                                                                                     }) => {
    const { product_name, authority_image_url } = item;
    const url = UrlUtil.getProductUrl(item);

    return (
        <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="100%"
            justifyContent="flex-end"
            p={2}
            {...rest}
        >
            <ProductImage
                width="60px"
                productUrl={url}
                imageUrl={authority_image_url}
                productName={product_name}
                onClick={onClick}
            />

            <RecommendationItemProductName item={item} fontSize={0} textDecoration="none" />
            <RecommendationItemPrice item={item} fontSize={0} />
            <Box width={["190px", "190px", "120px"]} my={2}>
                <ProductCTA product={item} size="small" />
            </Box>

        </Box>
    );
};

export default ProductDetailRecommendationItem;