import React from "react";
import { BoxProps } from "@onnit-js/ui/components/box/Box";
import PageHeader from "@onnit-js/ui/components/module/PageHeader";
import { PageHeader as PageHeaderInterface } from "../../interfaces/SiteContent";

interface Props extends BoxProps {
    data: PageHeaderInterface;
}

const ContentfulPageHeader = ({ data: { backgroundImage, foregroundImage, hiddenHeading } }: Props) => (
    <>
        <h1 className="sr-only">{hiddenHeading}</h1>
        <PageHeader bgImageSrc={backgroundImage.src} contentImageProps={foregroundImage} />
    </>
);

export default ContentfulPageHeader;
