import React, { ReactElement } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";
import { Box, BoxProps } from "../box/Box";
import { BgImage } from "../image/BgImage";

const Container = styled(Box)`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
`;

interface CardsProps extends BoxProps {
    before?: ReactElement;
}

export const Cards = ({
                          display = ["block", "block", "block", "grid"],
                          children,
                          before,
                          gridTemplateColumns = "repeat(12, 1fr)",
                          ...rest
                      }: CardsProps) => (
                          <Container {...rest}>
                              {before && <Box>{before}</Box>}
                              <Box display={display} gridTemplateColumns={gridTemplateColumns} gridGap={4}>
                                  {children}
                              </Box>
                          </Container>
);

export type ColumnSpans = 1 | 2 | 3 | 4 | 6 | 12 | "auto";

interface CardProps extends BoxProps {
    lazy?: boolean;
    bgSrc: string;
    bgOverlay?: boolean;
    href?: string;
    target?: string;
    span?: ColumnSpans;
    overlayAlpha?: number;
}

export const Card = ({
                         lazy = true,
                         bgSrc,
                         href,
                         target,
                         children,
                         span = 4,
                         bgOverlay = true,
                         justifyContent,
                         overlayAlpha = 0.4,
                         ...rest
                     }: CardProps) => {
    const style = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: justifyContent ?? "space-between",
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        width: "100%",
        height: "100%",
        padding: 24,
        textDecoration: "none",
        boxSizing: "border-box",
        zIndex: 1,
    };

    // eslint-disable-next-line react/no-children-prop
    const component = React.createElement(href ? "a" : "div", {
        href,
        target,
        style,
        children,
    });

    return (
        <BgImage
            lazy={lazy}
            mb={[4, 4, 4, 0]}
            pt="52.5%"
            position="relative"
            bg="black"
            src={bgSrc}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            style={
                span === "auto" ? undefined : {
                    gridColumnEnd: `span ${span}`,
                }
            }
            {...rest}
        >
            {bgOverlay && (
                <Box
                    top={0}
                    left={0}
                    bg={`rgba(0,0,0,${overlayAlpha})`}
                    position="absolute"
                    width="100%"
                    height="100%"
                    zIndex={0}
                >
                    &nbsp;
                </Box>
            )}
            {component}
        </BgImage>
    );
};
