import React, { ReactNode } from "react";
import Media from "@onnit-js/ui/components/media";
import ThemeProvider from "@onnit-js/ui/components/themes/ThemeProvider";
import GlobalStyle from "@onnit-js/ui/components/themes/GlobalStyle";
import theme from "@onnit-js/ui/components/themes/light";
import Box from "@onnit-js/ui/components/box/Box";
import Button from "@onnit-js/ui/components/button/Button";
import { Text, List, ListItem, Sup } from "@onnit-js/ui/components/text";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import { Img } from "@onnit-js/ui/components/image";
import Grid from "@onnit-js/ui/components/module/grid/12/Grid";
import MediaHero01 from "@onnit-js/ui/components/module/MediaHero01";
import MediaPano from "@onnit-js/ui/components/module/MediaPano";
import CopyDisplay02 from "@onnit-js/ui/components/module/CopyDisplay02";
import ImageContentPortrait from "@onnit-js/ui/components/module/ImageContentPortrait";
import Embed from "@onnit-js/ui/components/video/Embed";
import Dash from "@onnit-js/ui/components/icon/Dash";
import SupplementFacts from "../../components/common/SupplementFacts";
import useScrollToRef from "../../hooks/useScrollToRef";
import usePageData from "../../hooks/usePageData";
import UsageAndDisclaimers from "../../components/common/UsageAndDisclaimers";
import OfferHero from "../../components/common/offer/OfferHero";
import AsSeenIn from "../../components/common/offer/AsSeenIn";
import FiveStarTestimonials from "../../components/common/FiveStarTestimonials";
import testimonials from "./testimonials";
import Faqs from "../../components/common/Faqs";
import faqs from "./faq";
import OnnitLogoBackground from "../../components/common/OnnitLogoBackground";

import StickyHeader from "../../components/common/StickyHeader";
import config from "../../config/config";

interface Props {
    banner?: ReactNode;
    offers: (imgURL: string, mainColor: string) => ReactNode;
}

/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
export default function Page({ banner, offers }: Props) {
    const onnitContext = useOnnitContext();
    const { pageData } = usePageData();
    const [buyBoxRef, scrollToBuyBox] = useScrollToRef();

    if (!pageData || !onnitContext) {
        return null;
    }

    const { IMG_PARAMS } = config;
    const OFFER_IMG_URL = `${config.IMG_URL}/product-page/total-gut-health/3.0/offer`;
    const tghGreen = "#20A090";
    const darkTeal = "#54C2B7";

    return (
        <ThemeProvider theme={theme}>
            <>
                <GlobalStyle />
                {banner}
                <StickyHeader showHelp={false}>
                    <Button size="small" raised={false} onClick={() => scrollToBuyBox()}>
                        Try Now
                    </Button>
                </StickyHeader>
                <div className="hide-ie" style={{ marginTop: -56 }}>
                    <OfferHero
                        reverse
                        imgSrc={[
                            `${OFFER_IMG_URL}/2024-TGH-JMB-Wpage-Offer-1-HEADER-MOBILE-750x1300.jpg${IMG_PARAMS}`,
                            `${OFFER_IMG_URL}/2024-TGH-JMB-Wpage-Offer-1-HEADER-DESKTOP-1920x1067.jpg${IMG_PARAMS}`,
                        ]}
                    >
                        <Text typeStyle="copyDisplay02" fontSize={[5, 5, 6, 6, 6, 7, 7]} color="sunwashGold" mb={4}>
                            Total GUT HEALTH
                            <Sup variant="reg" /> Is Everything you want to promote a healthy gut!
                        </Text>
                        <Text color="white" mb={6} fontSize={[2, 2, 4]}>
                            Digestive enzymes, prebiotics, probiotics, and betaine hydrochloride (HCI).
                            <Sup variant="dagger" />
                        </Text>
                        <Button
                            size="medium"
                            width={[1, 1, "auto"]}
                            onClick={() => scrollToBuyBox()}
                            textColor="black"
                            color="white"
                            raised
                        >
                            TRY IT NOW - RISK FREE
                        </Button>
                    </OfferHero>

                    <AsSeenIn />

                    <Box bg="#232523" py={[6, 6, 8]}>
                        <Box py={[2, 2, 6, 8]} px={[5, 5, 5, 10]}>
                            <Box gridColumn="1 / -1">
                                <Text as="h2" typeStyle="headlineHero02" color={tghGreen} mb={4}>
                                    Support Digestion and Overall Health<Sup variant="dagger" />
                                </Text>
                                <Text as="h2" typeStyle="copy01" color="white" maxWidth="700px" ref={buyBoxRef}>
                                    Choose your package and save big on your order of total gut health
                                    <Sup variant="reg" />.
                                </Text>
                            </Box>
                        </Box>
                        {offers(OFFER_IMG_URL, darkTeal)}
                    </Box>

                    <MediaHero01
                        bg="grays.8"
                        pt={[8, 8, 8, 10]}
                        pb={[8, 8, 8, 10, "16%"]}
                        media={(
                            <Img
                                src={`${OFFER_IMG_URL}/2024-TGH-JMB-Wpage-Offer-3-Mid-1-920x920.jpg${IMG_PARAMS}`}
                                alt="Total GUT Health bottle next to bowl of guacamole."
                                width={1200}
                                height={1200}
                            />
                        )}
                        headline={(
                            <>
                                <Text typeStyle="headlineHero01" color={tghGreen}>
                                    Total
                                </Text>
                                <Text typeStyle="headlineHero01" color={tghGreen}>
                                    Gut
                                </Text>
                                <Text typeStyle="headlineHero01" color={tghGreen}>
                                    Health
                                    <Sup variant="reg" />
                                </Text>
                            </>
                        )}
                    />

                    <Media
                        query="(min-width: 960px)"
                        render={() => (
                            <MediaPano p={0} mt={[0, 0, "-8%"]} mb={[0, 0, 0, 8]}>
                                <Img
                                    src={`${OFFER_IMG_URL}/2024-TGH-JMB-Wpage-Offer-3-Mid-2-1600x450.jpg${IMG_PARAMS}`}
                                    alt="Hands holding a bottle of Total Gut Health over a steak dinner."
                                    width={1666}
                                    height={462}
                                />
                            </MediaPano>
                        )}
                    />

                    <ImageContentPortrait
                        py={[6, 6, 6, 8]}
                        image={(
                            <Img
                                src={`${OFFER_IMG_URL}/2024-TGH-JMB-Wpage-Offer-4-How-It-Helps-775x1000.jpg${IMG_PARAMS}`}
                                alt="Powder dropped into a cup."
                                width={934}
                                height={1212}
                            />
                        )}
                        content={(
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Text color="grays.6" typeStyle="copyDisplay02" mb={6}>
                                    How Total GUT
                                    <br /> HEALTH
                                    <Sup variant="reg" /> Helps You
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.6">
                                    Research suggests that the gut microbiome—the balance of bacteria in your
                                    intestines—helps form the foundation for healthy immune system function. It also
                                    assists with the digestion of nutrients you need to be healthy and help transform
                                    your body.<Sup variant="dagger" />
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.6" mt={[4, 6, 8]}>
                                    Total GUT HEALTH<Sup variant="reg" /> offers digestive enzymes, probiotics,
                                    prebiotics, and other
                                    nutrients that help support a healthy digestive system and gut microbiome, as well
                                    as help you process the occasional heavy meal.<Sup variant="dagger" />
                                </Text>
                            </Box>
                        )}
                    />

                    <ImageContentPortrait
                        py={[6, 6, 6, 8]}
                        image={(
                            <Img
                                src={`${OFFER_IMG_URL}/2024-TGH-JMB-Wpage-Offer-4-How-It-Works-775x1000.jpg${IMG_PARAMS}`}
                                alt="Pill capsules on top of powder."
                                width={934}
                                height={1212}
                            />
                          )}
                        content={(
                            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                                <Text color="grays.6" typeStyle="copyDisplay02" mb={6}>
                                    How Total GUT
                                    <br /> HEALTH
                                    <Sup variant="reg" /> Works
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.6">
                                    You can’t eat perfectly all the time. Whether you’re enjoying the occasional
                                    decadent meal or eating abroad where you’re not used to the food, you shouldn’t be
                                    punished with occasional stomach discomfort.<Sup variant="dagger" />
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.6" mt={[4, 6, 8]}>
                                    Total GUT HEALTH<Sup variant="reg" /> helps to ease occasional bloating and keep
                                    your digestion on
                                    track. Take it at your next big meal and the effects may help save the rest of your
                                    day.<Sup variant="dagger" />
                                </Text>
                            </Box>
                        )}
                    />

                    <Grid>
                        <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "3 / span 8"]} px={4}>
                            <Embed
                                title="Total GUT HEALTH Product Video"
                                src="https://player.vimeo.com/video/995050816"
                            />

                            <Box py={[6, 6, 6, 8]}>
                                <Dash mb={5} />
                                <List style={{ paddingLeft: 32 }}>
                                    <ListItem typeStyle="copyHero01" color="black">
                                        Eases occasional bloating<Sup variant="dagger" />
                                    </ListItem>
                                    <ListItem typeStyle="copyHero01" color="black">
                                        Supports digestive health<Sup variant="dagger" />
                                    </ListItem>
                                    <ListItem typeStyle="copyHero01" color="black">
                                        Helps maintain healthy immune system function<Sup variant="dagger" />
                                    </ListItem>
                                    <ListItem typeStyle="copyHero01" color="black">
                                        Promotes digestion<Sup variant="dagger" />
                                    </ListItem>
                                    <ListItem typeStyle="copyHero01" color="black">
                                        Helps break down food to support nutrient absorption<Sup variant="dagger" />
                                    </ListItem>
                                    <ListItem typeStyle="copyHero01" color="black">
                                        Helps promote healthy gut flora<Sup variant="dagger" />
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </Grid>

                    <Box mx="auto" maxWidth={["830px"]}>
                        <Img src={`${OFFER_IMG_URL}/2024-TGH-JMB-Wpage-Offer-5-Whats-In-920x1100.png${IMG_PARAMS}`} alt="Ttoal Gut Health with new packaging." />
                    </Box>

                    <CopyDisplay02 py={[6, 6, 6, 8]} dashColor={tghGreen}>
                        <Text color="grays.4" typeStyle="copyDisplay02" mb={[6, 6, 6, 8]}>
                            What's in Total GUT HEALTH<Sup variant="reg" />?
                        </Text>
                        <Box display={["block", "block", "flex"]}>
                            <Box flex="1 0 44%" mr={[0, 5, 6, 8]}>
                                <Text typeStyle="copy01" color={tghGreen} mb={2}>
                                    7 Strains of Probiotics
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    We included some of the most important probiotic strains to help contribute to a
                                    diverse gut microbiome: B. <Text
                                        color="grays.4"
                                        display="inline"
                                        fontStyle="italic"
                                    >subitilus
                                    </Text>, B. <Text
                                                                   color="grays.4" display="inline" fontStyle="italic"
                                                               >coagulans
                                                               </Text>, L. <Text
                                        color="grays.4" display="inline" fontStyle="italic"
                                    >rhamnosus
                                    </Text>, L. <Text
                                                                   color="grays.4" display="inline" fontStyle="italic"
                                                               >plantarum
                                                               </Text>, L.
                                    <Text color="grays.4" display="inline" fontStyle="italic">acidophilus</Text>,
                                    B. <Text color="grays.4" display="inline" fontStyle="italic">infantis</Text>,
                                    B. <Text color="grays.4" display="inline" fontStyle="italic">lactis</Text>.<Sup
                                        variant="dagger"
                                    />
                                </Text>

                                <Text typeStyle="copy01" fontStyle="italic" color={tghGreen} mb={2}>
                                    Saccharomyces boulardii
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    A gut-friendly yeast that acts as a probiotic, S. <Text
                                        color="grays.4"
                                        display="inline"
                                        fontStyle="italic"
                                    >Boulardii
                                    </Text> survives stomach acid to
                                    help restock your gut with beneficial microorganisms that support normal
                                    elimination.<Sup variant="dagger" />
                                </Text>
                            </Box>
                            <Box flex="1 0 44%">
                                <Text typeStyle="copy01" color={tghGreen} mb={2}>
                                    Betaine Hydrochloride (HCL)
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    A compound that helps promote stomach acid production, betaine HCl helps break down
                                    food and supports digestion.<Sup variant="dagger" />
                                </Text>
                                <Text typeStyle="copy01" color={tghGreen} mb={2}>
                                    2 types of organic prebiotics
                                </Text>
                                <Text as="p" typeStyle="copyHero02" color="grays.4" mb={6}>
                                    Organic Jerusalem artichoke and organic dandelion root help feed the gut’s probiotic
                                    strains, aiding in a positive balance in gut flora.<Sup variant="dagger" />
                                </Text>
                            </Box>
                        </Box>
                    </CopyDisplay02>

                    <OnnitLogoBackground
                        fillColor="#54c2b7"
                        fillOpacity={0.1}
                        bgFillColor="black"
                        backgroundColor="#000000"
                        backgroundSize="cover"
                    >
                        <FiveStarTestimonials
                            titleColor={darkTeal}
                            title={(
                                <>
                                    What customers are saying about Total GUT HEALTH
                                    <Sup variant="reg" />
                                </>
                            )}
                            testimonials={testimonials}
                            textColor="white"
                            flexDirection="row"
                        />
                        <Box display="flex" justifyContent="center" pt={6}>
                            <Button
                                size="medium"
                                width={[1, 1, "auto"]}
                                onClick={() => scrollToBuyBox()}
                                textColor="black"
                                color="white"
                                raised
                            >
                                TRY IT NOW - RISK FREE
                            </Button>
                        </Box>
                        <Text
                            mt={[4, 6]}
                            textAlign="center"
                            fontSize={0}
                            color="white"
                            target="_blank"
                            className="underline"
                            fontWeight="heavy"
                            href="/terms-and-conditions/subscription/"
                            as="a"
                            display="block"
                        >
                            Read the Terms and Conditions.
                        </Text>
                    </OnnitLogoBackground>
                </div>

                <UsageAndDisclaimers supplementFacts={<SupplementFacts />}>
                    <Text typeStyle="caption02" mb={4}>
                        <Text
                            as="span"
                            fontWeight="bold"
                            color="grays.5"
                            mt={4}
                            display="inline"
                        >WARNING:
                        </Text> Consult a medical doctor before taking this or any
                        other
                        nutritional
                        supplement if vou are pregnant, nursing, have or suspect a medical condition, or are taking
                        any medications.
                        <Text as="span" fontWeight="bold" mt={4} color="grays.5">
                            KEEP OUT OF REACH OF CHILDREN.<br />Store in a cool, dry place.
                        </Text>
                    </Text>
                    <Text
                        fontSize={0}
                        target="_blank"
                        href="/terms-and-conditions/subscription/"
                        as="a"
                    >
                        Read the Terms and Conditions here.
                    </Text>
                </UsageAndDisclaimers>

                <Faqs faqs={faqs} px={4} pb={[6, 6, 8]} />
            </>
        </ThemeProvider>
    );
}
/* eslint-enable */
