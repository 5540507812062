import React from "react";
import useOnnitContext from "@onnit-js/ui/hooks/useOnnitContext";
import HorizontalScrollGrid from "@onnit-js/ui/components/slider/HorizontalScrollGrid";
import useRecommendationsList from "@onnit-js/ui/hooks/amazonPersonalize/useRecommendationsList";
import GoogleTagManagerUtil from "@onnit-js/ui/utils/google-tag-manager/GoogleTagManagerUtil";
import ListNameEnum from "@onnit-js/ui/@types/enums/ListNameEnum";
import ProductDetailRecommendationItem from "@onnit-js/ui/components/amazonPersonalize/ProductDetailRecommendationItem";
import Box from "@onnit-js/ui/components/box/Box";
import Text from "@onnit-js/ui/components/text/Text";

export default ({ ...rest }) => {
    const onnitContext = useOnnitContext();
    const { paginatedData, } = useRecommendationsList({ pageType: "PRODUCT_DETAIL_PAGE", perPage: 7 });

    if (!onnitContext || !paginatedData || !paginatedData.data || paginatedData.data.length === 0) {
        return null;
    }

    return (
        <Box {...rest} bg="grays.0" py={5} maxWidth={["100%", "100%", "100%", 365]}>
            <Text color="grays.6" textAlign="center" fontSize={1} mb={1} fontWeight="bold" pb={2}>
                You may also like
            </Text>
            <Box>
                <HorizontalScrollGrid itemMaxWidth={130} showScrollBar scrollBarColor="blue">
                    {paginatedData?.data.map((item, index) => (
                        <ProductDetailRecommendationItem
                            key={item.product_id}
                            item={item}
                            bgColor={index % 2 === 0 ? "grays.0" : "grays.1"}
                            onClick={(event) => GoogleTagManagerUtil.productClick(event, item, ListNameEnum.RECOMMENDATIONS, index)}
                        />
                    ))}
                </HorizontalScrollGrid>
            </Box>
        </Box>
    );
};
