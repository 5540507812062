import React, { ReactNode } from "react";
import { Text } from "@onnit-js/ui/components/text";
import { Block, BLOCKS, Inline } from "@contentful/rich-text-types";
import SuperScriptSpecialChars from "../common/SuperScriptSpecialChars";

export const renderImageNode = (node: Block | Inline): ReactNode => (
    <img
        src={`https://${node.data.target.fields.file.url}`}
        height={node.data.target.fields.file.details.image.height}
        width={node.data.target.fields.file.details.image.width}
        alt={node.data.target.fields.description}
    />
);

export const renderTextNode = (text: string) => <SuperScriptSpecialChars text={text} />;

export const BANNER_RICH_TEXT_OPTIONS = {
    renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: renderImageNode,

        [BLOCKS.PARAGRAPH]: (node: Block | Inline, children: ReactNode): ReactNode => (
            <Text as="p" typeStyle="body" color="grays.4" mb={3}>
                {children}
            </Text>
        ),
    },
    renderText: renderTextNode,
};
