import React, { useState } from "react";
import Button, { ButtonProps } from "@onnit-js/ui/components/button/Button";
import { CartProductConfig } from "../../interfaces/CartProductConfig";

interface Props extends ButtonProps {
    configs: CartProductConfig[];
    buyBtnText?: string;
    buttonColor?: string;
    buttonTextColor?: string;
    inStock?: boolean;
    onSuccess?: () => void;
    onError?: () => void;
}

const AddToCartButton: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (
    {
        configs,
        inStock = true,
        size = "medium",
        buyBtnText = "Add to Bag",
        buttonColor = "black",
        buttonTextColor = "white",
        onSuccess,
        onError,
        ...rest
    }) => {
    const [isAddingToCart, setAddingToCart] = useState(false);

    const addToCart = () => {
        // Push this check to when the user clicks the button
        // because ONNIT.cart is defined after this app bootstraps.
        if (!window.ONNIT || !window.ONNIT.cart) {
            throw new Error("ONNIT.cart is undefined");
        }

        setAddingToCart(true);

        window.ONNIT.cart
            .addProducts(configs)
            .then(() => {
                if (onSuccess) {
                    onSuccess();
                    return;
                }
                window?.ONNIT?.cart?.openDrawer();
            })
            .catch((error) => {
                console.error("Failed to add product to cart.", error);
                if (onError) {
                    onError();
                }
            })
            .finally(() => setAddingToCart(false));
    };

    return inStock ? (
        <Button
            color={buttonColor}
            textColor={buttonTextColor}
            width={[1, "auto"]}
            size={size}
            onClick={(event) => {
                event.preventDefault();
                addToCart();
            }}
            disabled={isAddingToCart}
            {...rest}
        >
            {isAddingToCart ? "Adding..." : buyBtnText}
        </Button>
    ) : (
        <Button disabled size={size} {...rest}>
            Out of Stock
        </Button>
    );
};

export default AddToCartButton;
