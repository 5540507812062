export default function makePseudoButtonProps(callback: Function): Object {
    const open = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "click" || (event.type === "keyup" && event.key === "Enter")) {
            callback();
        }
        return false;
    };

    return {
        onClick: open,
        onKeyUp: open,
        onKeyDown: (event: any) => {
            event.stopPropagation();
        },
        role: "button",
        style: { cursor: "pointer" },
        tabIndex: 0,
    };
}
