import { useReducer } from "react";

export const enum RemoteDataTypes {
    NOT_ASKED = "NOT_ASKED",
    LOADING = "LOADING",
    FAILURE = "FAILURE",
    SUCCESS = "SUCCESS",
}

export interface RemoteData {
    type: RemoteDataTypes;
    loading: boolean;
    errors: any;
    data: any;
    eq: (type: RemoteDataTypes) => boolean;
}

// eslint-disable-next-line func-style
function eq(this: RemoteData, type: RemoteDataTypes): boolean {
    return this.type === type;
}

const defaultState: RemoteData = {
    type: RemoteDataTypes.NOT_ASKED,
    loading: false,
    errors: null,
    data: null,
    eq,
};
const reducer = (state: any, action: { type: RemoteDataTypes; payload?: any }) => {
    switch (action.type) {
        case RemoteDataTypes.NOT_ASKED:
            return {
                type: RemoteDataTypes.NOT_ASKED,
                loading: false,
                errors: null,
                data: null,
                eq,
            };
        case RemoteDataTypes.LOADING:
            return {
                type: RemoteDataTypes.LOADING,
                loading: true,
                errors: null,
                data: null,
                eq,
            };
        case RemoteDataTypes.FAILURE:
            return {
                type: RemoteDataTypes.FAILURE,
                loading: false,
                errors: action.payload,
                data: null,
                eq,
            };
        case RemoteDataTypes.SUCCESS:
            return {
                type: RemoteDataTypes.SUCCESS,
                loading: false,
                errors: null,
                data: action.payload,
                eq,
            };
        default:
            return state;
    }
};

export default function useRemoteDataState(initialState?: RemoteData) {
    const [remoteData, dispatch] = useReducer(reducer, initialState || defaultState);
    return [remoteData, dispatch];
}
