import { ifProp, prop, theme } from "styled-tools";
import styled, { css } from "styled-components";
import { Box, BoxProps } from "../box/Box";

interface Props extends BoxProps {
    count?: number;
    width?: string;
    gap?: number;
}

const MultiColumnText = styled(Box)<Props>`
    @media ${theme("mediaQueries.sm")} {
        column-count: ${prop("count", 2)};
        column-gap: ${(prp) => theme(`space.${prp.gap}`, 8)}px;
        ${ifProp(
    "width",
    css<Props>`
                column-width: ${prop("width")};
            `
)};
    }
`;

export default MultiColumnText;
