import { useCallback, useEffect, useState } from "react";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import OnnitPageData from "../interfaces/OnnitPageData";

type FindProduct = (pid: number) => Product | null;

export default function usePageData() {
    const [pageData, setPageData] = useState<OnnitPageData | null>(null);

    const findProduct: FindProduct = useCallback(
        (pid: number) => {
            // Merge products and other_products for convenience
            if (!pageData) {
                return null;
            }

            return (pageData.products ?? [])
                .concat(pageData.other_products ?? [])
                .find((product) => product.id === pid) ?? null;
        },
        [pageData]
    );

    useEffect(() => {
        if (window) {
            if (window.ONNIT_PAGE_DATA) {
                setPageData(window.ONNIT_PAGE_DATA);
            }
        }
    }, []);

    return {
        pageData,
        findProduct,
    };
}
