import React, { ReactNode } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Box from "@onnit-js/ui/components/box/Box";
import { Block, BLOCKS, Inline, Document } from "@contentful/rich-text-types";
import { Text, TextProps } from "@onnit-js/ui/components/text/Text";
import { TextContainer } from "../../interfaces/SiteContent";
import { renderTextNode, renderImageNode } from "./RichTextMapping";

interface Props {
    data: TextContainer;
}

const makeComponent = (el: string, { ...rest }: TextProps) => (
    node: Block | Inline,
    children: ReactNode
): ReactNode => (
    <Text as={el} {...rest}>
        {children}
    </Text>
);

const ContentfulTextContainer = ({ data: { content, boxProps, textProps } }: Props) => (
    <Box {...boxProps}>
        <>
            {documentToReactComponents(content as Document, {
                    renderNode: {
                        [BLOCKS.EMBEDDED_ASSET]: renderImageNode,
                        // Mapping heading_1 to h2 since we a11y says we can only have one h1 on the page.
                        [BLOCKS.HEADING_1]: makeComponent("h2", { ...textProps, mb: 4 }),
                        [BLOCKS.HEADING_2]: makeComponent("h2", { ...textProps, mb: 4 }),
                        [BLOCKS.HEADING_3]: makeComponent("h3", { ...textProps, mb: 4 }),
                        [BLOCKS.HEADING_4]: makeComponent("h4", { ...textProps, mb: 4 }),
                        [BLOCKS.HEADING_5]: makeComponent("h5", { ...textProps, mb: 4 }),
                        [BLOCKS.HEADING_6]: makeComponent("h6", { ...textProps, mb: 4 }),
                        [BLOCKS.PARAGRAPH]: makeComponent("p", { ...textProps, mb: 2 }),
                    },
                    renderText: renderTextNode,
                })}
        </>
    </Box>
    );

export default ContentfulTextContainer;
