import React, { useEffect, useState } from "react";
import Button from "@onnit-js/ui/components/button/Button";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import OnnitLogo from "@onnit-js/ui/components/logo/OnnitLogo";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { BsChat } from "react-icons/bs";
import FreeShipping from "./FreeShipping";

interface StickyHeaderProps extends BoxProps {
    showLogo?: boolean;
    children?: any;
    buttonText?: string;
    textColor?: string;
    color?: string;
    showMessage?: boolean;
    showHelp?: boolean;
}

const elementReady = (selector: string): Promise<Element> => new Promise((resolve) => {
    const el = document.querySelector(selector);
    if (el) {
        resolve(el);
        return;
    }
    new MutationObserver((mutationRecords, observer) => {
        // Query for elements matching the specified selector
        Array.from(document.querySelectorAll(selector)).forEach((element) => {
            resolve(element);
            // Once we have resolved we don't need the observer anymore.
            observer.disconnect();
        });
    }).observe(document.documentElement, {
        childList: true,
        subtree: true,
    });
});

export default function StickyHeader(
    {
        showLogo = false,
        bg = "rgba(249, 249, 249, 0.8)",
        children,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        buttonText = "Buy Now",
        textColor,
        color,
        showMessage = true,
        showHelp = true,
        ...rest
    }: StickyHeaderProps) {
    const [chatReady, setChatReady] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        elementReady("#launcher").then(() => setChatReady(true));
    }, []);
    const onHelpButtonClick = () => {
        if (window.zE) {
            window.zE("messenger", "show");
            window.zE("messenger", "open");
        } else {
            console.error("Zendesk chat widget is not available");
        }
    };

    return (
        <Box
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 12,
                position: "sticky",
                top: 0,
                left: 0,
                zIndex: 999,
                minHeight: 60
            }}
            {...rest}
            bg={bg}
        >
            {showLogo && <OnnitLogo width={[120, 120, 136]} />}
            {showMessage && <FreeShipping />}
            <Box ml="auto">
                {children}
                {showHelp && chatReady && (
                    <Button
                        ml={2}
                        size="small"
                        color={color}
                        textColor={textColor}
                        raised={false}
                        onClick={onHelpButtonClick}
                    >
                        <Icon icon={BsChat} mr={1} size={14} color="white" /> Help
                    </Button>
                )}
            </Box>
        </Box>
    );
}
