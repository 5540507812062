import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import RecommendationItem from "@onnit-js/ui/components/amazonPersonalize/RecommendationItem";
import Product from "@onnit-js/ui/@types/interfaces/product/Product";
import ListNameEnum from "@onnit-js/ui/@types/enums/ListNameEnum";
import GoogleTagManagerUtil from "@onnit-js/ui/utils/google-tag-manager/GoogleTagManagerUtil";

interface RecommendationsListProps {
    recommendations: Product[],
}

const RecommendationsList: React.FC<React.PropsWithChildren<React.PropsWithChildren<RecommendationsListProps>>> = ({ recommendations }) => (
    <Box
        display="flex"
        flexDirection={["column", "column", "row", "row"]}
        justifyContent={["center", "center", "space-around", "space-between"]}
        alignItems={["flex-start", "flex-start", "center", "center"]}
        flexWrap="wrap"
    >
        {recommendations.map((item, index) => (
            <RecommendationItem
                key={item.product_id}
                item={item}
                onClick={(event) => GoogleTagManagerUtil.productClick(
                    event, item, ListNameEnum.RECOMMENDATIONS, index,
                )}
                borderTop={undefined}
                borderBottom={undefined}
                width={["100%", "100%", "50%", "33%"]}
            />
        ))}
    </Box>
);

export default RecommendationsList;
