import React, { Ref } from "react";
import SelectField, { SelectFieldProps } from "../../form/selectfield/SelectField";
import ReadOnly from "../../form/field/ReadOnly";
import { ProductNameTransform } from "../BuyBox";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";

export interface ProductSelectProps extends SelectFieldProps {
    selectedProduct: Product;
    products: Product[];
    onProductChange: (product: Product) => void;
    productNameTransform?: ProductNameTransform;
    ref?: Ref<unknown>;
}

export default function ProductSelect(
    {
        selectedProduct,
        products,
        label,
        onProductChange,
        productNameTransform,
        ...rest
    }: ProductSelectProps) {
    return (
        <>
            {products.length > 1 ? (
                <SelectField
                    {...rest}
                    flex="1"
                    my={0}
                    label={label}
                    value={selectedProduct.id}
                    onChange={(event) => {
                        const pid = parseInt(event.target.value);
                        const product = products.find((prp) => prp.id === pid);
                        if (product) {
                            onProductChange(product);
                        }
                    }}
                >
                    {products.map((prp) => (
                        <option key={prp.id} value={prp.id}>
                            {productNameTransform ? productNameTransform(prp.name, prp.id) : prp.name}
                            {!prp.in_stock && " (out of stock)"}
                        </option>
                    ))}
                </SelectField>
            ) : (
                <ReadOnly label={label} value={selectedProduct.name} my={0} />
            )}
        </>
    );
}
