import React from "react";
import Product from "../../../@types/interfaces/product/Product";
import useAddToBag from "../../../hooks/useAddToBag";
import UrlUtil from "../../../utils/UrlUtil";
import Button, { ButtonProps } from "../../button/Button";

export interface ProductCTAProps extends ButtonProps {
    product: Product
}

export const ProductCTA: React.FC<React.PropsWithChildren<ProductCTAProps>> = ({ product, ...rest }) => {
    const { product_id, is_apparel } = product;
    const { addToBag, isAddingToBag } = useAddToBag();
    const url = UrlUtil.getProductUrl(product);

    const sharedProps: ButtonProps = {
        display: "block",
        width: 1,
        size: "medium",
        mb: 2,
    };

    if (is_apparel && url) {
        return <Button el="a" href={url} {...sharedProps} {...rest}>View Sizes</Button>;
    }
    return (
        <Button
            {...sharedProps}
            {...rest}
            disabled={isAddingToBag}
            onClick={() => addToBag({
                product_id,
                quantity: 1,
                subscription_interval: null,
            })}
        >
            {isAddingToBag ? "Adding..." : "Add to Bag"}
        </Button>
    );
};