import React from "react";
import { Box, BoxProps } from "../box/Box";
import Product from "../../@types/interfaces/product/Product";
import ProductImage from "../image/ProductImage";
import UrlUtil from "../../utils/UrlUtil";
import RecommendationItemPrice from "./RecommendationItemPrice";
import RecommendationItemProductName from "./RecommendationItemProductName";
import { ProductCTA } from "./shared/ProductCta";

export interface RecommendationItemProps extends BoxProps {
    item: Product,
    onClick: (event: any) => void;
}

const RecommendationItem: React.FC<React.PropsWithChildren<RecommendationItemProps>> = ({ item, onClick, ...rest }) => {
    const { product_name, authority_image_url } = item;
    const url = UrlUtil.getProductUrl(item);

    return (
        <Box
            display={["block", "block", "flex"]}
            py={2}
            borderTop="1px solid"
            borderBottom="1px solid"
            borderColor="grays.1"
            alignItems="center"
            {...rest}
        >
            <Box display="flex" flex="1 1 auto" alignItems="center" mb={[2, 2, 0]}>
                <ProductImage
                    productUrl={url}
                    imageUrl={authority_image_url}
                    productName={product_name}
                    width="100px"
                    onClick={onClick}
                />

                <Box flex="1 1 auto" p={2}>
                    <RecommendationItemProductName item={item} />
                    <RecommendationItemPrice item={item} />
                    <Box mt={2} width="190px">
                        <ProductCTA product={item} />
                    </Box>
                </Box>
            </Box>

        </Box>
    );
};

export default RecommendationItem;
