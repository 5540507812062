import React from "react";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import ProductType from "@onnit-js/ui/@types/enums/ProductType";
import StickyButtonAddToBagModal from "./StickyButtonAddToBagModal";

export interface AddToBagSimpleStickyProps {
    products: Product[];
    productName: string;
    rating: number;
}

export default ({ products, productName, rating }: AddToBagSimpleStickyProps) => {
    if (!products) {
        return null;
    }

    const inStock = products[0].in_stock ?? false;
    const isSubscriptionEligible = products[0].meta["subscription-eligible"] === "yes" ?? false;
    const isApparel = products[0].type_id === ProductType.APPAREL_CONTAINER ?? false;

    if (!inStock || (products.length === 1 && !isSubscriptionEligible && !isApparel)) {
        return null;
    }

    return (
        <StickyButtonAddToBagModal
            buttonProps={{
                size: "small",
                style: { borderWidth: "1px", borderColor: "rgb(255, 255, 255)", boxShadow: "rgb(255,255,255 / 40%" },
            }}
            products={products}
            productName={productName}
            rating={rating}
        />
    );
};
