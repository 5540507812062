import React from "react";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";
import StockNotificationForm from "../../form/StockNotificationForm";

const OOSView: React.FC<React.PropsWithChildren<{ product: Product }>> = ({ product, children }) => (
    <>
        <StockNotificationForm
            pid={product.id}
            mt={4}
            bg="grays.0"
            p={4}
            onSignup={() => {
                window.ONNIT?.googleTagManager?.backInStockNoticeRegistered(product.name);
            }}
        />
        {children}
    </>
);

export default OOSView;
