import { Favorite } from "../../../../@types/interfaces/favorites/Favorite";
import { PaginatedResponse } from "../../../../@types/interfaces/PaginatedResponse";
import config from "../../../../config";

const { API_URL } = config;

const defaultOptions: RequestInit = {
    headers: new Headers({ "content-type": "application/json" }),
    credentials: "include",
};

const handleInitialResponse = (response: Response) => {
    if (!response.ok) {
        if ([401, 403].includes(response.status)) {
            window.location.href = `/cart/logoff.php?next=${encodeURIComponent(window.location.pathname)}`;
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
            status: response.status,
            statusText: response.statusText,
        });
    }

    return response.headers.get("content-type") === "application/json" ? response.json() : response.text();
};

export const get = (customerId: number): Promise<PaginatedResponse<Favorite>> => fetch(`${API_URL}/customers/${customerId}/favorites?per_page=2000`, defaultOptions).then(
    handleInitialResponse
);

const handleResponse = (response: Response) => {
    if (!response.ok) {
        if ([401, 403].includes(response.status)) {
            window.location.href = `/cart/logoff.php?next=${encodeURIComponent(window.location.pathname)}`;
        }

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
            status: response.status,
            statusText: response.statusText,
        });
    }

    return response.headers.get("content-type") === "application/json" ? response.json() : response.text();
};

export const create = (customerId: number, productId: number): Promise<Favorite> => fetch(`${API_URL}/customers/${customerId}/favorites`, {
    ...defaultOptions,
    method: "POST",
    body: JSON.stringify({ product_id: productId }),
}).then(handleResponse);

export const destroy = (customerId: number, favoriteId: number): Promise<undefined> => fetch(`${API_URL}/customers/${customerId}/favorites/${favoriteId}`, {
    ...defaultOptions,
    method: "DELETE",
}).then(handleResponse);
