import { useState } from "react";
import { CartProductConfig } from "../@types/interfaces/CartProductConfig";

interface Props {
    onSuccess?: () => void;
    onError?: (error: any) => void;
    listName?: string;
}

export default function useAddToBag(props?: Props) {
    const [isAddingToBag, setAddingToBag] = useState(false);

    const addToBag = (config: CartProductConfig | CartProductConfig[]) => {
        // Push this check to when the user clicks the button
        // because ONNIT.cart is defined after this app bootstraps.
        if (!window.ONNIT || !window.ONNIT.cart) {
            throw new Error("ONNIT.cart is undefined");
        }

        setAddingToBag(true);

        window.ONNIT.cart
            .addProducts(Array.isArray(config) ? config : [config], undefined, undefined, props?.listName)
            .then(() => {
                window?.ONNIT?.cart?.openDrawer();
                if (props?.onSuccess) {
                    props.onSuccess();
                }
            })
            .catch((error) => {
                console.error("Failed to add product to cart.", error);
                if (props?.onError) {
                    props.onError(error);
                }
            })
            .finally(() => setAddingToBag(false));
    };

    return {
        addToBag,
        isAddingToBag,
    };
}
