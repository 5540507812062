import React, { ReactNode, useRef } from "react";
import styled, { css, keyframes } from "styled-components";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { theme } from "styled-tools";
import { Box, BoxProps } from "../../box/Box";
import IndexIcon from "../../shared/IndexIcon";

interface Props extends BoxProps {
    currentIndex: number;
    updateIndex: (i: number) => void;
    children?: ReactNode[];
}

const imageFramesDesktop = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

const dashFramesDesktop = keyframes`
    0% {
        opacity: 0;
        width: 224px;
        left: 0;
    }
    50% {
        width: 100%;
        opacity: 1;
    }
    100% {
        width: 224px;
        right: 0;
        opacity: 0;
    }
`;

const desktopTransition = css`
    .dashContainer {
        position: absolute;
        animation-timing-function: cubic-bezier(0.78, 0, 0.15, 1);
        animation-duration: 1s;
        animation-fill-mode: forwards;
        overflow: hidden;
        opacity: 0;
        width: 224px;
        height: 135px;
        display: flex;

        svg,
        .animatedRect {
            height: 135px;
        }

        .animatedRect {
            background-color: black;
            flex: 1;
        }
    }

    .imageContainer {
        animation-timing-function: cubic-bezier(0, 0, 0.72, 0.17);
        animation-delay: 550ms;
        animation-duration: 750ms;
        animation-fill-mode: forwards;
    }

    .testimonial-exit-active {
        transition: opacity 300ms, transform 300ms;
        opacity: 0;
        transform: translateX(100px);
    }

    .testimonial-enter {
        .imageContainer {
            opacity: 0;
        }

        .quoteContainer,
        .citeContainer {
            transform: translateX(-100px);
            opacity: 0;
        }
    }

    .testimonial-enter-active,
    .testimonial-enter-done {
        .dashContainer {
            animation-name: ${dashFramesDesktop};
        }

        .imageContainer {
            animation-name: ${imageFramesDesktop};
        }

        .quoteContainer,
        .citeContainer {
            transform: translateX(0);
            opacity: 1;
            transition: all 300ms;
            transition-delay: 750ms;
        }

        .citeContainer {
            transition-delay: 850ms;
        }
    }
`;

const StyledTestimonialList = styled(Box)<BoxProps>`
    display: grid;
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 1fr 40px;

    .testimonialSlider {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    ${desktopTransition};
`;

const Desktop: React.FC<React.PropsWithChildren<Props>> = (
    {
        currentIndex,
        updateIndex,
        children,
        ...rest
    }) => {
    const nodeRef = useRef(null);
    return (
        <StyledTestimonialList {...rest}>
            <Box gridColumn={["1 / -1", "1 / -1", "2 / span 10"]}>
                <div className="testimonialSlider">
                    <SwitchTransition>
                        <CSSTransition
                            key={currentIndex}
                            nodeRef={nodeRef}
                            classNames="testimonial"
                            timeout={{
                                enter: 750,
                                exit: 300
                            }}
                        >
                            <div ref={nodeRef}>{children && children[currentIndex]}</div>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            </Box>
            <Box
                gridColumn={["1 / -1", "1 / -1", "2 / span 10"]}
                ml={[0, 0, "40%"]}
                display={["flex", "flex", "block"]}
                px={6}
                justifyContent="center"
            >
                {React.Children.map(children, (child, index) => (
                    <IndexIcon
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        active={index === currentIndex}
                        onClick={() => updateIndex(index)}
                        style={{ cursor: "pointer" }}
                    />
                ))}
            </Box>
        </StyledTestimonialList>
    );
};

export default Desktop;
