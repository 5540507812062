import React from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import Img from "@onnit-js/ui/components/image/Image";
import { ImageContainer } from "../../interfaces/SiteContent";

interface Props extends BoxProps {
    data: ImageContainer;
}

const ContentfulImageContainer = ({ data: { lazyLoad, boxProps = [], image } }: Props) => (
    <Box {...boxProps}>
        <Img lazyLoad={lazyLoad} {...image} />
    </Box>
);

export default ContentfulImageContainer;
