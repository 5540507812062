import { MediaContentShowcaseItem } from "@onnit-js/ui/components/module/MediaContentShowcase";

const ingredientsFlowBlend: MediaContentShowcaseItem[] = [
    {
        image: `l-theanine.png?q=75&auto=compress,format`,
        imageWidth: 560,
        imageHeight: 560,
        title: "L-theanine",
        summary:
            'An amino acid that supports the release of dopamine and serotonin in the brain -- two hormones that help induce relaxation via alpha wave activity.<span class="oui-sup">&dagger;</span>',
        detail:
            'Supports alpha brain wave activity, promoting "flow state" — the feeling of being in the zone.<span class="oui-sup">&dagger;</span>',
    },
    {
        image: `2023-AB-Wpage-keyIngredients-phosphatidyserine.png?q=75&auto=compress,format`,
        imageWidth: 560,
        imageHeight: 560,
        title: "Phosphatidylserine",
        summary: "A type of fat found in cell membranes.",
        detail: 'Serves an important role in cell structure and function.<span class="oui-sup">&dagger;</span>',
    },
    {
        image: `2023-AB-Wpage-keyIngredients-alphagpc.png?q=75&auto=format`,
        imageWidth: 560,
        imageHeight: 560,
        title: "Alpha GPC",
        summary: "A compound that supplies choline, a precursor to the neurotransmitter acetylcholine.",
        detail: 'Supports acetylcholine levels.<span class="oui-sup">&dagger;</span>',
    },
    {
        image: `2023-AB-Wpage-keyIngredients-huperzia-extract.png?q=75&auto=format`,
        imageWidth: 560,
        imageHeight: 560,
        title: "Huperzia serrata extract",
        summary: "This Toothed Clubmoss contains Huperzine A.",
        detail: 'Helps slow the breakdown of acetylcholine.<span class="oui-sup">&dagger;</span>',
    },
    {
        image: `2023-AB-Wpage-keyIngredients-vitaminb6.png?q=75&auto=format`,
        imageWidth: 560,
        imageHeight: 560,
        title: "Vitamin B6",
        summary: "A water-soluble vitamin involved in more than 100 physiological reactions.",
        detail: 'Plays an important role in neurotransmitter production.<span class="oui-sup">&dagger;</span>',
    },
];

export default ingredientsFlowBlend;
