import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import MediaContentShowcase from "@onnit-js/ui/components/module/MediaContentShowcase";
import { Img } from "@onnit-js/ui/components/image";
import { Text } from "@onnit-js/ui/components/text";
import TwelveColumn from "@onnit-js/ui/components/module/grid/12/Grid";
import ingredientsFlowBlend from "./ingredients-flow-blend";

export default ({ imageUrl }: { imageUrl: string }) => {
    const AB_IMG_URL = imageUrl;

    return (
        <Box bg="black" py={[6, 6, 6, 8]}>
            <Box>
                <TwelveColumn mb={4}>
                    <Box gridColumn={["1 / -1", "1 / -1", "1 / -1", "2 / span 12"]} pt={3} px={[4, 4, 4, 0]}>
                        <Text role="heading" aria-level={2} as="p" typeStyle="copyDisplay02" color="white">
                            Key Ingredients
                        </Text>
                    </Box>
                </TwelveColumn>

                <MediaContentShowcase
                    mb={[6]}
                    items={ingredientsFlowBlend.map((item) => {
                        const copy = { ...item };
                        copy.image = `${AB_IMG_URL}/${item.image}`;
                        return copy;
                    })}
                    textColor="#6e6e6e"
                    activeColor="white"
                    bgMedia={(
                        <Img
                            placeholder={[754, 208]}
                            src={`${AB_IMG_URL}/2023-AB-Wpage-keyIngredients-background.png?q=75&auto=format`}
                            alt="River background for Flow Blend"
                        />
                    )}
                />
            </Box>
        </Box>
    );
};
