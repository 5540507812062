import React, { useReducer, useRef } from "react";
import Media from "@onnit-js/ui/components/media";
import { Controller, useForm } from "react-hook-form";
import { boolean, object, ref, string } from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import useOnnitContext from "../../hooks/useOnnitContext";
import { Box, BoxProps } from "../box/Box";
import { Text } from "../text/Text";
import TextField from "./textfield/TextField";
import CheckBox from "./toggle/CheckBox";
import Button from "../button/Button";
import MessageBox, { MessageLevelEnum } from "../notification/MessageBox";
import EmailOptInText from "../module/EmailOptInText";

interface Props extends BoxProps {
    location: string;
    includePasswordFields?: boolean;
    includeEmailConfirmation?: boolean;
    successMessage?: string;
    onSuccess?: () => void;
    onClose: () => void;
}

interface FormValues {
    firstname: string;
    lastname: string;
    email_address: string;
    email_address2?: string;
    password?: string;
    confirmation?: string;
    newsletter: boolean;
}

interface CsrfTokenArray {
    _CSRF_TOKEN: string;
    _CSRF_INDEX: string;
}

const makeFormSchema = (includePasswordFields: boolean, includeEmailConfirmation: boolean) => {
    const schema: any = {
        firstname: string().label("First name").required().min(3),
        lastname: string().label("Last name").required().min(3),
        email_address: string().label("Email").required().email("Invalid email"),
        newsletter: boolean(),
    };

    if (includeEmailConfirmation) {
        schema.email_address2 = string()
            .label("Email Confirmation")
            .required()
            .email("Invalid email")
            .oneOf([ref("email_address")], "Email addresses must match");
    }

    if (includePasswordFields) {
        schema.password = string().label("Password").required().min(6);
        schema.confirmation = string()
            .label("Password")
            .required()
            .min(6)
            .oneOf([ref("password")], "Passwords must match");
    }

    return yupResolver(object().shape(schema));
};

const makeRequestPayload = (
    values: FormValues,
    recaptchaToken: string,
    location: string,
    csrfTokenArray: CsrfTokenArray,
): FormData => {
    const formData = new FormData();
    formData.set("action", "create_account_process");
    formData.set("firstname", values.firstname);
    formData.set("lastname", values.lastname);
    formData.set("email_address", values.email_address);
    formData.set("email_address2", values.email_address2 ?? values.email_address);
    formData.set("g-recaptcha-response", recaptchaToken);
    formData.set("is_create_account", "1");
    formData.set("newsletter", values.newsletter ? "1" : "0");
    formData.set("lead_source_new", "onnit:favorites");
    // eslint-disable-next-line no-underscore-dangle
    formData.set("_CSRF_TOKEN", csrfTokenArray._CSRF_TOKEN);
    // eslint-disable-next-line no-underscore-dangle
    formData.set("_CSRF_INDEX", csrfTokenArray._CSRF_INDEX);

    if (values.password && values.confirmation) {
        formData.set("password", values.password);
        formData.set("confirmation", values.confirmation);
    }
    if (values.newsletter) {
        window.ONNIT?.googleTagManager?.newsletterRegistered(location);
    }

    return formData;
};

type Status = "initial" | "submitting" | "success" | "error";

interface State {
    status: Status;
    error?: string;
}

interface Action {
    type: "reset" | "submit" | "success" | "error";
    error?: string;
}

const initialState: State = {
    status: "initial",
    error: undefined,
};
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case "reset":
            return { ...initialState };
        case "submit":
            return {
                status: "submitting",
                error: undefined,
            };
        case "success":
            return {
                status: "success",
                error: undefined,
            };
        case "error":
            return {
                status: "error",
                error: action.error,
            };
        default:
            return { ...initialState };
    }
};

export default function NewCustomerRegistrationForm(
    {
        includePasswordFields = true,
        includeEmailConfirmation = true,
        successMessage = "Your account was created successfully.",
        location,
        onSuccess,
        onClose,
        ...boxProps
    }: Props) {
    const onnitContext = useOnnitContext();

    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        handleSubmit,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        formState: { errors },
        control
    } = useForm({
        resolver: makeFormSchema(includePasswordFields, includeEmailConfirmation)
    });
    const reRef = useRef<any>();

    const getCsrfTokenArray = async () => fetch("/cart/create_account.php?action=getBootstrap", {
        method: "GET",
        headers: new Headers({
            "X-Requested-With": "XMLHttpRequest",
        }),
    })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((text) => {
                    throw new Error(text.message.toString());
                });
            }
            return response.json();
        })
        .then((data) => data);

    const onFormSubmit = async (values: any) => {
        dispatch({ type: "submit" });

        if (!reRef.current) {
            return;
        }

        // Recaptcha token
        const token = reRef.current.getValue();
        reRef.current.reset();
        if (!token) {
            console.error("Recaptcha token absent");
            dispatch({ type: "reset" });
            return;
        }

        let csrfTokenArray: CsrfTokenArray;
        try {
            csrfTokenArray = await getCsrfTokenArray();
        } catch (error: any) {
            console.error(error);
            dispatch({ type: "reset" });
            return;
        }

        const payload = makeRequestPayload(values, token, location, csrfTokenArray);

        fetch("/cart/create_account.php", {
            method: "POST",
            headers: new Headers({
                "X-Requested-With": "XMLHttpRequest",
            }),
            body: payload,
        })
            .then((response) => {
                const contentType = response.headers.get("content-type");
                const parseMethod = contentType === "application/json" ? "json" : "text";
                return response[parseMethod]().then((data) => {
                    if (response.ok) {
                        return Promise.resolve(data);
                    }
                    return Promise.reject(data);
                });
            })
            .then(() => {
                dispatch({ type: "success" });
                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((errors) => {
                // Only show the first error if array
                dispatch({ type: "error", error: Array.isArray(errors) ? errors[0] : errors });
            });
    };

    if (!onnitContext) {
        return null;
    }

    return (
        <Box {...boxProps}>
            {state.status === "success" ? (
                <>
                    <MessageBox level={MessageLevelEnum.SUCCESS} message={successMessage} />
                    <Button width={["100%", "auto"]} mb={[2, 0]} size="medium" onClick={onClose}>
                        Close
                    </Button>
                </>
            ) : (
                <>
                    {state.status === "error" && (
                        <MessageBox level={MessageLevelEnum.ERROR} message={state.error} />
                    )}
                    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
                        <Controller
                            render={({ field, fieldState: { error }, }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="First Name"
                                    error={error?.message}
                                />
                            )}
                            name="firstname"
                            control={control}
                            defaultValue=""
                        />

                        <Controller
                            render={({ field, fieldState: { error }, }) => (
                                <TextField
                                    {...field}
                                    type="text"
                                    label="Last Name"
                                    error={error?.message}
                                />
                            )}
                            name="lastname"
                            control={control}
                            defaultValue=""
                        />

                        <Controller
                            render={({ field, fieldState: { error }, }) => (
                                <TextField
                                    {...field}
                                    type="email"
                                    label="Email"
                                    error={error?.message}
                                />
                            )}
                            name="email_address"
                            control={control}
                            defaultValue=""
                        />

                        {includeEmailConfirmation && (
                            <Controller
                                render={({ field, fieldState: { error }, }) => (
                                    <TextField
                                        {...field}
                                        type="email"
                                        label="Email Confirmation"
                                        error={error?.message}
                                    />
                                )}
                                name="email_address2"
                                control={control}
                                defaultValue=""
                            />
                        )}

                        {includePasswordFields && (
                            <>
                                <Controller
                                    render={({ field, fieldState: { error }, }) => (
                                        <TextField
                                            {...field}
                                            type="password"
                                            label="Password"
                                            error={error?.message}
                                        />
                                    )}
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                />
                                <Controller
                                    render={({ field, fieldState: { error }, }) => (
                                        <TextField
                                            {...field}
                                            name="confirmation"
                                            type="password"
                                            label="Confirmation"
                                            error={error?.message}
                                        />
                                    )}
                                    name="confirmation"
                                    control={control}
                                    defaultValue=""
                                />
                            </>
                        )}

                        <Controller
                            name="newsletter"
                            control={control}
                            defaultValue
                            render={({ field, fieldState: { error }, }) => (
                                <CheckBox
                                    {...field}
                                    checked={field.value}
                                    onChange={(event) => {
                                        field.onChange(event.target.checked);
                                    }}
                                    name="newsletter"
                                    error={error?.message}
                                    label={(
                                        <EmailOptInText mt={1} />
                                    )}
                                />
                            )}
                        />

                        <Box minHeight="78px">
                            <Media query="(min-width: 376px)">
                                {(matches) => (
                                    matches && (
                                        <ReCAPTCHA
                                            sitekey={onnitContext.api_key.recaptcha_public_key}
                                            size={matches ? "normal" : "compact"}
                                            ref={reRef}
                                        />
                                    )
                                )}
                            </Media>
                        </Box>

                        <Box py={3}>
                            <Button
                                width={["100%", "auto"]}
                                mb={[2, 0]}
                                size="medium"
                                type="submit"
                                disabled={state.status === "submitting"}
                            >
                                {state.status === "submitting" ? "Sending..." : "Sign up"}
                            </Button>
                        </Box>
                    </form>
                </>
            )}
        </Box>
    );
}
