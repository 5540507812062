import React, { FormEvent, useState } from "react";
import Button from "@onnit-js/ui/components/button/Button";
import Icon from "@onnit-js/ui/components/icon/Icon";
import { Text } from "@onnit-js/ui/components/text";
import { FaChevronRight } from "react-icons/fa";
import TextField from "@onnit-js/ui/components/form/textfield/TextField";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { isValidEmail } from "../../lib/utils";

interface Props extends BoxProps {
    formId: string;
    portalId: string;
    pageName: string;
    buttonLabel: string;
    onSuccess?: Function;
    fieldBg?: string;
    successFeedbackColor?: string;
    submitBtnText?: string;
    submitBtnColor?: string;
    submitBtnTextColor?: string;
    fieldAriaLabel?: string;
    autoFocusInputField?: boolean;
    successMessageText?: string;
    formProps?: BoxProps;
}

interface FormProps extends Omit<BoxProps, "onSubmit"> {
    onSubmit: (values: { email: string }) => void;
    buttonLabel: string;
    fieldBg?: string;
    submitBtnText?: string;
    submitBtnColor?: string;
    submitBtnTextColor?: string;
    fieldAriaLabel?: string;
    autoFocusInputField?: boolean;
}

const STATUS = {
    INIT: "init",
    SUCCESS: "success",
    ERROR: "error",
};

const Form: React.FC<React.PropsWithChildren<React.PropsWithChildren<FormProps>>> = (
    {
        onSubmit,
        buttonLabel,
        fieldBg,
        submitBtnText,
        submitBtnColor,
        submitBtnTextColor,
        fieldAriaLabel,
        autoFocusInputField,
        ...rest
    }) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setError(null);

        if (!isValidEmail(email)) {
            setError("Please enter a valid email address");
            return;
        }

        onSubmit({ email });
    };

    const disabled = !isValidEmail(email);

    return (
        <form onSubmit={handleSubmit}>
            <Box display="flex" {...rest}>
                <TextField
                    my={[2, 2, 0]}
                    flex="1"
                    placeholder="Email"
                    value={email}
                    aria-label={fieldAriaLabel}
                    onChange={(event) => setEmail(event.target.value.trim())}
                    mr={3}
                    error={error}
                    bg={fieldBg}
                    autoFocus={autoFocusInputField}
                />

                {submitBtnText ? (
                    <Button
                        my={[2, 2, 0]}
                        width={[1, 1, "auto"]}
                        aria-label={buttonLabel}
                        size="medium"
                        type="submit"
                        disabled={disabled}
                        color={submitBtnColor}
                        textColor={submitBtnTextColor}
                    >
                        {submitBtnText}
                    </Button>
                ) : (
                    <Button
                        my={[2, 2, 0]}
                        style={{ padding: 0, height: 48 }}
                        width="48px"
                        aria-label={buttonLabel}
                        size="medium"
                        type="submit"
                        disabled={disabled}
                        color={submitBtnColor}
                        textColor={submitBtnTextColor}
                    >
                        <Icon icon={FaChevronRight} color={disabled ? "grays.5" : submitBtnTextColor} />
                    </Button>
                )}
            </Box>
        </form>
    );
};

const HubSpotEmailCapture: React.FC<React.PropsWithChildren<React.PropsWithChildren<Props>>> = (
    {
        formId,
        portalId,
        pageName,
        buttonLabel,
        onSuccess,
        fieldBg,
        successFeedbackColor = "earthGreen",
        submitBtnText,
        submitBtnColor,
        submitBtnTextColor,
        fieldAriaLabel,
        autoFocusInputField = false,
        successMessageText,
        flexDirection,
        alignItems,
        children,
        formProps,
        ...rest
    }) => {
    const [status, setStatus] = useState(STATUS.INIT);
    const [feedback, setFeedback] = useState("Sorry, there was an error submitting the form.");
    const feedbackColor = status === STATUS.ERROR ? "gameRed" : successFeedbackColor;
    const ariaLabel = fieldAriaLabel || pageName;
    const successMessage = successMessageText || null;

    const sendFormData = (values: { email: string }) => {
        fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                fields: [
                    {
                        name: "email",
                        value: values.email,
                    },
                ],
                context: {
                    // Include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
                    hutk: window.getCookie("hubspotutk") || null,
                    pageUri: window.location.href,
                    pageName,
                },
            }),
        })
            .then((resp) => resp.json().then((data) => (resp.ok ? data : Promise.reject(data))))
            .then((resp) => {
                window.ONNIT?.hubspot?.identify({ email: values.email });

                window?.dataLayer.push({
                    event: "fbqTrack",
                    fbqEvent: "Lead",
                    fbqData: {},
                });
                if (resp.redirectUri) {
                    window.location.href = resp.redirectUri;
                }
                const message = successMessage || resp.inlineMessage;
                setFeedback(message);
                setStatus(STATUS.SUCCESS);
                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((event) => {
                console.error("HubSpot Form Submission Error:", event.message);
                setFeedback("Sorry, there was an error submitting the form.");
                setStatus(STATUS.ERROR);
            });
    };

    return (
        <Box {...rest}>
            {status !== STATUS.SUCCESS && (
                <>
                    {children}
                    <Form
                        {...formProps}
                        onSubmit={sendFormData}
                        buttonLabel={buttonLabel}
                        fieldBg={fieldBg}
                        submitBtnText={submitBtnText}
                        submitBtnColor={submitBtnColor}
                        submitBtnTextColor={submitBtnTextColor}
                        fieldAriaLabel={ariaLabel}
                        flexDirection={flexDirection}
                        alignItems={alignItems}
                        autoFocusInputField={autoFocusInputField}
                    />
                </>
            )}
            {(status === STATUS.ERROR || status === STATUS.SUCCESS) && (
                <Text
                    as="p"
                    role="alert"
                    className="feedbackMessage"
                    my={3}
                    color={feedbackColor}
                    fontWeight="bold"
                    fontSize={4}
                    dangerouslySetInnerHTML={{ __html: feedback }}
                />
            )}
        </Box>
    );
};

export default HubSpotEmailCapture;
