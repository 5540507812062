import { Stories } from "../../interfaces/Story";
import { StoryCategory, StoryType } from "../../enums/Story";

const stories: Stories = [
    {
        headline: "Problems Are Just A Speed Bump",
        title: "Daniel Fajardo’s Onnit Story",
        description:
            "Exercise isn’t just something we do because we need another appointment in our day. It’s supposed to add something to our life, making us feel better and do better at the other things we try. So what happens if your workouts actually subtract something from your quality of life, making it harder, even painful, to do what you love?<br/><br/>That’s the question Daniel Fajardo—a doctor, husband, and father in St. Simons Island, Georgia—found himself asking a few years ago, after years of heavy lifting left him too sore and stiff to play with his kids. The answer he found was Onnit. The Onnit 6 programs’ focus on mobility and longevity helped Fajardo reframe what fitness means, and restore the body he thought had given out on him. <br/><br/>Fajardo tells his tale to Shane Heins, Onnit’s Director of Community Engagement, in this week’s Onnit Story—part of our ongoing series of live video interviews with people who have made great life improvements with Onnit’s help. See the video below, along with an edited transcript of the highlights, time-stamped so you can easily find them in the video. Stay up to date with Onnit Stories by following Onnit’s Instagram TV (IGTV). <a href='https://www.instagram.com/onnit/channel/?hl=en' target='_blank'>(IGTV)</a>.",
        img: "DanielFajardo.jpg",
        href: "https://www.onnit.com/academy/daniel-fajardo-onnit-story/",
        imgAltText: "Daniel Fajardo",
        storyCategory: StoryCategory.Lifestyle,
        storyType: StoryType.Hero,
        date: "2022-11-07",
    },
    {
        headline: "Give More Than You Receive",
        title: "Eric Leija's Onnit Story",
        description:
            "If you have an Instagram account and you’re even mildly interested in kettlebells, you already know Eric Leija, aka @Primal.Swoledier. The perennially ripped and never out of energy Onnit-certified kettlebell coach has been associated with our brand from its infancy, and continues to do us proud as a rising fitness influencer with a massive following (nearly one million followers on IG alone). But for all his accomplishments and glory, he’d rather talk about what YOU are up to. Leija still makes himself available for workshops where he can connect with other coaches and fans alike, not just to spread his own philosophy but to learn theirs as well.<br/><br />The Primal Swoledier recently gave an interview to Onnit Chief Fitness Officer John Wolf (who also happens to be one of Leija’s earliest mentors) for our Onnit Stories series, where we talk to people on camera about how they changed their lives with Onnit’s help. See Leija’s interview below, along with an edited transcript of the highlights, time-stamped so you can find those moments in the video. You can stay up to date with Onnit Stories by following Onnit’s Instagram TV <a href='https://www.instagram.com/onnit/channel/?hl=en' target='_blank'>(IGTV)</a>.",
        img: "eric-leija.jpg",
        href: "https://www.onnit.com/academy/eric-leija-onnit-story/",
        imgAltText: "Eric Leija",
        storyCategory: StoryCategory.Pro,
        storyType: StoryType.Feature,
        date: "2022-09-20",
    },
    {
        headline: "Life Is A Book of Chapters",
        title: "Matt Vincent's Onnit Story",
        description:
            "Working your whole life to be the best at one thing is a noble effort, but it comes with an inherent risk: what if, quite suddenly, you can't do that thing anymore? What are you supposed to do then?<br /><br />When injury forced Matt Vincent to retire from his athletic career, he had to start viewing it not as “the end” but rather the end of a chapter of life. Like any good story, one chapter leads into another, and the main character moves forward. To paraphrase Vincent, most adventure stories don’t peak in Chapter 3.<br /><br />Vincent tells his tale, to Shane Heins, Onnit's Director of Community Engagement, in this week’s Onnit Story—our ongoing series of interviews with people who have changed their lives with Onnit's assistance.",
        img: "OnnitStories-2022-Wpage-MattVincent-1200X700.jpg",
        href: "https://www.onnit.com/academy/matt-vincent-onnit-story/",
        imgAltText: "Matt Vincent doing a dumb-bell curl.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-08-05",
    },
    {
        headline: "I Found A Sister In The Onnit Tribe",
        title: "Mariana Fuentes-Smith's Onnit Story",
        description:
            "When you meet someone on social media, you can't help but be skeptical. Is the person really who they portray themself to be, or a troll trying to lure you into a trap under his bridge? When Mariana Fuentes-Smith discovered Onnit, she not only uncovered a fitness system that changed her body, she gained access to a community of people that changed her life—REAL people, online, with no agenda other than to help one another get fitter and to make friends doing it. This community, of course, is the Onnit Tribe, our private support group on Facebook and Discord which primarily serves the contestants in our Onnit 6 transformation Challenges.",
        img: "OnnitStories-2022-Wpage-Banner-Mariana Fuentes-Smith-1260X554.png",
        href: "https://www.onnit.com/academy/mariana-fuentes-smith/",
        imgAltText: "Mariana Fuentes-Smith stretching.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-07-22",
    },
    {
        headline: "We Owe It To Ourselves",
        title: "Magnus Palmer's Onnit Story",
        description:
            "Proving that Onnit is becoming a force throughout the globe, this week's Onnit Story comes to you from Europe. Magnus Palmer, an Onnit Tribe member for more than a year, has the distinction of being the first overseas subject in our ongoing series of interviews with inspirational guests. Speaking to Onnit's Director of Community Engagement, Shane Heins, Palmer told his story from his native Sweden, including advice on how to find motivation in trying times, and clarifying once and for all whether you really have to be able to assemble an IKEA chair in order to work for the company (Palmer formerly served as a data engineer for the notoriously complex furniture brand!).",
        img: "OnnitStories-2022-Wpage-MagnusPalmer-1200X700.png",
        href: "https://www.onnit.com/academy/magnus-palmer/",
        imgAltText: "Magnus Palmer smiling.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-07-01",
    },
    {
        headline: "You Bought The Ticket, Now Take The Ride",
        title: "Jennifer Cruz's Onnit Story",
        description:
            "If there’s one thing that practically all people on their deathbeds agree on, it’s this: when you look back on your life, it’s not the failures you regret, but rather the times when you didn’t even try. So ask your crush out for coffee, write that screenplay you’ve been mulling over in your head, and, if you’re like Jennifer Cruz, walk into Onnit Gym and enroll in a fitness class.<br /><br />Cruz, one of our Austin, TX, gym’s earliest members, didn’t know if she could handle Onnit’s workouts, especially coming off an injury, but she had the guts to try… and now we can’t get her to stop! Hear her story, as told to our Chief Fitness Officer, John Wolf, in the newest installment of our ongoing Onnit Stories series—interviews with people who have made inspiring life changes with Onnit’s support.",
        img: "OnnitStories-2022-Wpage-Banner-JenCruz-1260X554.png",
        href: "https://www.onnit.com/academy/jennifer-cruz/",
        imgAltText: "Jennifer Cruz performing a bent over row.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-06-17",
    },
    {
        headline: "There Are Multiple Layers of Learning",
        title: "AJ & Georgie's Onnit Story",
        description:
            "For some of us, school was no place to learn. (As Adam Sandler said in Billy Madison, \"Chlorophyll? More like BORE-ophyll!\") Fortunately, some schools today are changing, due to an understanding that we don't all learn the same way. And in the fitness world, personal trainers are changing too, in an effort to help clients understand what they're doing in training and find ways to enjoy the process more.<br /><br />AJ Londono and Georgie Boynton, a couple with a fitness business in south Florida, know this all too well, and train their clients—which have included children—with different techniques that not only make working out more effective, but also not something you can sleep through like a junior high biology class. They speak to Onnit's Director of Community Engagement, Shane Heins, for this week's Onnit Story.",
        img: "OnnitStories-2022-WPage-Georgie-AJ-200X700.png",
        href: "https://www.onnit.com/academy/aj-georgie/",
        imgAltText: "AJ & Georgie smiling.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-05-20",
    },
    {
        headline: "The Holistic Way Is The Only Way",
        title: "Sean Hyson's Onnit Story",
        description:
            "There's an old expression that all roads lead to Rome, meaning that there's no one path to reaching a goal. And while it implies that you can get what you want by many different means, when you think about it, the saying also serves as a clear set of travel directions. In the context of one's fitness journey, you can never optimize strength, body composition, health, or any other single quality without paying attention to all the others. And if you ignore the others, you're sure to go off track. This philosophy is Onnit's mission of Total Human Optimization in a nutshell, and it's also a theme in this week's Onnit Story.<br /><br />Sean Hyson, Editor-in-Chief of Onnit, speaks to Onnit Chief Fitness Officer John Wolf about how he integrated unconventional training with old-school strength work for greater longevity.",
        img: "OnnitStories-2022-DBanner-SeanHyson-Wpage-1200x700.png",
        href: "https://www.onnit.com/academy/sean-hyson/",
        imgAltText: "Sean Hyson lifting a dumbell.",
        storyCategory: StoryCategory.Employee,
        storyType: StoryType.Feature,
        date: "2022-05-06",
    },
    {
        headline: "I Made A Promise To My Daughter",
        title: "Kaysee Brooks' Onnit Story",
        description:
            "Whether you're facing a sink full of dirty dishes or riding out the winds of a hurricane, it pays to see every challenge in your life in a positive light. Onnit Tribe member and O6 Challenge veteran Kaysee Brooks has faced both extremes with the same attitude: \"It's not going to last forever, and we'll be just fine.\" Now she's teaching her daughter to do the same, and it starts with smiling back at what you see in the bathroom mirror.<br /><br />Kaysee spoke to Shane Heins, Onnit's Director of Education, to tell her Onnit Story—part of our ongoing series of interviews with people who have made inspiring life changes with Onnit's support.",
        img: "OnnitStories-KayseeBrooks-Website.png",
        href: "https://www.onnit.com/academy/kaysee-brooks-onnit-story/",
        imgAltText: "Kaysee Brooks stretching.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-04-22",
    },
    {
        headline: "Total Humanitarian Optimization",
        title: "Justin Wren's Onnit Story",
        description:
            "There are plenty of pro athletes who are happy to cash in on their talents, endorse causes that improve their public image, and pose with disenfranchised people for a photo op. And then there's Justin Wren, a successful, American-born MMA fighter who was so rankled by the plight of Africa's Pygmy tribes that he moved to the rainforest to help them.<br /><br />Wren sat down with Shane Heins, Onnit's Director of Education, to discuss his humanitarian efforts, struggle with substance abuse, and return to the MMA ring in this edition of Onnit Stories—an ongoing series of live interviews with people who have made inspiring life changes with Onnit's support.",
        img: "OnnitStories-JustinWren.jpg",
        href: "https://www.onnit.com/academy/justin-wren-onnit-story/",
        imgAltText: "Justin Wren in an MMA fight.",
        storyCategory: StoryCategory.Pro,
        storyType: StoryType.Feature,
        date: "2022-04-08",
    },
    {
        headline: "I Tricked My Body To Exercise",
        title: "Zaki Abdul Malik's Onnit Story",
        description:
            "Zaki Abdul Malik rediscovered his passion for fitness when a late transit bus forced him to walk home one night. Feeling more empowered than angry, Malik made the walk a habit, and it led him back to the sports and martial arts training he enjoyed as a younger man. Now he's participating in Onnit 6 Challenges, making up his own workouts, and teaching his son to make a habit of exercise that he enjoys.<br /><br />Malik tells his story, part of our ongoing Onnit Stories series, in a live video interview conducted by Onnit's Director of Education, Shane Heins.",
        img: "OnnitStories-Website-ZakiAbdulMalik.png",
        href: "https://www.onnit.com/academy/zaki-abdul-malik-onnit-story/",
        imgAltText: "Zaki Abdul Malik smiling.",
        storyCategory: StoryCategory.Pro,
        storyType: StoryType.Feature,
        date: "2022-03-25",
    },
    {
        headline: "My Value Is Not Attached To My Size",
        title: "Julia Morales' Onnit Story",
        description:
            "With fitness, we tend to think that how we feel on the inside will change when we like what we see on the outside. But the truth is usually just the reverse. Julia Morales always thought she’d be happy if she were thinner, so she lost 100 pounds and... was more miserable than ever. <br/> <br/>Through the Onnit 6 fitness transformation Challenges, and the Onnit Tribe—our private Facebook support group—Morales gained self-confidence, improved her health, and embraced a community of kindred spirits. And before long, those changes started showing up in how she looked. <br/> <br/> Shane Heins, Director of Onnit Education, interviewed Morales live for this week's Onnit Story.",
        img: "OnnitStories-JuliaMorales.png",
        href: "https://www.onnit.com/academy/julia-morales/",
        imgAltText: "Julia smiling and showing the right bicep.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-03-01",
    },
    {
        headline: "You Want It To Look Better Than Reality",
        title: "Preston Maddox's Onnit Story",
        description:
            "If you’ve watched Onnit's commercials, Instagram and YouTube content, or Onnit 6 workout videos, you’ve seen the world through Preston Maddox’s eyes. As Onnit’s Lead Videographer, he’s responsible for capturing the moments that show our brand best, without sacrificing its authenticity. It’s a delicate balance for this former rock musician and movie buff with a love for fitness and personal development, and he’s about to give you an insider’s guide to how it’s done.<br /><br />Maddox spoke to Onnit’s Director of Fitness Education, Shane Heins, to tell his Onnit Story, the latest in an ongoing series where we do live interviews with people who have made inspiring life changes since discovering our brand.",
        img: "OnnitStories-PrestonMaddox.png",
        href: "https://www.onnit.com/academy/preston-maddox/",
        imgAltText: "Preston Maddox playing bass guitar.",
        storyCategory: StoryCategory.Employee,
        storyType: StoryType.Feature,
        date: "2022-02-11",
    },
    {
        headline: "My Daughter Needs To See The Good, Bad, and Ugly",
        title: "Michelle Waterson's Onnit Story",
        description:
            "Nicknamed \"The Karate Hottie\" for obvious reasons, Michelle Waterson was one of Onnit's first sponsored athletes. More than a decade later, it's a relationship that's still going strong, as the former Invicta FC atomweight champ continues her climb up the UFC's ranks while embarking on a movie career. But more important to Waterson than success and celebrity status, of course, is her daughter, Araya, 10, for whom she hopes to set a good example—without shielding from the harsh realities of what it takes to make dreams come true.<br /><br />Waterson met with Onnit's Director of Fitness Education, Shane Heins, for this week's Onnit Story—part of our ongoing series of live interviews with people who have made inspiring life changes with Onnit in their corner.",
        img: "OnnitStories-MichelleWaterson.png",
        href: "https://www.onnit.com/academy/michelle-waterson-story/",
        imgAltText: "Michelle Waterson pushing a sled.",
        storyCategory: StoryCategory.Pro,
        storyType: StoryType.Feature,
        date: "2022-01-28",
    },
    {
        headline: "This Is The Hard I’ve Chosen",
        title: "Dale Chambless’ Onnit Story",
        description:
            "When Dale Chambless decided to get in shape, it led to workouts that pushed him to the brink, confronting his physical limitations, and a knee injury he has yet to fully recover from. Still, he wouldn’t have had it any other way. For the father of three, the collateral damage of an active lifestyle will always beat the hazards of weighing 300 pounds, and setting a good example for his kids is more important than protecting his ego.",
        img: "OnnitStories-DaleChambless.png",
        href: "https://www.onnit.com/academy/dale-chambless/",
        imgAltText: "Dale Chambless holding a belt.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-01-13",
    },
    {
        headline: "Grace and Acceptance",
        title: "Michelle Spain's Onnit Story",
        description:
            "Most of us would never let anyone else treat us the way we treat ourselves. We're much harsher and more critical than we have a right to be. In addition to the physical transformation that a fitness journey can provide, people often find that it helps them change the way they see and feel about themselves, emerging more compassionate and self-confident.<br /><br />Michelle Spain, a wife and mom in Preston, Maryland, used to beat herself up about the number she saw on a scale, but with the help of the Onnit Tribe—our private Facebook support group—and the Onnit 6 Challenges, she was able to accept her.",
        img: "OnnitStories-MichelleSpain.png",
        href: "https://www.onnit.com/academy/michelle-spain-story/",
        imgAltText: "Michelle Spain flexing arm and smiling.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2022-01-05",
    },
    {
        headline: "Fitness makes me beam with love",
        title: "Ryan Logsdon's Onnit Story",
        description:
            "For many people, self-care means self-indulgence—eating and drinking whatever you want, being lazy, and otherwise giving in to temptations that can be unhealthy. But when you set out to get fitter, you find that self-care becomes just the opposite. You start craving good sleep and healthy food over pizza and beer, and, no matter how much your last workout pushed you to the edge, you can't wait to hit the next one.<br /><br />Ryan Logsdon found this for himself when he joined Onnit Gym nearly four years ago. And while his physical changes have been exciting, he can’t wait to tell you how his journey with Onnit has made him a better man overall, capable of taking care of others as well as himself. This is his Onnit Story.",
        img: "OnnitStories-RyanLogsdon.png",
        href: "https://www.onnit.com/academy/ryan-logsdon-story/",
        imgAltText: "Ryan Lodgson wearing sunglasses and smiling.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2021-12-15",
    },
    {
        headline: "My co-workers are like family",
        title: "Kamile Ishiyama Dotson’s Onnit Story",
        description:
            "Anyone can get Onnit. Kamile Ishiyama Dotson, Onnit’s Director of Project Management, certainly isn’t “anyone,” but she’s a good example of someone who came to Onnit not knowing what to expect and, by keeping an open mind and a brave spirit, discovered a new path to fitness—and a career in which she excels. Now she’s returning the favor by lending her considerable talents to make Onnit a tighter-run ship than it’s ever been.",
        img: "OnnitStories-KamilleDotson.png",
        href: "https://www.onnit.com/academy/kamile-ishiyama-dotson/",
        imgAltText: "Kamile drinking coffee in a park.",
        storyCategory: StoryCategory.Employee,
        storyType: StoryType.Feature,
        date: "2021-11-01",
    },
    {
        headline: "It's OK To Not Be Understood",
        title: "Leo Savage’s Onnit Story",
        description:
            "Chief Fitness Officer John Wolf connected with Savage for this week’s Onnit Story to find out how steel mace training has helped the coach free his mind as well as his body—and learn why following your passion can make you money, turn enemies into friends, and cement a legacy that lives forever.",
        img: "OnnitStories-Leo.png",
        href: "https://www.onnit.com/academy/leo-savage/",
        imgAltText: "Leo Savage swinging steel club.",
        storyCategory: StoryCategory.Pro,
        storyType: StoryType.Feature,
        date: "2021-12-01",
    },
    {
        headline: "Everyone here is growing",
        title: "Alecia Nicole's Onnit Story",
        description:
            "Not all heroes wear capes… In fact, some of them sit at a desk and answer phones in an office building. It may not sound so glamorous, but these people have the power to save the day. Take Alecia Nicole, for example, Quality Coordinator in Onnit’s customer service department. When a customer ordered some apparel for his wedding and received the wrong product, another company’s service rep might have just offered a refund or gotten a replacement out to him a week later. But Nicole went the extra mile, personally shipping it to the man just in time for him to walk down the aisle.",
        img: "OnnitStories-AleciaNorman.png",
        href: "https://www.onnit.com/academy/alecia-nicole-story/",
        imgAltText: "Alecia Nicole performing yoga pose.",
        storyCategory: StoryCategory.Employee,
        storyType: StoryType.Feature,
        date: "2021-11-02",
    },
    {
        headline: "I can conquer the world now",
        title: "Fay Marzuq's Onnit Story",
        description:
            "Fay Marzuq trusted the Onnit Tribe, our private Facebook group, with her struggle to have a child at age 49—and they didn’t let her down. With the support of newfound friends, she ended up changing her body and her life over the course of her first Onnit 6 Challenge, and has been committed to fitness (and her healthy daughter) ever since. This week, she’s the subject of our Onnit Story—a live interview we do with people who have made positive life changes with Onnit’s help.",
        img: "OnnitStories-FayMazuq-Website.png",
        href: "https://www.onnit.com/academy/fay-marzuq-story/",
        imgAltText: "Fay Marzuq holding a baby.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2021-10-18",
    },
    {
        headline: "You have to experience something challenging",
        title: "Neen Williams' Onnit Story",
        description:
            "Skateboarding pro Neen Williams recognized that after he tore his ACL at age 28—an event that saw him change his lifestyle overnight, and eventually find his way to Onnit. Now 36, he’s in the shape of his life, and plans to be a better sk8er at 80 than he is today. See how in his interview with John Wolf—part of our ongoing Onnit Stories series.",
        img: "OnnitStories-NeenWilliams-Website.png",
        href: "https://www.onnit.com/academy/neen-williams-story/",
        imgAltText: "Neen Williams skateboarding.",
        storyCategory: StoryCategory.Pro,
        storyType: StoryType.Feature,
        date: "2021-10-07",
    },
    {
        headline: "Know where your toes are pointing",
        title: "Nikita Fear's Onnit Story",
        description:
            'If you’re ever reading or listening to something from Onnit and you can’t wrap your head around it, there’s a pretty good chance that Nikita Fear can. A highly experienced trainer at Equinox, Fear is also a natural leader, teacher, and problem-solver with a keen intuition. She’s long been a friend to our brand, as well as an ambassador who’s helped teach our Steel Club certifications in the past. In a live interview for our ongoing Onnit Stories series, Fear talks about how her background in art helped her develop as a fitness pro, how she learned to balance career, training, and life as a new mom, and what Onnit has done to help her find her center in any so-called "storm."',
        img: "OnnitStories-NikitaFear.png",
        href: "https://www.onnit.com/academy/nikita-fear-story/",
        imgAltText: "Nikita Fear at the Onnit Gym",
        storyCategory: StoryCategory.Trainer,
        storyType: StoryType.Feature,
        date: "2021-10-04",
    },
    {
        headline: "Keep shoveling that coal",
        title: "Larry Maloney's Onnit Story",
        description:
            "If you’re trying to escape the corporate grind and transition over to your dream job, Larry Maloney may have the blueprint for you. At one time a franchise manager for Chipotle restaurants, Maloney’s true passion was fitness, and he yearned for the day he could make his living in sweatpants surrounded by heavy iron. It took working two jobs and attending college classes while raising two kids to get there, but today, Maloney is the General Manager of Onnit Gym in Austin, TX, helping our gym staff take the next step in their careers while giving our members the best possible training experience.",
        img: "OnnitStories-LarryMaloney.png",
        href: "https://www.onnit.com/academy/larry-maloney-story/",
        imgAltText: "Larry holding a steel club.",
        storyCategory: StoryCategory.Employee,
        storyType: StoryType.Feature,
        date: "2021-09-16",
    },
    {
        headline: "Learn how to lose to win",
        title: "Ryan McDonald's Onnit Story",
        description:
            "As great as winning feels, it doesn’t teach you anything. Only by losing can we learn what we need to correct going forward, or, in some cases, what our true purpose is. An injury brought Ryan McDonald’s collegiate track career to an end, but it inadvertently led him to Onnit, where he now explores his two greatest passions—fitness and technology—as a Full Stack Developer on our web team.",
        img: "OnnitStories-RyanMcdonald.png",
        href: "https://www.onnit.com/academy/ryan-mcdonald-story/",
        imgAltText: "Ryan performing kettlebell squat.",
        storyCategory: StoryCategory.Employee,
        storyType: StoryType.Feature,
        date: "2021-09-13",
    },
    {
        headline: "Onnit is for everyone",
        title: "Jason and Tracy Oberuc's Onnit Story",
        description:
            "Everybody has their own way of working out, but no matter what kind you choose, it should make you feel better than you do without it, and it should have benefits that carry over into other aspects of your life. In that respect, Onnit has you covered, whether you’re a young guy looking to lose weight or an older lady who’s trying to be active again. Such is the case with Jason Oberuc and his mom Tracy (aka “Tad”)—two people who grew closer following the same training system, despite having different goals and being decades apart in age.",
        img: "OnnitStories-OberucFamily.png",
        href: "https://www.onnit.com/academy/oberuc-onnit-story/",
        imgAltText: "Black and white photo of Jason and Tracy holding a kettlebell.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2021-09-09",
    },
    {
        headline: "Build your own castle",
        title: "Anthony Perez's Onnit Story",
        description:
            'Successful people don\'t really see days as "good" or "bad." They\'re just opportunities to give your best effort toward whatever dream you\'re working on.',
        img: "OnnitStories-AnthonyPerez.png",
        href: "https://www.onnit.com/academy/anthony-perez-story/",
        imgAltText: "Anthony Perez holding kettlebell.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2021-08-26",
    },
    {
        headline: "I live with Joy",
        title: "Mitchell Crocker's Onnit Story",
        description:
            "There’s a secret that only people who work out are in on: life is easy. OK, maybe not easy, but it’s a lot easier for people who are vigorously active on a regular basis, because once you’ve left your heart and soul in a pool of sweat on the floor, you can’t be daunted by the minor inconveniences and irritations of daily life. As our friend Mitchell Crocker says, “If I can overcome a workout, I shouldn’t have anything stressing me out.” The Missouri City, Texas, native is one of the happiest and most positive guys we know, and the perfect subject for our continuing Onnit Stories series.",
        img: "OnnitStories-MitchellCrocker.png",
        href: "https://www.onnit.com/academy/mitchell-crocker-story/",
        imgAltText: "Mitchell with boxing glove on.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2021-08-19",
    },
    {
        headline: "People crave authenticity",
        title: "Hannah Eden's Onnit Story",
        description:
            "Hannah Eden may look like some kind of modern-day warrior princess, but she didn’t start out that way. The British-born fitness influencer and Instagram sensation was, by her own admission, “a very dark, angry little girl” once, and she still has moments where she wants to hide her true self from the world. But with the empowerment that comes with fitness, and the support of a like-minded community (in which Onnit humbly plays a role), Eden continues to take her fans and followers along on her journey, sharing its every up and down.",
        img: "OnnitStories-HannahEden.png",
        href: "https://www.onnit.com/academy/hannah-eden-onnit-story/",
        imgAltText: "Hannah flexing.",
        storyCategory: StoryCategory.Pro,
        storyType: StoryType.Feature,
        date: "2021-08-13",
    },
    {
        headline: "We're all ships in the ocean",
        title: "Jolie Kobrinsky's Onnit Story",
        description:
            "You never know whom among those you help today might return the favor tomorrow. Case in point, the relationship between Onnit Chief Fitness Officer John Wolf and coach Jolie Kobrinsky, of Seaside, California. The pair met more than a decade ago when Kobrinsky was an up and coming trainer and wanted to learn kettlebell training from the best. Today, her studio is spreading the Onnit gospel on the west coast. Wolf sat down with Kobrinsky to track how their friendship has developed and improved both their lives and careers.",
        img: "OnnitStories-JolieKobrinsky.png",
        href: "https://www.onnit.com/academy/jolie-kobrinsky/",
        imgAltText: "Hannah flexing.",
        storyCategory: StoryCategory.Trainer,
        storyType: StoryType.Feature,
        date: "2021-08-05",
    },
    {
        headline: "I help clients be better people",
        title: "Juan Leija's Onnit Story",
        description:
            "Juan Leija is a walking billboard for what Onnit’s fitness philosophy can do for your body (if you choose to take it that far). But if you don’t know Juan personally, you probably don’t understand what he can do for your life apart from kettlebells and sweatpants. Onnit Gym’s Director of Fitness Programming is one of our most sought-after trainers, and working with him will develop much more than your muscles.",
        img: "OnnitStories-JuanLeija-Website.jpg",
        href: "https://www.onnit.com/academy/juan-leija/",
        imgAltText: "Juan rehydrating after a workout.",
        storyCategory: StoryCategory.Employee,
        storyType: StoryType.Feature,
        date: "2021-07-28",
    },
    {
        headline: "I made up a way",
        title: "Kenny Stanford's Onnit Story",
        description:
            "The 39 year-old from West Columbia, TX, started working out in an effort to set a good example for his kids. Now, under a decade later, he owns three gym locations and leads some of the toughest teenagers you’ll ever find in adventure races that could fell a horse.",
        img: "OnnitStories-KennyStanford.png",
        href: "https://www.onnit.com/academy/kenny-stanford/",
        imgAltText: "Kenny jogging.",
        storyCategory: StoryCategory.Trainer,
        storyType: StoryType.Feature,
        date: "2021-07-14",
    },
    {
        headline: "I don't know everything and it's okay",
        title: "Anthony Mejia's Onnit Story",
        description:
            "An athlete all his life, Anthony Mejia, 47, of Huntington Beach, Calif., thought fitness was something that could be won with hard work. But competing in the Onnit 6 Challenge and “losing” taught him more about how to transform his body, mind, and spirit than winning ever did. Here, Mejia tells the tale of how Onnit, the Onnit Tribe, and the steel mace he plans to name changed his life.",
        img: "OnnitStories-AnthonyMejia.png",
        href: "https://www.onnit.com/academy/anthony-mejia/",
        imgAltText: "Anthony at the beach.",
        storyCategory: StoryCategory.TribeMember,
        storyType: StoryType.Feature,
        date: "2021-07-01",
    },
    {
        headline: "NFL draft prep 2019",
        description:
            "Follow former head of Onnit Sports Performance, Jeremy Hills, and the rest of the Onnit Team as they help a group of young NFL Draft hopefuls prepare to make their dreams come true, as they pursue the ultimate goal of making an NFL roster.",
        img: "OnnitStories-NFLDraftPrep.jpg",
        href: "https://player.vimeo.com/video/569435927",
        imgAltText: "Football cleats on turf",
        storyCategory: StoryCategory.Sports,
        storyType: StoryType.Feature,
        date: "2019-01-10",
        isModal: true,
    },
    {
        headline: "In the ring with us",
        description:
            "A candid look at the world of female Muay Thai fighting and the uphill battle these women face on a daily basis.",
        img: "OnnitStories-InTheRingWithUs.jpg",
        href: "https://player.vimeo.com/video/569430750",
        imgAltText: "Muay Thai fighters weighing in.",
        storyCategory: StoryCategory.Sports,
        storyType: StoryType.Feature,
        date: "2019-01-09",
        isModal: true,
    },
    {
        headline: "True wrestling",
        description:
            "The world of Independent Wrestling has changed drastically over the years, but the dedication and motivation of its athletes continue to entertain and inspire in cities around the world. Take a peek behind the curtain and follow the journey of Ricky Starks and Aaron Solow, an Independent Tag Team on the underground circuit, as they travel throughout Texas, Mexico and beyond to chase their dreams to the WWE.",
        img: "OnnitStories-TrueWrestling.jpg",
        href: "https://player.vimeo.com/video/569430444",
        imgAltText: "Two wrestlers posing for a photo.",
        storyCategory: StoryCategory.Sports,
        storyType: StoryType.Feature,
        date: "2019-01-08",
        isModal: true,
    },
    {
        headline: "Cowboy",
        description:
            'An all-access behind the scenes look at what life is like for the UFC fighter they call, Donald "Cowboy" Cerrone. Featuring training, life on the BMF ranch, and the lead up to UFC 205 and 206. Donald Cerrone works hard, plays hard, and lives a life worth living.',
        img: "OnnitStories-Cowboy.jpg",
        href: "https://player.vimeo.com/video/569430192",
        imgAltText: "Photograph of cowboy on a ranch.",
        storyCategory: StoryCategory.Sports,
        storyType: StoryType.Feature,
        date: "2019-01-07",
        isModal: true,
    },
    {
        headline: "Before the pour",
        description:
            "Pre-ground or whole bean, french press or drip, black or MCT-charged, we all have our coffee process. But there’s a more extraordinary and beautiful process that takes place long before the coffee makes it in your cup. This rarely-seen journey begins hundreds of miles away, in a small village in Guatemala. So before you brew your next cup, enjoy this incredible look at what goes into creating our Arabica Dark Roast Coffee from the mountains of Guatemala to the streets of Austin, Texas.",
        img: "OnnitStories-BeforeThePour.jpg",
        href: "https://player.vimeo.com/video/569429979",
        imgAltText: "Aerial view of coffee bean farm.",
        storyCategory: StoryCategory.Other,
        storyType: StoryType.Feature,
        date: "2019-01-06",
        isModal: true,
    },
];

export default stories;
