import React from "react";
import styled, { css } from "styled-components";
import { theme } from "styled-tools";
import { Box, BoxProps } from "../box/Box";
import { BgImage } from "../image";

interface Props extends BoxProps {
    children?: any;
    bgColor?: string;
    bgSrc: string | string[];
    bgProps?: object;
    gradient?: string;
}

const getColor = (bg: string) => {
    if (bg.indexOf?.("#") !== -1) {
        return bg;
    }
    return theme(`colors.${bg}`);
};

const Container = styled(Box)<BoxProps & { gradient?: string }>`
    position: relative;
    .content {
        background-image: ${(prp) => (prp.gradient ? prp.gradient : css`linear-gradient(180 deg, transparent 50 %, ${((prp) => getColor(String(prp.bg)))(prp)} 90 %)`)};
    }
`;

export default ({ children, bgSrc, bgColor = "grays.8", bgProps, ...rest }: Props) => (
    <Container bg={bgColor} {...rest}>
        <BgImage
            width="100%"
            height="100%"
            src={bgSrc}
            backgroundPosition="top center"
            position="absolute"
            top="0"
            left="0"
            zIndex={0}
            {...bgProps}
        />
        <Box className="content" position="relative" zIndex={1}>
            {children}
        </Box>
    </Container>
);
