import React, { MouseEvent } from "react";
import { Box, BoxProps } from "../box/Box";

interface Props extends Omit<BoxProps, "onClick"> {
    imageUrl: string;
    productUrl?: string | null;
    productName: string;
    onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

const ProductImage: React.FC<React.PropsWithChildren<Props>> = (
    {
        imageUrl,
        productUrl,
        productName,
        children,
        width = "75px",
        onClick,
        ...boxProps
    }) => {
    const image = (
        <img
            src={imageUrl}
            alt={productName}
            width="100%"
        />
    );

    return (
        <Box mr={3} position="relative" flex="none" width={width} {...boxProps}>
            {children && children}
            {productUrl ? (
                <a href={productUrl} onClick={onClick}>
                    {image}
                </a>
            ) : image}
        </Box>
    );
};

export default ProductImage;
