import React, { createContext, useContext } from "react";
import { Box, BoxProps } from "../../box/Box";
import Toggle, { ToggleProps } from "./Toggle";

interface Props extends BoxProps {
    children: any;
    name?: string;
    onChange: (event: any) => void;
    value: string;
    labelPosition?: "left" | "right";
}

const StateContext = createContext<Partial<Props>>({});

export const RadioButton = (props: ToggleProps) => {
    const { name, labelPosition, value: selectedValue, onChange } = useContext(StateContext);
    return (
        <Toggle
            type="radio"
            value={props.value}
            checked={selectedValue === props.value}
            onChange={onChange}
            name={name}
            labelPosition={labelPosition}
            children={props.children} /* eslint-disable-line react/no-children-prop */
        />
    );
};

export const RadioGroup: React.FunctionComponent<React.PropsWithChildren<Props>> = (
    {
        children,
        name,
        onChange,
        value,
        labelPosition,
        ...rest
    }) => (
        <Box {...rest}>
            <StateContext.Provider
                value={{ /* eslint-disable-line react/jsx-no-constructed-context-values */
                name,
                labelPosition,
                value,
                onChange,
            }}
            >
                {children}
            </StateContext.Provider>
        </Box>
);
