import React from "react";
import styled from "styled-components";
import { prop, theme } from "styled-tools";
import { Box, BoxProps } from "../box/Box";

const Container = styled(Box)`
    max-width: ${theme("gridMaxWidth")}px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    @media (max-width: ${prop("breakpoints.1")}) {
        min-height: 343px;
        padding-top: 34%;
    }
    @media ${theme("mediaQueries.md")} {
        display: grid;
        grid-template-columns: 4fr 7fr 1fr;
    }
`;

const Media = styled.div`
    box-sizing: border-box;
    @media (max-width: ${prop("breakpoints.1")}) {
        margin-right: ${theme("space.4")}px;
        margin-left: ${theme("space.4")}px;
        overflow: hidden;
        position: absolute;
        top: 0;
        width: calc(100% - 32px);
        height: 100%;
        display: flex;
        justify-content: center;
    }

    @media ${theme("mediaQueries.md")} {
        margin: auto;
        padding-right: ${theme("space.4")}px;
        padding-left: ${theme("space.4")}px;
        grid-column: 2;
        width: 100%;
    }

    > img,
    video {
        display: block;
        object-fit: contain;
        width: auto;
        height: 100%;
        @media ${theme("mediaQueries.md")} {
            width: 100%;
            height: auto;
        }
    }
`;

interface Props extends BoxProps {
    children?: any;
}

export default ({ children, ...rest }: Props) => (
    <Container {...rest}>
        <Media>{children}</Media>
    </Container>
);
