import React, { ReactNode } from "react";
import { Box, BoxProps } from "../box/Box";
import makePseudoButtonProps from "../../utils/PropUtils";

interface BannerProps extends BoxProps {
    headline: ReactNode;
    image?: ReactNode;
    button: ReactNode;
    url: string;
}

const FlashBanner: React.FC<React.PropsWithChildren<BannerProps>> = (
    {
        headline,
        url,
        image,
        children,
        button,
        ...rest
    }) => {
    const buttonProps = url
        ? makePseudoButtonProps(() => {
            if (url) {
                window.location.href = url;
            }
        })
        : {};
    return (
        <Box {...rest} {...buttonProps}>
            <Box
                display="grid"
                width="100%"
                maxWidth={["100%", "100%", "100%", "1200px"]}
                mx="auto"
                gridTemplateRows={["1fr 1fr", "1fr 1fr", "1fr"]}
                gridTemplateColumns={[
                    "max-content 1fr min-content",
                    "max-content 1fr min-content",
                    "1fr max-content 1fr max-content",
                    "1fr max-content 1fr 1fr",
                ]}
            >
                <Box
                    py={[1, 1, 4]}
                    px={[2, 2, 6]}
                    gridColumn={[2, 2, 1]}
                    gridRow={1}
                    display="flex"
                    alignItems="center"
                >
                    {headline}
                </Box>
                {image && (
                    <Box
                        maxWidth="100px"
                        p={[2, 2, 4]}
                        gridColumn={[1, 1, 2]}
                        gridRow={["1 / span 2", "1 / span 2", "1"]}
                    >
                        {image}
                    </Box>
                )}
                <Box
                    p={[2, 2, 4]}
                    gridColumn={["2 / span 2", "2 / span 2", 3]}
                    gridRow={[2, 2, 1]}
                    display={["block", "block", "flex"]}
                    justifyContent="center"
                    flexDirection="column"
                >
                    {children}
                </Box>
                <Box
                    p={[2, 2, 6]}
                    gridColumn={[3, 3, 4]}
                    gridRow={1}
                    display="flex"
                    alignItems="center"
                    justifyContent={["center", "center", "flex-end"]}
                >
                    {button}
                </Box>
            </Box>
        </Box>
    );
};

export default FlashBanner;
