import React from "react";
import styled from "styled-components";
import { Box } from "../../box/Box";
import { Text, TextProps } from "../../text/Text";
import { ThemeInterface } from "../../themes/interfaces";
import { FieldStates } from "./FieldContext";

export { default as FieldBorder } from "./FieldBorder";

const themeFieldColor = (prp: { currentState: string; theme: ThemeInterface }) => (prp.currentState && prp.theme
    ? prp.theme.component.formfield[prp.currentState].helperTextColor
    : "gray");

const StyledText = styled(Text)<TextProps & { currentState: FieldStates }>`
    color: ${themeFieldColor};
    user-select: none;
`;

export const HelperText = ({ fieldState, children, id, pl = 3, pt = 1, ...rest }: any) => (
    <Box pl={pl} pt={pt} {...rest}>
        <StyledText
            id={id}
            fontSize={1}
            currentState={fieldState.currentState}
            role={fieldState.currentState === FieldStates.ERROR ? "alert" : undefined}
        >
            {children}
        </StyledText>
    </Box>
);
