import React from "react";
import { Box, BoxProps } from "../../box/Box";
import { Text } from "../../text/Text";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";

interface Props extends BoxProps {
    product: Product;
}

export default function PriceLabel({ product, ...rest }: Props) {
    const isOnSale = product.sale_discount_ratio > 0;
    const saleText = isOnSale ? `${(product.sale_discount_ratio * 100).toFixed()}% off` : "";

    return (
        <Box {...rest}>
            {product.sale_discount_ratio === 1 ? (
                <Box>
                    <Text display="inline-block" fontWeight="bold" fontSize={4}>
                        FREE
                    </Text>
                </Box>
            ) : (
                <Box>
                    {isOnSale && (
                        <>
                            <span className="sr-only">Normally,</span>
                            <Text
                                typeStyle="text03"
                                display="inline-block"
                                textDecoration="line-through"
                                color="grays.4"
                                mr={2}
                                as="p"
                            >
                                {product.price_msrp}
                            </Text>
                            <span className="sr-only">Your price today,</span>
                        </>
                    )}
                    <Text display="inline-block" typeStyle="text03" as="p">
                        {product.price}
                    </Text>
                    {isOnSale && (
                        <Text
                            display="inline-block"
                            typeStyle="text03"
                            fontWeight="bold"
                            color="grays.4"
                            ml={2}
                            as="p"
                        >
                            {saleText}
                        </Text>
                    )}
                </Box>
            )}
        </Box>
    );
}
