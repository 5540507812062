import React, { useEffect } from "react";
import config from "../../../config";
import getProductStatus from "../utils";
import ProductStatus from "../../../@types/enums/ProductStatus";
import { Box } from "../../box/Box";
import InStockView from "./instock/InStockView";
import OOSView from "../shared/OOSView";
import DiscontinuedView from "../shared/DiscontinuedView";
import { BuyBoxProps } from "../BuyBox";
import { FavoriteContainerButton } from "../shared/favorites";
import ProductSelect from "../shared/ProductSelect";
import PromoDiscount, { getActivePromoDiscountConfig } from "../shared/PromoDiscount";
import useSelectedProduct from "../../../hooks/useSelectedProduct";
import PriceSpiderContainer from "../shared/PriceSpiderContainer";

export default function DefaultBuyBox(
    {
        products,
        promoDiscountConfigs,
        productSelectLabel = "Product",
        freeShippingThreshold = config.FREE_SHIPPING_THRESHOLD_DEFAULT,
        onProductChange,
        productNameTransform,
        showFavoritesButton = true,
        showFooter = true,
        onSuccess,
        onError,
        buyBoxRef,
        addToBagMiddleware,
        priceSpiderHeroSku,
        ...rest
    }: BuyBoxProps) {
    const { selectedProduct, setProduct } = useSelectedProduct(products);

    useEffect(() => {
        if (onProductChange) {
            onProductChange(selectedProduct);
        }
    }, [selectedProduct, onProductChange]);

    if (!products || products.length === 0) {
        console.error("Default BuyBox: no products provided");
        return null;
    }

    if (!selectedProduct) {
        return null;
    }

    const activePromoConfig = promoDiscountConfigs
        ? getActivePromoDiscountConfig(selectedProduct.id, promoDiscountConfigs)
        : null;

    return (
        <Box ref={buyBoxRef} {...rest}>
            {activePromoConfig && <PromoDiscount promoDiscountConfig={activePromoConfig} mb={4} />}

            <ProductSelect
                label={productSelectLabel}
                selectedProduct={selectedProduct}
                products={products}
                productNameTransform={productNameTransform}
                onProductChange={setProduct}
            />

            {
                {
                    [ProductStatus.InStock]: (
                        <InStockView
                            promoDiscountConfig={activePromoConfig}
                            onSuccess={onSuccess}
                            onError={onError}
                            addToBagMiddleware={addToBagMiddleware}
                            product={selectedProduct}
                            freeShippingThreshold={freeShippingThreshold}
                            showFooter={showFooter}
                            priceSpiderHeroSku={priceSpiderHeroSku}
                        >
                            {showFavoritesButton && (
                                <FavoriteContainerButton mt={2} productId={selectedProduct.id} />
                            )}
                        </InStockView>
                    ),
                    [ProductStatus.OutOfStock]: (
                        <OOSView product={selectedProduct}>
                            {showFavoritesButton && (
                                <FavoriteContainerButton mt={2} productId={selectedProduct.id} />
                            )}
                            {priceSpiderHeroSku !== null && (
                                <PriceSpiderContainer mt={4} mb={8}>
                                    {/* eslint-disable-next-line react/no-unknown-property */}
                                    <div className="ps-widget" ps-sku={priceSpiderHeroSku} />
                                </PriceSpiderContainer>
                            )}
                        </OOSView>
                    ),
                    [ProductStatus.Discontinued]: <DiscontinuedView />,
                }[getProductStatus(selectedProduct)]
            }
        </Box>
    );
}
