import React, { Fragment } from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text/Text";

interface Props extends BoxProps {
    breadcrumbs: Array<{ slug: string; name: string }>;
}

export default ({ breadcrumbs, ...rest }: Props) => (
    <Box {...rest}>
        {breadcrumbs.map((breadcrumb, index, arr) => (
            <Fragment key={breadcrumb.slug}>
                <Text as="a" href={`/${breadcrumb.slug}`} display="inline-block" color="grays.4" fontSize={1}>
                    {breadcrumb.name}
                </Text>
                {index < arr.length - 1 && (
                    <Text px={1} display="inline-block" color="grays.4" fontSize={1}>
                        /
                    </Text>
                )}
            </Fragment>
        ))}
    </Box>
);
