import React from "react";
import { useTheme } from "styled-components";
import { MdCheckBox, MdCheckBoxOutlineBlank, MdRadioButtonChecked, MdRadioButtonUnchecked, } from "react-icons/md";
import { Box } from "../../box/Box";
import Icon from "../../icon/Icon";

interface Props {
    type: "checkbox" | "radio";
    checked: boolean;
}

interface IconProps {
    checked?: boolean;
    size?: number;
    color?: string;
}

const CheckToggleIcon = ({ checked, ...rest }: IconProps) => (checked ? (
    <Icon size={24} icon={MdCheckBox} {...rest} />
) : (
    <Icon size={24} icon={MdCheckBoxOutlineBlank} {...rest} />
));

const RadioToggleIcon = ({ checked, ...rest }: IconProps) => (checked ? (
    <Icon size={24} icon={MdRadioButtonChecked} {...rest} />
) : (
    <Icon size={24} icon={MdRadioButtonUnchecked} {...rest} />
));

export default function ToggleIcon({ checked, type = "checkbox" }: Props) {
    const theme = useTheme();
    const Comp = type === "radio" ? RadioToggleIcon : CheckToggleIcon;
    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Comp
                checked={checked}
                size={22}
                color={
                    checked
                        ? theme.component.toggle.active.borderColor
                        : theme.component.toggle.initial.borderColor
                }
            />
        </Box>
    );
}
