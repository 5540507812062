import React from "react";
import TwoEight from "./grid/12/TwoEight";
import Dash from "../icon/Dash";
import { Box, BoxProps } from "../box/Box";

interface Props extends BoxProps {
    withDash?: boolean;
    withDashMobile?: boolean;
    dashColor?: string;
    dashColorLinearStop?: string;
    dashEndColor?: string;
    children?: any;
}

export default ({
                    children,
                    dashColor,
                    withDash = true,
                    withDashMobile = true,
                    dashColorLinearStop,
                    dashEndColor,
                    ...rest
                }: Props) => {
    const showMobile = withDashMobile ? "block" : "none";
    return (
        <Box {...rest}>
            <TwoEight
                two={
                    withDash && (
                        <Box px={4} py={[0, 0, 0, 4]} display={[showMobile, "block"]}>
                            <Dash color={dashColor} linearColorStop={dashColorLinearStop} endColor={dashEndColor} mt={2} />
                        </Box>
                    )
                }
                eight={<Box p={4}>{children}</Box>}
            />
        </Box>
    );
};
