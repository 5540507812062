import React, { ReactNode } from "react";
import TwoEight, { TwoEightProps } from "./grid/12/TwoEight";
import Dash from "../icon/Dash";
import { Box } from "../box/Box";

interface Props extends TwoEightProps {
    withDash?: boolean;
    dashColor?: string;
    label?: ReactNode;
}

export default ({ label, content, withDash = false, dashColor, reverse, ...rest }: Props) => (
    <Box {...rest}>
        {withDash && (
            <TwoEight
                reverse={reverse}
                eight={(
                    <Box px={4}>
                        <Dash color={dashColor} />
                    </Box>
                )}
                mb={label ? [2, 2, 2, 6] : [0, 0, 0, 6]}
            />
        )}
        <TwoEight
            reverse={reverse}
            two={
                label && (
                    <Box px={4} py={[0, 0, 0, 4]}>
                        {label}
                    </Box>
                )
            }
            eight={<Box p={4}>{content}</Box>}
        />
    </Box>
);
