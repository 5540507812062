import React from "react";
import { BoxProps } from "@onnit-js/ui/components/box/Box";
import Tabs from "@onnit-js/ui/components/tabs/Tabs";
import { Text } from "@onnit-js/ui/components/text";
import { TabNode, Tabs as TabsInterface } from "../../interfaces/SiteContent";
import ContentfulComponentMapper from "./ContentfulComponentMapper";

interface Props extends BoxProps {
    data: TabsInterface;
}

const ContentfulTabs:React.FC<Props> = ({ data: { tabs, activeColor, activeTabIndex, ...rest } }) => (
    <Tabs activeTabIndex={activeTabIndex} {...rest}>
        {tabs.map((tab: TabNode) => (
            <Tabs.Tab
                key={tab.data.title}
                title={(isActive) => (
                    <Text
                        display="block"
                        fontFamily="proxima"
                        fontWeight="bold"
                        lineHeight={2}
                        textTransform="uppercase"
                        fontSize={[3, 3, 4, 5]}
                        {...tab.data.titleProps}
                        mr={3}
                        borderBottom="2px solid"
                        borderColor={isActive ? activeColor : "transparent"}
                    >
                        {tab.data.title}
                    </Text>
                )}
                content={<ContentfulComponentMapper content={tab.data.content} />}
            />
        ))}
    </Tabs>
);

export default ContentfulTabs;
