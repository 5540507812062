import React from "react";
import FlashBanner from "@onnit-js/ui/components/module/FlashBanner";
import Box from "@onnit-js/ui/components/box/Box";
import { Text } from "@onnit-js/ui/components/text";
import Img from "@onnit-js/ui/components/image/Image";
import Button from "@onnit-js/ui/components/button/Button";
import { FlashSaleBanner } from "../../interfaces/SiteContent";
import SuperScriptSpecialChars from "../common/SuperScriptSpecialChars";

interface Props {
    data: FlashSaleBanner;
}

const ContentfulFlashBanner = ({ data }: Props) => {
    if (!data.isActive) {
        return null;
    }

    const { src, ...imgProps } = data.image;
    const textColor = data.textColor ?? "black";
    const isOutlineVariant = data.variant === "outline";
    const variantProps = isOutlineVariant
        ? {
            borderTop: `1px solid ${data.backgroundColor}`,
            borderBottom: `1px solid ${data.backgroundColor}`,
        }
        : {
            bg: data.backgroundColor,
        };

    return (
        <FlashBanner
            {...variantProps}
            url={data.url}
            headline={(
                <Text as="h2" typeStyle="title02" color={textColor}>
                    <SuperScriptSpecialChars text={data.headline} />
                </Text>
            )}
            image={
                data.image ? (
                    <Box maxWidth={["initial", "initial", "60px"]}>
                        <Img lazyLoad={false} src={`${src}?q=40&fl=progressive`} {...imgProps} />
                    </Box>
                ) : (
                    <></>
                )
            }
            button={(
                <Button
                    color={textColor}
                    textColor={isOutlineVariant ? "black" : textColor}
                    fill={isOutlineVariant ? "solid" : "outline"}
                    raised
                    size={["icon", "medium"]}
                    aria-label={data.urlDescription}
                >
                    {data.cta}
                </Button>
            )}
        >
            <Text as="p" color={textColor} typeStyle="caption01" textTransform="uppercase" mb={2}>
                <SuperScriptSpecialChars text={data.title} />
            </Text>
            {data.body && (
                <Text as="p" color={textColor} typeStyle="text03" fontWeight="regular">
                    <SuperScriptSpecialChars text={data.body} />
                </Text>
            )}
        </FlashBanner>
    );
};

export default ContentfulFlashBanner;
