import React from "react";
import Carousel from "@onnit-js/ui/components/slider/Carousel";
import { Carousel as CarouselInterface } from "../../interfaces/SiteContent";
import ContentfulComponentMapper from "./ContentfulComponentMapper";

interface Props {
    data: CarouselInterface
}

const ContentfulCarousel:React.FC<Props> = ({ data: { items, ...rest } }) => {
    if (!items) {
      return null;
    }

    return (
        <Carousel {...rest}>
            {items.map((node, index) =>
                // eslint-disable-next-line react/no-array-index-key,implicit-arrow-linebreak
                <ContentfulComponentMapper key={index} content={[node]} />
            )}
        </Carousel>
    );
};

export default ContentfulCarousel;
